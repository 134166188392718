import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { getNamespace } from '../../../../redux/selectors';
import ICButton from '../../../shared/ICButton';
import AlertPanel from '../../shared/alerts/AlertPanel';

export default function AlertForm({
    closeModal,
    currentAlerts,
    onRemoveFromSituation,
    situationReportId
}) {
    const situationsNamespace = useSelector(
        getNamespace(NAMESPACES.SITUATIONS_NAMESPACE)
    );
    const [alerts, setAlerts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (situationsNamespace) {
            situationsNamespace.on('alertSearchResults', (data) => {
                setAlerts(data);
                setLoading(false);
            });
        }

        return () => {
            situationsNamespace.off('alertSearchResults');
        };
    }, []);

    useEffect(() => {
        if (searchTerm.length >= 3) {
            setLoading(true);
            fetchAlerts(searchTerm);
        } else if (searchTerm === '') {
            setAlerts([]);
        }
    }, [searchTerm]);

    const fetchAlerts = _.debounce(
        () => {
            situationsNamespace.emit('searchAlertsByKeyword', searchTerm);
        },
        1000,
        { leading: false, trailing: true }
    );

    function searchAlerts(e) {
        setSearchTerm(e.target.value);
    }

    function onAddToSituation(alertId) {
        situationsNamespace.emit('addAlertToSituationReport', {
            alertId,
            situationReportId
        });
    }

    function renderResults() {
        return (
            <div className="ContentForm-alert-results">
                {loading ? (
                    <i className="fa fa-spinner fa-spin" />
                ) : alerts.length ? (
                    _.map(alerts, (alert) => {
                        const type = _.find(currentAlerts, alert)
                            ? 'contentManagementRemove'
                            : 'contentManagementAdd';
                        return (
                            <AlertPanel
                                key={alert.id}
                                alert={alert}
                                dateField="published_date"
                                type={type}
                                onAddToSituation={onAddToSituation}
                                onRemoveFromSituation={onRemoveFromSituation}
                            />
                        );
                    })
                ) : (
                    'No alerts match search criteria.'
                )}
            </div>
        );
    }

    return (
        <div>
            <div className="ContentManagementModal-header">
                <h2>Add Related Threats</h2>
                <button
                    className="ReactModal__CloseButton"
                    onClick={closeModal}
                    data-testid={
                        TEST_ATTRIBUTES.CONTENT_MANAGEMENT.CLOSE_ALERT_MODAL
                    }
                >
                    <i className="fa fa-window-close" />
                </button>
            </div>

            <div className="ContentManagementModal-body">
                <div className="ContentForm-input-container">
                    <label htmlFor="alert-search">Search Threats</label>
                    <div className="input">
                        <input
                            id="alert-search"
                            className="ContentForm-form-control"
                            type="text"
                            onChange={searchAlerts}
                            data-testid={
                                TEST_ATTRIBUTES.CONTENT_MANAGEMENT.ALERT_SEARCH
                            }
                        />
                    </div>
                </div>

                {searchTerm.length >= 3 && renderResults()}
            </div>

            <div className="ContentManagementModal-footer">
                <ICButton color="white" onClick={closeModal}>
                    Close
                </ICButton>
            </div>
        </div>
    );
}
