import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../../../config/constants';
import { getNamespace } from '../../../../../redux/selectors';
import ICButton from '../../../../shared/ICButton';

export default function UpdateSummaryButton({ event }) {
    // redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const handleUpdateSummaryClick = () => {
        confirmAlert({
            title: 'Confirm Summary Update',
            message: `Are you sure you want to update the summary on this event?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        console.log('updateSummary', {
                            id: event?.id
                        });
                        threatDeckNamespace.emit('updateSummary', {
                            id: event?.id
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    };

    return (
        <ICButton
            onClick={handleUpdateSummaryClick}
            color="yellow"
            text={'Update Summary'}
            className="update-summary-button"
        />
    );
}
