import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import {
    MULTISELECT_COMPONENT_STYLES,
    NAMESPACES
} from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { getNamespace } from '../../../../redux/selectors';
import ICButton from '../../../shared/ICButton';

export default function TwitterKnownAccountForm({
    setCreateTwitterAccountActive,
    twitterAccountToEdit,
    setTwitterAccountToEdit,
    regions
}) {
    // redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const [twitterAccountHandle, setTwitterAccountHandle] = useState(
        twitterAccountToEdit?.handle
    );
    const [twitterAccountFollow, setTwitterAccountFollow] = useState(
        twitterAccountToEdit?.follow
    );
    const [twitterAccountRegion, setTwitterAccountRegion] = useState(
        twitterAccountToEdit ? twitterAccountToEdit.region : null
    );
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on(
                'knownTwitterAccountCreateResponse',
                (data) => {
                    if (data.error) {
                        setErrorMessage(data.error);
                        return;
                    }
                    closeTheForm();
                }
            );
            threatDeckNamespace.on(
                'knownTwitterAccountEditResponse',
                (data) => {
                    if (data.error) {
                        setErrorMessage(data.error);
                        return;
                    }
                    closeTheForm();
                }
            );
        }
    }, [threatDeckNamespace]);

    function closeTheForm() {
        threatDeckNamespace.emit('getKnownTwitterAccountsList');
        setTwitterAccountToEdit(false);
        setCreateTwitterAccountActive(false);
    }

    function saveTwitterAccount() {
        let socketEvent = twitterAccountToEdit
            ? 'updateKnownTwitterAccount'
            : 'createKnownTwitterAccount';
        let args = {
            handle: twitterAccountHandle,
            follow: twitterAccountFollow,
            region: twitterAccountRegion
        };
        if (twitterAccountToEdit) {
            args['id'] = twitterAccountToEdit.id;
        }
        threatDeckNamespace.emit(socketEvent, args);
    }

    return (
        <Container className="ConfigureSystem-ThreatDeckSourceForm-Container">
            <span>
                <Row>
                    <Col>
                        <h1>
                            {twitterAccountToEdit ? 'Edit' : 'Register'} Known
                            Twitter Account
                        </h1>
                    </Col>
                </Row>
                <Row className="ThreatDeckSource-form-row">Account Handle</Row>
                <Row>
                    <input
                        id="TDRegisterTwitterAccountHandle"
                        className="ThreatDeckSource-form-control"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.FORM.ACCOUNT_HANDLE}`}
                        onChange={(e) =>
                            setTwitterAccountHandle(e.target.value)
                        }
                        value={twitterAccountHandle || ''}
                    />
                </Row>
                <Row className="ThreatDeckSource-form-row">
                    <span className="TDRegisterTwitterAccountFollow">
                        <input
                            type="checkbox"
                            id="updateFollowTwitterFollow"
                            data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.FORM.ACCOUNT_FOLLOW}`}
                            checked={twitterAccountFollow}
                            onChange={() =>
                                setTwitterAccountFollow(!twitterAccountFollow)
                            }
                        />
                        <label htmlFor="updateFollowTwitterFollow">
                            Follow
                        </label>
                    </span>
                </Row>
                <Row className="ThreatDeckSource-form-row">Region</Row>
                <Row>
                    <Multiselect
                        id="updateRegionMultiselect"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.FORM.ACCOUNT_REGION}`}
                        selectionLimit={1}
                        options={regions}
                        onSelect={(selectedList, item) =>
                            setTwitterAccountRegion(item)
                        }
                        displayValue="name"
                        singleSelect={true}
                        style={MULTISELECT_COMPONENT_STYLES}
                        selectedValues={
                            twitterAccountToEdit
                                ? [
                                      _.find(regions, [
                                          'id',
                                          twitterAccountToEdit.region.id
                                      ])
                                  ]
                                : twitterAccountRegion
                                ? [twitterAccountRegion]
                                : []
                        }
                    />
                </Row>
                {errorMessage && (
                    <div
                        className="Login-form-error"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.FORM.ACCOUNT_ERROR_MESSAGE}`}
                    >
                        {errorMessage}
                    </div>
                )}

                <Row className="ThreatDeckSource-form-row">
                    <ICButton
                        color="blue"
                        className="ThreatDeckSource-form-button"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.SAVE_ACCOUNT}`}
                        onClick={() => {
                            saveTwitterAccount();
                        }}
                    >
                        Save {twitterAccountToEdit ? 'Edits' : ''}
                    </ICButton>
                </Row>
                <Row className="ThreatDeckSource-form-row">
                    <ICButton
                        color="white"
                        className="ThreatDeckSource-form-button"
                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.CANCEL_ACCOUNT}`}
                        onClick={() => {
                            closeTheForm();
                        }}
                    >
                        Cancel
                    </ICButton>
                </Row>
            </span>
        </Container>
    );
}
