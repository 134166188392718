import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../../../config/constants';
import { getNamespace } from '../../../../../redux/selectors';
import Loading from '../../../../shared/Loading';
import EventDetailsBody from './EventDetailsBody';
import EventDetailsHeader from './EventDetailsHeader';

export default function EventDetails({
    eventId,
    isModalView = false,
    setDoesNotExist,
    closeModal
}) {
    // state
    const [eventDetails, setEventDetails] = useState(null);
    const [mergeList, setMergeList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    useEffect(() => {
        if (threatDeckNamespace && eventId) {
            threatDeckNamespace.on('threatDeckEventDetails', (event) => {
                if (!event?.id && setDoesNotExist) {
                    setDoesNotExist(true);
                } else {
                    setEventDetails(event);
                }
                setIsLoading(false);
            });
            threatDeckNamespace.on('updatedFeedItemEvent', (event) => {
                if (event.id === eventId) {
                    threatDeckNamespace.emit(
                        'getThreatDeckEventDetails',
                        eventId
                    );
                }
            });

            threatDeckNamespace.emit('getThreatDeckEventDetails', eventId);

            return () => {
                threatDeckNamespace.off('threatDeckEventDetails');
            };
        }
    }, [threatDeckNamespace, eventId]);

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && eventDetails && (
                <>
                    <EventDetailsHeader
                        event={eventDetails}
                        isModalView={isModalView}
                        setMergeList={setMergeList}
                        closeModal={closeModal}
                    />
                    <EventDetailsBody
                        event={eventDetails}
                        mergeList={mergeList}
                        setMergeList={setMergeList}
                        isModalView={isModalView}
                    />
                </>
            )}
        </>
    );
}
