import './ThreatDeckCard.css';

import React from 'react';

import { THREAT_DECK_SOURCE_ICONS } from '../../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';

export default function CardSource({
    type,
    sourceName,
    sourceLink,
    cardView = false
}) {
    const name =
        sourceName || THREAT_DECK_SOURCE_ICONS[type]?.label || 'Unknown Source';
    const linkedName = sourceLink ? (
        <a href={sourceLink} target="_blank" rel="noopener noreferrer">
            {name}
        </a>
    ) : (
        name
    );

    return (
        <div
            className="ThreatDeckCard-source"
            data-testid={TEST_ATTRIBUTES.THREAT_DECK.CARDS.SOURCE}
        >
            {THREAT_DECK_SOURCE_ICONS[type]?.icon}
            <span
                className={`ThreatDeckCard-source-name ${
                    cardView ? 'card' : 'details'
                }`}
                data-testid={TEST_ATTRIBUTES.THREAT_DECK.CARDS.SOURCE_NAME}
            >
                {linkedName}
            </span>
        </div>
    );
}
