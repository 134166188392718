import '../ThreatDeckSources.css';

import React, { useEffect, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { NAMESPACES, ROLES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import {
    getApplicationRegions,
    getAuthInfo,
    getNamespace
} from '../../../../redux/selectors';
import ICButton from '../../../shared/ICButton';
import ICTable from '../../../shared/ICTable';
import RSSFeedsForm from './RSSFeedsForm';

const { ADMINISTRATOR, LEAD_ANALYST } = ROLES;
const { THREATDECK_NAMESPACE } = NAMESPACES;

export default function RSSFeeds() {
    const authInfo = useSelector(getAuthInfo);
    const regionOptions = useSelector(getApplicationRegions);
    const threatDeckNamespace = useSelector(getNamespace(THREATDECK_NAMESPACE));

    const [rssFeeds, setRssFeeds] = useState([]);
    const [createRssFeedActive, setCreateRssFeedActive] = useState(false);
    const [rssFeedToEdit, setRssFeedToEdit] = useState(null);

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on('rssFeedsList', setRssFeeds);
            threatDeckNamespace.emit('getRssFeedsList');
            threatDeckNamespace.emit('getRegionList');
        }

        // component cleanup, cancel socket listeners
        // this prevents setting state on unmounted components
        return () => {
            threatDeckNamespace.off('rssFeedsList');
        };
        // we might not even want to reFetch data if the socket changes
    }, [threatDeckNamespace]);

    function editRssFeed(item) {
        if (authInfo.role === LEAD_ANALYST || authInfo.role === ADMINISTRATOR) {
            // Let's make sure region is not null
            if (!item.region) {
                item.region = { id: 1, name: 'Unknown' };
            }
            setRssFeedToEdit(item);
            setCreateRssFeedActive(!createRssFeedActive);
        }
    }

    function deleteRssFeed(item) {
        if (authInfo.role === ADMINISTRATOR) {
            threatDeckNamespace.emit('deleteRssFeed', {
                id: item.id
            });
            threatDeckNamespace.emit('getRssFeedsList');
        }
    }

    function toggleRssFeedItemStatus(item) {
        if (authInfo.role === LEAD_ANALYST || authInfo.role === ADMINISTRATOR) {
            threatDeckNamespace.emit('setRssFeedActive', {
                id: item.id,
                active: !item.active
            });
        }
    }

    const rssFeedColumns = useMemo(() => {
        return [
            {
                Header: 'Feed Name',
                accessor: 'title',
                customColumnRender: {
                    render: ({ item }) => {
                        return (
                            <span>
                                {item.title}
                                {authInfo.role === ADMINISTRATOR && (
                                    <ICButton
                                        key={`feed_item_delete_${item.id}`}
                                        color="red"
                                        className="ThreatDeckSource-delete-button"
                                        data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.BUTTONS.DELETE_RSS}-${item.id}`}
                                        onClick={() => {
                                            confirmAlert({
                                                title: 'Confirm Delete',
                                                message: `Are you sure you want to delete this feed?`,
                                                buttons: [
                                                    {
                                                        label: 'Yes',
                                                        onClick: () =>
                                                            deleteRssFeed(item)
                                                    },
                                                    {
                                                        label: 'No',
                                                        onClick: () => {}
                                                    }
                                                ]
                                            });
                                        }}
                                    >
                                        Delete
                                    </ICButton>
                                )}
                                {(authInfo.role === LEAD_ANALYST ||
                                    authInfo.role === ADMINISTRATOR) && (
                                    <ICButton
                                        key={`feed_item_edit_${item.id}`}
                                        color="blue"
                                        className="ThreatDeckSource-edit-button"
                                        data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.BUTTONS.EDIT_RSS}-${item.id}`}
                                        onClick={() => {
                                            editRssFeed(item);
                                        }}
                                    >
                                        Edit
                                    </ICButton>
                                )}
                            </span>
                        );
                    },
                    args: { item: 'row.original' }
                }
            },
            {
                Header: 'URL',
                accessor: 'url',
                Filter: false,
                customColumnRender: {
                    render: ({ item }) => {
                        return (
                            <ICButton
                                key={`url_${item.url}`}
                                color="white"
                                className="ThreatDeckSource-url-button"
                                data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.BUTTONS.OPEN_RSS}-${item.id}`}
                                onClick={() => window.open(item.url, '_new')}
                            >
                                <span title={item.url}>Open Feed</span>
                            </ICButton>
                        );
                    },
                    args: { item: 'row.original' }
                }
            },
            { Header: 'Region', accessor: 'region.name' },
            {
                Header: 'Status',
                accessor: 'active',
                Filter: false,
                customColumnRender: {
                    render: ({ item }) => {
                        let text = item.active ? 'Active' : 'Not Active';
                        let color = item.active ? 'green' : 'red';
                        return (
                            <ICButton
                                className="ThreatDeckSource-status-button"
                                data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.BUTTONS.STATUS_RSS}-${item.id}`}
                                key={`feed_item_${item.id}_status`}
                                color={color}
                                disabled={
                                    authInfo.role !== LEAD_ANALYST &&
                                    authInfo.role !== ADMINISTRATOR
                                }
                                onClick={() => {
                                    toggleRssFeedItemStatus(item);
                                }}
                            >
                                {text}
                            </ICButton>
                        );
                    },
                    args: { item: 'row.original' }
                }
            }
        ];
    }, []);

    return (
        <Container className="ConfigureSystem-Container">
            {!createRssFeedActive && (
                <span>
                    <Row>
                        <Col>
                            <h1>RSS Feed List</h1>
                        </Col>
                        {(authInfo.role === LEAD_ANALYST ||
                            authInfo.role === ADMINISTRATOR) && (
                            <Col>
                                <ICButton
                                    color="green"
                                    className="ThreatDeckSource-create-button"
                                    data-testid={`${TEST_ATTRIBUTES.RSS_SETTINGS.BUTTONS.CREATE_RSS}`}
                                    onClick={() => {
                                        setCreateRssFeedActive(
                                            !createRssFeedActive
                                        );
                                    }}
                                >
                                    Register RSS Feed
                                </ICButton>
                            </Col>
                        )}
                    </Row>
                    <ICTable
                        columns={rssFeedColumns}
                        data={rssFeeds}
                        reference={'RSSFeeds'}
                    />
                </span>
            )}
            {createRssFeedActive && (
                <RSSFeedsForm
                    setCreateRssFeedActive={setCreateRssFeedActive}
                    rssFeedToEdit={rssFeedToEdit}
                    setRssFeedToEdit={setRssFeedToEdit}
                    regions={regionOptions}
                />
            )}
        </Container>
    );
}
