import { Editor } from '@tinymce/tinymce-react';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import validate from 'validate.js';

import { NAMESPACES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { s3Promise, useS3 } from '../../../../hooks/useS3';
import { getNamespace } from '../../../../redux/selectors';
import { auditsFailedModal, stripTags } from '../../../../utils/utils';
import ICButton from '../../../shared/ICButton';
import MediaInput from './MediaInput';

export default function ArticleForm({
    allowEdit = true,
    article = {},
    closeModal,
    situationReportId,
    updateArticle
}) {
    const [title, setTitle] = useState(article?.title || '');
    const [content, setContent] = useState(article?.content || '');
    const [date, setDate] = useState(
        article?.articleDate
            ? moment.unix(article.articleDate).format('YYYY-MM-DD')
            : ''
    );
    const [media, setMedia] = useState(article?.media);
    const [file, setFile] = useState();
    const [pinned, setPinned] = useState(article?.pinned || false);
    const [editorKey, setEditorKey] = useState(0); // Used to reset tinyMCE
    const situationsNamespace = useSelector(
        getNamespace(NAMESPACES.SITUATIONS_NAMESPACE)
    );

    const [isLoadingS3Response, s3Response, setS3Response] = useS3({
        file,
        situationReportId,
        options: {
            socketNamespace: situationsNamespace,
            s3Action: 'putObject'
        },
        dependencies: [file]
    });
    useEffect(() => {
        if (_.get(s3Response, 'data')) {
            setMedia(s3Response.data);
        }
    }, [s3Response]);

    useEffect(() => {
        if (file) {
            setMedia({});
        }
    }, [file]);

    const TINY_MCE_CONFIG = {
        placeholder: 'Type content here',
        height: 250,
        width: '100%',
        plugins: [
            'autolink',
            'lists',
            'advlist',
            'searchreplace',
            'table',
            'link',
            'autolink',
            'fullscreen',
            `${situationReportId ? 'image' : ''}`
        ],
        toolbar: `${
            situationReportId ? 'image |' : ''
        } blocks |  bold italic underline | numlist bullist | blockquote hr | alignleft aligncenter alignright | link paste | fullscreen `,
        paste_preprocess: function (plugin, args) {
            // We will do our own pasting processing - we allow only certain HTML tags below
            args.content = stripTags(
                args.content,
                'b',
                'i',
                'u',
                'p',
                'br',
                'a',
                'strong',
                'em'
            );
        },
        extended_valid_elements: 'span',
        link_default_target: '_blank',
        browser_spellcheck: true,
        contextmenu: false,
        images_upload_url: 'yep!', // just need a value here, we don't end up using a url
        images_upload_handler: handleImageUpload,
        file_picker_types: 'image',
        images_reuse_filename: true
    };

    useEffect(() => {
        if (situationsNamespace) {
            situationsNamespace.on('articleSaveFailed', () => {
                confirmAlert({
                    title: 'Save Failed',
                    message: 'There was an error.',
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            });
        }
    }, [situationsNamespace]);

    useEffect(() => {
        updateArticle({ content, date, pinned, title, media });
    }, [title, content, date, pinned, media]);

    function confirmCancel() {
        if (!allowEdit || (!title && !content && !date)) {
            // If the user hasn't updated anything, no confirmation needed
            clearAndCloseModal();
            return;
        }
        confirmAlert({
            title: 'Confirm Cancel',
            message: `Are you sure you want to discard your edits?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: clearAndCloseModal
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function auditData() {
        return validate(
            { title, content, date },
            {
                title: {
                    presence: {
                        allowEmpty: false,
                        message: '^Articles must have a title.'
                    }
                },
                content: {
                    presence: {
                        allowEmpty: false,
                        message: '^Articles must have content.'
                    }
                },
                date: {
                    presence: {
                        allowEmpty: false,
                        message: '^Articles must have a date.'
                    }
                }
            }
        );
    }

    function confirmSave() {
        const validationMessages = auditData();
        if (_.isEmpty(validationMessages)) {
            confirmAlert({
                title: 'Confirm Save',
                message: `Are you sure you want to save your changes?`,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: saveArticle
                    },
                    {
                        label: 'No',
                        onClick: () => {}
                    }
                ]
            });
        } else {
            auditsFailedModal(validationMessages);
        }
    }

    function saveArticle() {
        const eventName = article?.id ? 'updateArticle' : 'createArticleDraft';
        situationsNamespace.emit(eventName, {
            id: article?.id,
            content,
            date,
            pinned,
            situationReportId,
            title,
            media
        });
    }

    function clearAndCloseModal() {
        clearData();
        closeModal();
    }

    function clearData() {
        setTitle('');
        setContent('');
        setDate('');
        setPinned(false);
        setEditorKey((currVal) => currVal + 1);
    }

    function updateTitle(e) {
        setTitle(e.target.value);
    }

    function updateDate(e) {
        setDate(e.target.value);
    }

    function updateContent(e) {
        setContent(e);
    }

    function updatePinned(e) {
        setPinned(e.target.checked);
    }

    function handleFileUpload(event) {
        if (event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    }

    /**
     * This image upload handler drives tinyMCE's image upload functionality
     * @param {*} fileBlob blob generated from tinyMCE
     * @param {*} success what tinyMCE expects when the upload is successful
     * @param {*} reject what tinyMCE expects when the upload fails
     */
    async function handleImageUpload(fileBlob, success, reject) {
        try {
            const s3Data = await s3Promise({
                file: fileBlob.blob(), // turns it into a File, which is what we need for uplaoading to S3
                situationReportId,
                options: {
                    socketNamespace: situationsNamespace,
                    s3Action: 'putObject'
                }
            });
            success(_.get(s3Data, 'tagSet.500x500'));
        } catch (error) {
            reject(error);
        }
    }

    return (
        <div className="ContentForm-area">
            {closeModal ? (
                <div className="ContentManagementModal-header">
                    <h2>{article && article.id ? 'Edit' : 'New'} Article</h2>
                    <button
                        className="ReactModal__CloseButton"
                        onClick={confirmCancel}
                        data-testid={`${
                            TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                .CLOSE_ARTICLE_MODAL
                        }${article?.id ? `-${article.id}` : ''}`}
                    >
                        <i className="fa fa-window-close" />
                    </button>
                </div>
            ) : (
                <div className="ContentForm-area-header">
                    <h3>{article && article.id ? 'Edit' : 'New'} Article</h3>
                </div>
            )}
            <div className="ContentForm-area-body">
                <form>
                    <div className="ContentForm-input-container">
                        <label htmlFor="content-article-title">
                            Article Title
                        </label>
                        <div className="input">
                            <input
                                id="content-article-title"
                                className="ContentForm-form-control"
                                type="text"
                                value={title}
                                onChange={updateTitle}
                                disabled={!allowEdit}
                                data-testid={
                                    TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                        .ARTICLE_TITLE
                                }
                            />
                        </div>
                    </div>
                    <div className="ContentForm-input-container">
                        <label htmlFor="content-article-date">Date</label>
                        <div className="input">
                            <input
                                id="content-article-date"
                                className="ContentForm-form-control"
                                type="date"
                                value={date}
                                onChange={updateDate}
                                disabled={!allowEdit}
                                data-testid={
                                    TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                        .ARTICLE_DATE
                                }
                            />
                            {/* TODO: add pin checkbox */}
                            <input
                                id="content-article-pin"
                                type="checkbox"
                                checked={pinned}
                                onChange={updatePinned}
                                disabled={!allowEdit}
                                data-testid={
                                    TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                        .ARTICLE_PINNED
                                }
                            />
                            <label
                                htmlFor="content-article-pin"
                                id="pin-article-label"
                            >
                                Pin article on top of page
                            </label>
                        </div>
                    </div>
                    <div className="ContentForm-input-container">
                        <label htmlFor="Content-article-content">Content</label>
                        <div className="input">
                            <Editor
                                key={editorKey}
                                id={`${'article_content_tinymce'}${
                                    article && article.id
                                        ? `-${article.id}`
                                        : ''
                                }`}
                                value={content || null}
                                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                                init={TINY_MCE_CONFIG}
                                onEditorChange={updateContent}
                                disabled={!allowEdit}
                                data-testid={
                                    TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                        .ARTICLE_CONTENT
                                }
                            />
                        </div>
                    </div>
                    <div className="ContentForm-input-container">
                        <label htmlFor="content-article-media">Media</label>
                        {situationReportId ? (
                            <div className="input">
                                <MediaInput
                                    allowEdit={allowEdit}
                                    isLoadingS3Response={isLoadingS3Response}
                                    media={media}
                                    s3Response={s3Response}
                                    handleFileUpload={handleFileUpload}
                                />
                            </div>
                        ) : (
                            <div className="input">
                                <span>
                                    You will be able to add an image after you
                                    create the Situation Report.
                                </span>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            {closeModal && (
                <div className="ContentManagementModal-footer">
                    <ICButton color="white" onClick={confirmCancel}>
                        Cancel
                    </ICButton>
                    <ICButton
                        disabled={isLoadingS3Response || !allowEdit}
                        color="blue"
                        onClick={confirmSave}
                    >
                        Save
                    </ICButton>
                </div>
            )}
        </div>
    );
}
