import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';
import { FiCircle, FiHexagon } from 'react-icons/fi';
import { IoTrashOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { MAP_DEFAULTS, NAMESPACES, ROLES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { usePrevious, useRefState } from '../../../../hooks/shared';
import { setSavedBoundarySearch } from '../../../../redux/actions';
import {
    getAuthInfo,
    getNamespace,
    isSavedBoundarySearchEditMode,
    isSavedBoundarySearchOpen
} from '../../../../redux/selectors';
import store from '../../../../redux/store';
import SavedBoundarySearch from './SavedBoundarySearch';

const { READ_ONLY } = ROLES;

const DEFAULT_OVERLAY = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: '#FF0000',
    fillOpacity: 0.25,
    visible: true
};

const INITIAL_STATIC_OVERLAY = {
    strokeColor: '#ff7b00',
    strokeOpacity: 0.6,
    strokeWeight: 1,
    fillColor: '#ff7b00',
    fillOpacity: 0.15,
    visible: true
};

export default function AlertMap({
    alert,
    boundary,
    editMode,
    // This is an override if you want the search box to show up no matter what
    loadSearchBox = false,
    onSaveOverlays,
    skipCircles,
    skipPolygons,
    skipSavedBoundaries,
    updateBoundary
}) {
    // State
    /*
      ** A note about ref state **

     There are many places in this file where we don't actually need a ref
     to access the current state value. HOWEVER, because there are also
     several Google maps event listeners for which refs ARE needed, and since
     we will eventually be replacing the Google maps component anyway, and
     since there's really no downside to using the refs, we decided to leave
     things as they are for now.
    */
    const [currentMap, currentMapRef, setCurrentMap] = useRefState(false);
    const [googleMaps, googleMapsRef, setGoogleMaps] = useRefState(false);
    const [overlays, overlaysRef, setOverlays] = useRefState([]);
    const [searchBox, searchBoxRef, setSearchBox] = useRefState(false);
    const [circleRadius, setCircleRadius] = useState('');
    const [selectedCircle, selectedCircleRef, setSelectedCircle] =
        useRefState(false);
    const [shouldSaveOverlay, shouldSaveOverlayRef, setShouldSaveOverlay] =
        useRefState((alert && alert.source_id !== '1') || boundary);

    // Redux
    const savedBoundarySearchOpen = useSelector(isSavedBoundarySearchOpen);
    const savedBoundarySearchEditMode = useSelector(
        isSavedBoundarySearchEditMode
    );
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );
    const authInfo = useSelector(getAuthInfo);

    // Prevs
    const prevAlert = usePrevious(alert);
    const prevEditMode = usePrevious(editMode);

    // Refs
    const geofencing = useRef();
    const geofencingInfo = useRef();
    const geofencingInput = useRef();
    const searchInput = useRef();
    const [containerAttr, setContainerAttr] = useState();

    useEffect(() => {
        // In READ_ONLY view, a new alert gets passed in every few seconds. Whenever the alert
        //  changes, we need to remove the old alert overlays and draw the new ones. However,
        //  this can only happen once Google Maps loads.
        if (googleMaps && prevAlert !== alert) {
            if (authInfo.role === READ_ONLY) {
                deleteOverlays();
                drawOverlays({});
            }
        }
    }, [alert]);

    // Once Google Maps loads, draw overlays/boundaries
    useEffect(() => {
        if (!googleMaps) return;
        if (alert) {
            if (alert.source_id === '1') {
                drawOverlays({
                    overrides: {
                        editable: false,
                        draggable: false,
                        ...INITIAL_STATIC_OVERLAY
                    }
                });
                setShouldSaveOverlay(true);
            } else {
                drawOverlays({});
            }
        } else if (boundary) {
            drawSavedBoundary(boundary);
        }
    }, [googleMaps]);

    useEffect(() => {
        // avoids the specific race condition of google maps not being loaded yet
        // and trying to render the boundary overlay
        if (googleMaps) {
            if (!_.isEmpty(boundary)) {
                deleteOverlays();
                drawSavedBoundary(boundary);
            }
        }
    }, [boundary]);

    // This effect specifically applies to the map management form, whenever a user first opens the map
    //  in view-only mode and then clicks the edit button. Therefore, whenever a boundary is present
    //  (aka we're in the map form) and editMode goes from false to true, we need to reload data
    useEffect(() => {
        if (boundary && prevEditMode === false && editMode === true) {
            deleteOverlays();
            // This loads/configures the search box and other map tools
            handleApiLoaded(currentMap, googleMaps);
            // This, of course, actually draws the boundary
            drawSavedBoundary(boundary);
        }
    }, [editMode]);

    // Whenever the overlays change, call onSaveOverlays to communicate this change up to any parent components
    useEffect(() => {
        if (typeof onSaveOverlays === 'function') {
            onSaveOverlays(overlays);
        }
    }, [overlays]);

    function deleteOverlays() {
        overlaysRef.current.forEach((overlay) => {
            overlay.value.setMap(null);
        });
        setOverlays([]);
        pruneOverlays();
    }

    function saveOverlay(overlay) {
        setOverlays((currVal) => [...currVal, overlay]);
        pruneOverlays();
    }

    function pruneOverlays() {
        // We have some overlays that have been removed ... prune them
        setOverlays((currVal) =>
            _.filter(currVal, (o) => {
                return o.value.map !== null;
            })
        );
    }

    // Utility to calculate radius based on map zoom
    function calculateDefaultRadius() {
        return (
            Math.pow(2, 22 - currentMapRef.current.getZoom()) * 1128.5 * 0.0027
        );
    }

    function drawOverlays({ overrides = {} }) {
        let bounds = new googleMapsRef.current.LatLngBounds();

        _.get(alert, 'locations').forEach((location) => {
            if (location.type === 'circle') {
                let overlay = drawCircle(location.center, location.radius);
                if (editMode || authInfo.role === READ_ONLY) {
                    saveOverlay({ type: 'circle', value: overlay });
                }
                bounds.union(overlay.getBounds());
            }
            if (location.type === 'polygon') {
                let path = [];
                location.coordinates[0].forEach((coordinate) => {
                    path.push({ lat: coordinate[1], lng: coordinate[0] });
                });
                let polygon;
                if (alert && alert.source_id === '1') {
                    polygon = drawPolygon({
                        path,
                        overrides: {
                            ...overrides,
                            draggable: false,
                            editable: false
                        },
                        isRemovable: false
                    });
                } else {
                    polygon = drawPolygon({
                        path,
                        overrides: {
                            ...overrides,
                            draggable: editMode && !location.isSavedBorder,
                            editable: editMode && !location.isSavedBorder
                        },
                        isRemovable: true
                    });
                }
                polygon.getPath().forEach((latLng) => {
                    bounds.union(
                        new googleMapsRef.current.LatLngBounds(latLng, latLng)
                    );
                });

                if (
                    (editMode && shouldSaveOverlayRef.current) ||
                    authInfo.role === READ_ONLY
                ) {
                    saveOverlay({
                        type: 'polygon',
                        value: polygon,
                        isSavedBorder: location.isSavedBorder
                    });
                }
            }
        });
        if (typeof currentMapRef.current.fitBounds !== 'undefined' && bounds) {
            currentMapRef.current.fitBounds(bounds);
        }
    }

    function drawDefaultPolygon() {
        let center = currentMapRef.current.getCenter().toJSON();
        let latVariance =
            (center.lat -
                currentMapRef.current.getBounds().getSouthWest().lat()) /
            4;
        let lngVariance =
            (center.lng -
                currentMapRef.current.getBounds().getSouthWest().lng()) /
            4;
        let southWest = {
            lat: center.lat - latVariance,
            lng: center.lng - lngVariance
        };
        let northEast = {
            lat: center.lat + latVariance,
            lng: center.lng + lngVariance
        };
        let path = [
            { lat: northEast.lat, lng: northEast.lng },
            { lat: southWest.lat, lng: northEast.lng },
            { lat: southWest.lat, lng: southWest.lng },
            { lat: northEast.lat, lng: southWest.lng }
        ];
        let polygon = drawPolygon({ path });
        saveOverlay({ type: 'polygon', value: polygon });
    }

    function drawPolygon({ path, overrides = {}, isRemovable = true }) {
        let polygon = new googleMapsRef.current.Polygon({
            ...DEFAULT_OVERLAY,
            ...{
                editable: alert === false || editMode,
                draggable: alert === false || editMode,
                paths: path,
                map: currentMapRef.current
            },
            ...overrides
        });

        if (alert === false || editMode) {
            if (isRemovable) {
                googleMapsRef.current.event.addListener(
                    polygon,
                    'rightclick',
                    () => {
                        polygon.setMap(null);
                        pruneOverlays();
                    }
                );
            }
        }
        return polygon;
    }

    function drawDefaultCircle() {
        let center = currentMapRef.current.getCenter();
        let radius = calculateDefaultRadius();
        let circle = drawCircle(center, radius);
        saveOverlay({ type: 'circle', value: circle });
    }

    function drawCircle(center, radius) {
        let circle = new googleMapsRef.current.Circle({
            ...DEFAULT_OVERLAY,
            ...{
                editable: alert === false || editMode,
                draggable: alert === false || editMode,
                center: center,
                radius: radius,
                map: currentMapRef.current
            }
        });
        if (alert === false || editMode) {
            setCircleRadius(getMiles(radius));
            hideCircleRadius({ hide: false });
            setSelectedCircle(circle);
            googleMapsRef.current.event.addListener(
                circle,
                'radius_changed',
                () => {
                    updateRadiusInfo(circle);
                }
            );
            googleMapsRef.current.event.addListener(circle, 'click', () => {
                setSelectedCircle(circle);
                updateRadiusInfo(circle);
            });
            googleMapsRef.current.event.addListener(
                circle,
                'rightclick',
                () => {
                    circle.setMap(null);
                    pruneOverlays();
                }
            );
        } else {
            setCircleRadius(getMiles(radius));
            hideCircleRadius({ hide: false, disableInput: true });
            googleMapsRef.current.event.addListener(circle, 'click', () => {
                setSelectedCircle(circle);
                updateRadiusInfo(circle);
            });
        }
        return circle;
    }

    function toggleSavedBoundarySearch() {
        threatsNamespace.emit('getEnabledBoundaries');
        store.dispatch(
            setSavedBoundarySearch(!savedBoundarySearchOpen, editMode)
        );
    }

    function panToPolygon(pathArray) {
        let bounds = new googleMapsRef.current.LatLngBounds();
        pathArray.forEach((p) => {
            if (Array.isArray(p)) {
                p.forEach((nestedP) => {
                    let latLng = new googleMapsRef.current.LatLng(
                        nestedP.lat,
                        nestedP.lng
                    );
                    bounds.extend(latLng);
                });
            } else {
                let latLng = new googleMapsRef.current.LatLng(p.lat, p.lng);
                bounds.extend(latLng);
            }
        });

        currentMapRef.current.fitBounds(bounds);
    }

    function drawSavedBoundary(boundary) {
        // TODO: The below GeoJSON loader is smarter to do, but has some sort of Google API bug
        // when we hear back from them about it, use this simple method instead of the below
        // to build out system polygons
        // let polygon = new currentMapRef.current.data.addGeoJson(
        //     boundary.geometry
        // );
        let overlayObject;
        let pathArray;
        if (boundary.geometry.geometry.type === 'Polygon') {
            boundary.geometry.geometry.coordinates.forEach((polygonPath) => {
                pathArray = polygonPath.map((p) => ({ lat: p[1], lng: p[0] }));
                let polygon = drawPolygon({
                    path: pathArray,
                    overrides: {
                        editable: editMode,
                        draggable: editMode
                    }
                });
                overlayObject = { type: 'polygon', value: polygon };
                overlayObject.isSavedBorder = true;
                saveOverlay(overlayObject);
                if (pathArray) {
                    panToPolygon(pathArray);
                }
            });
        } else if (boundary.geometry.geometry.type === 'MultiPolygon') {
            pathArray = [];
            let multiPolygonPathArrayForPanning = [];
            boundary.geometry.geometry.coordinates.forEach((polygon) => {
                polygon.forEach((polygonPath) => {
                    pathArray = polygonPath.map((p) => ({
                        lat: p[1],
                        lng: p[0]
                    }));
                    multiPolygonPathArrayForPanning.push(pathArray);

                    let polygon = drawPolygon({
                        path: pathArray,
                        overrides: {
                            editable: editMode,
                            draggable: editMode
                        }
                    });
                    overlayObject = { type: 'polygon', value: polygon };
                    overlayObject.isSavedBorder = true;
                    saveOverlay(overlayObject);
                    if (multiPolygonPathArrayForPanning) {
                        panToPolygon(multiPolygonPathArrayForPanning);
                    }
                });
            });
        }
    }

    function updateRadiusInfo(circle) {
        setCircleRadius(getMiles(circle.getRadius()));
    }

    function updateCircleRadius(event) {
        let radius = event.target.value;
        if (radius < 0) {
            radius = radius * -1;
        }
        setCircleRadius(radius);
        if (selectedCircleRef.current) {
            selectedCircleRef.current.setRadius(getMeters(radius));
        }
    }

    function getMiles(meters) {
        return parseFloat((meters * 0.000621371192).toFixed(2));
    }

    function getMeters(miles) {
        return parseFloat((miles * 1609.344).toFixed(2));
    }

    function hideCircleRadius({ hide, disableInput }) {
        geofencingInfo.current.style.display = hide ? 'none' : 'block';
        if (disableInput) {
            geofencingInput.current.disabled = true;
        }
    }

    function handleBoundsChanged() {
        searchBoxRef.current.setBounds(currentMapRef.current.getBounds());
    }

    function handleSearchPlaceSelected() {
        let places = searchBoxRef.current.getPlaces();
        if (places.length === 0) {
            return;
        }
        // For each place, get the icon, name and location.
        const bounds = new googleMapsRef.current.LatLngBounds();
        places.forEach((place) => {
            if (!place.geometry) {
                console.log('Returned place contains no geometry');
                return;
            }
            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });
        currentMapRef.current.fitBounds(bounds);
    }

    function handleApiLoaded(map, maps) {
        let searchBox = false;
        maps.event.addListenerOnce(map, 'tilesloaded', function () {
            setContainerAttr({
                ['data-testid']: TEST_ATTRIBUTES.ALERT_FORM.MAP_IS_VISIBLE
            });
        });
        if ((!alert && !boundary) || editMode || loadSearchBox) {
            searchBox = new maps.places.SearchBox(searchInput.current);
            map.controls[maps.ControlPosition.TOP_LEFT].push(
                searchInput.current
            );
            map.addListener('bounds_changed', handleBoundsChanged);
            searchBox.addListener('places_changed', handleSearchPlaceSelected);
            setTimeout(() => {
                // check that the ref still exists in case they quickly close the map/form
                if (searchInput.current) {
                    searchInput.current.style.display = 'block';
                }
            }, 2000);
            map.controls[maps.ControlPosition.TOP_RIGHT].push(
                geofencing.current
            );
            setTimeout(() => {
                // check that the ref still exists in case they quickly close the map/form
                if (searchInput.current) {
                    geofencing.current.style.display = 'block';
                }
            }, 2000);
            map.controls[maps.ControlPosition.TOP_RIGHT].push(
                geofencingInfo.current
            );
        }

        // Set to state
        setSearchBox(searchBox);
        setCurrentMap(map);
        setGoogleMaps(maps);
    }

    return (
        <div className="MapContainer" {...containerAttr}>
            <div className="SavedBoundarySearch">
                {savedBoundarySearchOpen &&
                    editMode === savedBoundarySearchEditMode && (
                        <SavedBoundarySearch
                            drawSavedBoundary={drawSavedBoundary}
                            updateBoundary={updateBoundary}
                        />
                    )}
            </div>
            <input
                ref={searchInput}
                className="controls AlertMap-search"
                type="text"
                placeholder="Search for location on map"
                data-testid={TEST_ATTRIBUTES.ALERT_FORM.MAP_SEARCH_BOX}
            />
            <div ref={geofencing} className="AlertMap-geofencing">
                <IoTrashOutline
                    onClick={deleteOverlays}
                    className={`btn-geofence`}
                    size={'28'}
                    data-testid={TEST_ATTRIBUTES.ALERT_FORM.MAP_DELETE_POLYGON}
                />
                {!skipPolygons && (
                    <FiHexagon
                        onClick={drawDefaultPolygon}
                        className={`btn-geofence`}
                        size={'28'}
                        data-testid={
                            TEST_ATTRIBUTES.ALERT_FORM.MAP_CREATE_BOX_POLYGON
                        }
                    />
                )}
                {!skipCircles && (
                    <FiCircle
                        onClick={drawDefaultCircle}
                        className={`btn-geofence`}
                        size={'28'}
                        data-testid={
                            TEST_ATTRIBUTES.ALERT_FORM.MAP_CREATE_CIRCLE_POLYGON
                        }
                    />
                )}
                {!skipSavedBoundaries && (
                    <AiOutlineSave
                        onClick={toggleSavedBoundarySearch}
                        className={`btn-geofence`}
                        size={'28'}
                        data-testid={
                            TEST_ATTRIBUTES.ALERT_FORM.MAP_SAVED_BOUNDARY
                        }
                    />
                )}
            </div>
            <div
                ref={geofencingInfo}
                className="AlertMap-geofencing-info"
                style={{ position: 'absolute', zIndex: 1 }}
            >
                {'Radius (miles) '}
                <input
                    ref={geofencingInput}
                    className="controls AlertMap-radius"
                    type="number"
                    value={circleRadius}
                    min={0}
                    onChange={updateCircleRadius}
                    data-testid={TEST_ATTRIBUTES.ALERT_FORM.RADIUS_MILES_INPUT}
                />
            </div>
            <GoogleMapReact
                bootstrapURLKeys={MAP_DEFAULTS.bootstrapURLKeys}
                defaultCenter={MAP_DEFAULTS.defaultCenter}
                defaultZoom={MAP_DEFAULTS.defaultZoom}
                yesIWantToUseGoogleMapApiInternals={true}
                options={MAP_DEFAULTS.options}
                onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                }
            />
        </div>
    );
}
