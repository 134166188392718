import './Column.css';

import filter from 'lodash/filter';
import React, { useEffect, useRef, useState } from 'react';
import { FaCog, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { COLUMNS } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import {
    checkColumnConfig,
    toggleUnviewedConfig,
    toggleViewedConfig
} from '../../../../localStorage/readItemColumnConfigStorage';
import { getColumnContent } from '../../../../redux/selectors';
import { doesItemMatchTDColumnFilters } from '../../../../utils/utils';
import ThreatDeckCard from '../../shared/cards/card/ThreatDeckCard';
import EventCard from '../../shared/events/EventCard';

function renderOdincard(contentType, content, index, localColumnConfig, style) {
    const mergedStyles = {
        ...style,
        // we have to overwrite some styles passed down from react-window
        backgroundColor: '#f3f9f9',
        color: 'black'
    };

    if (contentType === COLUMNS.CONTENT_TYPE.FEED_ITEMS) {
        return (
            <ThreatDeckCard
                item={content}
                key={content.id}
                localColumnConfig={localColumnConfig}
                style={mergedStyles}
                cardView={true}
            />
        );
    } else if (contentType === COLUMNS.CONTENT_TYPE.EVENTS) {
        return (
            <EventCard
                key={`td-event-${content.id}`}
                event={content}
                style={mergedStyles}
                view="COLUMN"
            />
        );
    }
}

export default function Column({
    column,
    openModal,
    closeModal,
    isLoading,
    innerRef,
    setShowCreateGroupModal,
    groupToEdit,
    ...rest
}) {
    // redux
    const columnContent = useSelector(getColumnContent(column.contentType));

    // state
    const [localConfig, setLocalConfig] = useState(
        checkColumnConfig(column.id)
    );

    const [filteredContent, setFilteredContent] = useState(
        filter(columnContent, (item) => {
            return doesItemMatchTDColumnFilters(item, column, localConfig);
        })
    );
    // refs
    const listRef = useRef(null);
    const scrollOffsetRef = useRef(0);

    useEffect(() => {
        if (columnContent && column && localConfig) {
            setFilteredContent((prevFilteredContent) => {
                const prevLength = prevFilteredContent?.length || 0;
                if (!column.contentType) {
                    console.log(
                        `Column ${column.id} has no content type. No content will display.`,
                        {
                            column
                        }
                    );
                    return [];
                }

                const newFilteredContent = filter(columnContent, (item) => {
                    return doesItemMatchTDColumnFilters(
                        item,
                        column,
                        localConfig
                    );
                });

                if (!prevFilteredContent) {
                    return newFilteredContent;
                }

                const newLength = newFilteredContent.length;
                // quick shallow compare to see if we need to update the filtered content or not
                const noLeadingChange = prevFilteredContent.every((pfc, i) => {
                    if (
                        column.contentType === COLUMNS.CONTENT_TYPE.FEED_ITEMS
                    ) {
                        // the use case here is that people might potentially hide viewed/unviewed items
                        // they might also update the ui_type of an item and we want to reflect that immediately in the ui
                        return (
                            pfc?.id === newFilteredContent[i]?.id &&
                            pfc?.annotation.ui_type ===
                                newFilteredContent[i]?.annotation.ui_type
                        );
                    } else {
                        return pfc?.id === newFilteredContent[i]?.id;
                    }
                });

                if (noLeadingChange && prevLength !== newLength) {
                    // we don't want to scroll if the new content matches the old contentfor the entirety of its length
                    // but since we may have new paginated content added to the end of the list
                    // or removed/expired content from the end of the list return the new filtered content
                    return newFilteredContent;
                } else if (noLeadingChange && prevLength === newLength) {
                    // if all ids and indexes match, and the lengths are the same:
                    // we should not scroll and we should not update the filtered content
                    return prevFilteredContent;
                }

                if (
                    listRef?.current &&
                    scrollOffsetRef?.current > 0 &&
                    prevLength < newLength
                ) {
                    const offset =
                        scrollOffsetRef.current +
                        (newLength - prevLength) *
                            COLUMNS.CONTENT_HEIGHT[column.contentType];

                    listRef.current.scrollTo(offset);
                }

                return newFilteredContent;
            });
        }
    }, [columnContent, column, localConfig]);

    useEffect(() => {
        setLocalConfig(checkColumnConfig(column.id));
    }, [column.id]);

    function openGroupModal() {
        groupToEdit.current = column?.group[0];
        setShowCreateGroupModal(true);
    }

    function toggleViewed() {
        toggleViewedConfig(column?.id);
        setLocalConfig(checkColumnConfig(column.id));
    }

    function toggleUnviewed() {
        toggleUnviewedConfig(column?.id);
        setLocalConfig(checkColumnConfig(column.id));
    }
    function handleScroll({
        scrollOffset,
        scrollDirection,
        scrollUpdateWasRequested
    }) {
        scrollOffsetRef.current = scrollOffset;
    }

    return (
        <div
            className={`TD-Column`}
            data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN.TD_COLUMN}`}
            ref={innerRef}
            {...rest}
        >
            <div className="TD-Column-name">
                <div className="TD-column-name-box">
                    <span>{column.name}</span>
                    {column?.type === 'GROUP' && (
                        <div
                            className="TD-column-group-name"
                            onClick={openGroupModal}
                        >
                            {column?.group[0]?.name}
                        </div>
                    )}
                </div>
                <div className="TD-Column-config">
                    {column?.contentType ===
                        COLUMNS.CONTENT_TYPE.FEED_ITEMS && (
                        <div
                            data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN.VIEWED}-${column.id}`}
                            className={`${
                                localConfig.showViewed
                                    ? ''
                                    : 'TD-Column-config-off'
                            }`}
                            data-tooltip-id={`${column.id}-show-viewed`}
                            data-tooltip-content={
                                localConfig.showViewed
                                    ? 'Show Viewed'
                                    : "Don't Show Viewed"
                            }
                            onClick={toggleViewed}
                        >
                            <FaRegEye />
                            <Tooltip id={`${column.id}-show-viewed`} />
                        </div>
                    )}
                    {column?.contentType ===
                        COLUMNS.CONTENT_TYPE.FEED_ITEMS && (
                        <div
                            data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN.UNVIEWED}-${column.id}`}
                            className={`${
                                localConfig.showUnviewed
                                    ? ''
                                    : 'TD-Column-config-off'
                            }`}
                            data-tooltip-id={`${column.id}-show-unviewed`}
                            data-tooltip-content={
                                localConfig.showUnviewed
                                    ? 'Show Unviewed'
                                    : "Don't Show Unviewed"
                            }
                            onClick={toggleUnviewed}
                        >
                            <FaRegEyeSlash />
                            <Tooltip id={`${column.id}-show-unviewed`} />
                        </div>
                    )}
                    <div
                        className="TD-Column-cog"
                        data-testid={`${
                            TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN.CONFIG
                        }${column?.id ? `-${column.id}` : ''}`}
                        onClick={() => {
                            openModal({ column });
                        }}
                    >
                        <FaCog />
                    </div>
                </div>
            </div>
            <div className={'TD-Column-cards'}>
                {!!filteredContent?.length && (
                    <AutoSizer>
                        {({ height, width }) => {
                            return (
                                <FixedSizeList
                                    ref={listRef}
                                    height={height}
                                    width={275}
                                    itemData={filteredContent}
                                    itemCount={filteredContent?.length}
                                    itemKey={(index, data) => data[index].id}
                                    itemSize={
                                        COLUMNS.CONTENT_HEIGHT[
                                            column.contentType
                                        ]
                                    } // This needs to be a fixed value.
                                    onScroll={handleScroll}
                                    style={{
                                        overflowX: 'hidden',
                                        scrollbarWidth: 'thin'
                                    }}
                                >
                                    {({ index, style }) => {
                                        const adjustedStyle = {
                                            ...style,
                                            width: 259,
                                            height: 'fit-content',
                                            overflowX: 'hidden'
                                        };

                                        return renderOdincard(
                                            column.contentType,
                                            filteredContent[index],
                                            index,
                                            localConfig,
                                            adjustedStyle
                                        );
                                    }}
                                </FixedSizeList>
                            );
                        }}
                    </AutoSizer>
                )}
            </div>
        </div>
    );
}
