import each from 'lodash/each';
import includes from 'lodash/includes';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { APPS, CAPABILITIES, NAMESPACES, ROLES } from '../../config/constants';
import { TEST_ATTRIBUTES } from '../../config/testConstants';
import { logoutUser } from '../../redux/actions';
import {
    checkSocketConnections,
    getApplication,
    getAuthInfo,
    getEnabledCapabilities,
    getNamespace,
    getSocketManager
} from '../../redux/selectors';
import { signOut } from '../../utils/awsAmplifyUtils';
import ConfigureSystemModal from './config/ConfigureSystemModal';
import UserDetailsModal from './config/UserDetailsModal';

const { READ_ONLY, LABELER } = ROLES;

export default function Header({ login }) {
    // State
    const [configureSystem, setConfigureSystem] = useState(false);
    const [manageUserProfile, setManageUserProfile] = useState(false);
    // Redux
    const app = useSelector(getApplication);
    const authInfo = useSelector(getAuthInfo);
    const areSocketsConnected = useSelector(checkSocketConnections);
    const usersNamespace = useSelector(
        getNamespace(NAMESPACES.USERS_NAMESPACE)
    );
    const enabledCapabilities = useSelector(getEnabledCapabilities);
    const socketManager = useSelector(getSocketManager);
    const dispatch = useDispatch();

    async function handleLogout(e) {
        e.preventDefault();
        await signOut();
        window.localStorage.removeItem('sessionEmail');
        usersNamespace.emit('logOut');
        onLogout({ socketManager });
    }

    function onLogout({ id, socketManager }) {
        signOut();
        each(socketManager.nsps, (nsp) => {
            nsp.disconnect();
        });
        dispatch(logoutUser(id));
    }

    function showManageUserProfile(e) {
        e.preventDefault();
        setManageUserProfile(true);
    }

    function showConfigureSystem(e) {
        e.preventDefault();
        setConfigureSystem(true);
    }

    function closeManageUserProfile() {
        setManageUserProfile(false);
    }

    function closeSystemConfiguration() {
        setConfigureSystem(false);
    }

    function activeLinkStyle({ isActive }) {
        return isActive
            ? {
                  fontWeight: 'bold',
                  cursor: 'default'
              }
            : undefined;
    }

    function logoutLink() {
        return (
            <>
                <a
                    className="Header-user-logout"
                    href="#"
                    onClick={handleLogout}
                    data-testid={TEST_ATTRIBUTES.BUTTONS.LOGOUT}
                >
                    Logout
                </a>
            </>
        );
    }

    function userProfileLink() {
        return (
            <>
                <a
                    className="Header-user-profile"
                    href="#"
                    title="Manage your profile"
                    onClick={showManageUserProfile}
                    data-testid={TEST_ATTRIBUTES.BUTTONS.OPEN_USER_MODAL}
                >
                    My Settings
                </a>
                |
            </>
        );
    }

    function configureSystemLink() {
        if (!includes([READ_ONLY, LABELER], authInfo?.role)) {
            return (
                <>
                    <a
                        className="Header-configure-system"
                        href="#"
                        title="Configuration"
                        onClick={showConfigureSystem}
                        data-testid={TEST_ATTRIBUTES.BUTTONS.OPEN_SYSTEM_MODAL}
                    >
                        Configuration
                    </a>
                    |
                </>
            );
        }
    }

    function icLinks() {
        return (
            <>
                <NavLink
                    className="Header-configure-system"
                    to="/"
                    title="Threat Cards"
                    style={activeLinkStyle}
                >
                    Threat Cards
                </NavLink>
                |
                {enabledCapabilities.includes(
                    CAPABILITIES.SITUATION_REPORTS
                ) && (
                    <>
                        <NavLink
                            className="Header-configure-system"
                            to="/situation-reports"
                            title="Situation Reports"
                            style={activeLinkStyle}
                        >
                            Situation Reports
                        </NavLink>
                        |
                    </>
                )}
                {enabledCapabilities.includes(CAPABILITIES.TRAVEL_BRIEFS) && (
                    <>
                        <NavLink
                            className="Header-configure-system"
                            to="/travel-briefs"
                            title="Travel Briefs"
                            style={activeLinkStyle}
                        >
                            Travel Briefs
                        </NavLink>
                        |
                    </>
                )}
            </>
        );
    }

    function applicationName() {
        return (
            <span>
                {app?.logo && <img className="Header-logo" src={app.logo} />}
                {app?.label ?? 'Intelligence Center'}
                <span className="Header-status">
                    {!login && !areSocketsConnected ? '(DISCONNECTED)' : ''}
                </span>
            </span>
        );
    }

    function appLinks() {
        const eventGroupingCapability = enabledCapabilities.includes(
            CAPABILITIES.EVENT_GROUPING
        );
        if (authInfo.user.role !== READ_ONLY) {
            if (app?.name === APPS.IC.name) {
                if (!includes([READ_ONLY, LABELER], authInfo?.role)) {
                    return icLinks();
                }
            } else if (app?.name === APPS.TD.name) {
                return (
                    authInfo?.role &&
                    !includes([READ_ONLY, LABELER], authInfo?.role) && (
                        <>
                            <NavLink
                                className="Header-configure-system"
                                to="/"
                                title="GATOR"
                                style={activeLinkStyle}
                            >
                                GATOR Feed
                            </NavLink>
                            {eventGroupingCapability && (
                                <>
                                    |
                                    <NavLink
                                        className="Header-configure-system"
                                        to="/events"
                                        title="Events"
                                        style={activeLinkStyle}
                                    >
                                        GATOR Events
                                    </NavLink>
                                </>
                            )}
                            |
                            <NavLink
                                className="Header-configure-system"
                                to="/labeling"
                                title="Labeling"
                                style={activeLinkStyle}
                            >
                                Labeling
                            </NavLink>
                            |
                        </>
                    )
                );
            }
        }
    }

    return (
        <header className="Header-bar">
            {applicationName()}
            {!login && authInfo?.user?.role && (
                <span>
                    <div className="Header-user">
                        {authInfo.user && authInfo.user.id
                            ? userProfileLink()
                            : ''}
                        {appLinks()}
                        {configureSystemLink()}
                        {areSocketsConnected && logoutLink()}
                    </div>
                    <UserDetailsModal
                        isOpen={manageUserProfile}
                        onClose={closeManageUserProfile}
                    />
                    {authInfo?.role &&
                        !includes([READ_ONLY, LABELER], authInfo?.role) && (
                            <ConfigureSystemModal
                                isOpen={configureSystem}
                                onClose={closeSystemConfiguration}
                            />
                        )}
                </span>
            )}
        </header>
    );
}
