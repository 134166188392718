import kebabCase from 'lodash/kebabCase';
import React from 'react';

import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';

export default function CardCategory({ item }) {
    const category =
        item?.annotation?.ui_category || item?.annotation?.ml_category;

    return (
        <div
            className={`ThreatDeckCard-category ${kebabCase(category)}`}
            data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.CARDS.CATEGORY}-${item.id}`}
        >
            {category}
        </div>
    );
}
