import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';

import { NAMESPACES } from '../../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';
import { setAlertModal } from '../../../../../redux/actions';
import { getNamespace } from '../../../../../redux/selectors';
import ICButton from '../../../../shared/ICButton';

/**
 * RejectAlertButton component
 * @param {object} alert item pending approval
 * @param {function} displayAlertModal used to close the modal after clicking reject
 * @param {boolean} isUpdate indicates if the alert is an update
 * @param {function} onAfterClick used to hide the alert panel of the rejected alert
 * @param {object} threatsNamespace socket namespace for threats
 * @returns RejectAlertButton component
 */
export default function RejectAlertButton({ alert, isUpdate, onAfterClick }) {
    // redux
    const dispatch = useDispatch();
    function displayAlertModal({ alert }) {
        dispatch(setAlertModal({ alert }));
    }
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );

    const rejectAlert = () => {
        confirmAlert({
            title: 'Confirm Reject',
            message: `Are you sure you want to reject ${alert.title}?  This will send it back to the analyst to edit.`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let socketEmit =
                            alert.isUpdate || isUpdate
                                ? 'rejectUpdate'
                                : 'rejectAlert';
                        displayAlertModal({ alert: false });
                        if (onAfterClick) {
                            onAfterClick();
                        }
                        threatsNamespace.emit(socketEmit, alert.id);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    };

    return (
        <ICButton
            onClick={rejectAlert}
            color="dark-red"
            data-testid={`${TEST_ATTRIBUTES.BUTTONS.REJECT}-${alert.id}`}
        >
            Reject
        </ICButton>
    );
}
