import 'react-tabs/style/react-tabs.css';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { APPS, ROLES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import {
    getApplication,
    getAuthInfo,
    getEnabledCapabilities
} from '../../../redux/selectors';
import MapManagement from '../../intelligence-center/config/map-management/MapManagement';
import RSSFeeds from '../../threat-deck/config/rss-feeds/RSSFeeds';
import TwitterKnownAccounts from '../../threat-deck/config/twitter-accounts/TwitterKnownAccounts';
import TwitterFilters from '../../threat-deck/config/twitter-filters/TwitterFilters';
import UserManagement from './user-management/UserManagement';

const { ADMINISTRATOR, LEAD_ANALYST } = ROLES;

export default function ConfigureSystemModal({ isOpen, onClose }) {
    const app = useSelector(getApplication);
    const authInfo = useSelector(getAuthInfo);
    const enabledCapabilities = useSelector(getEnabledCapabilities);
    const isCustomMapsEnabled = _.includes(enabledCapabilities, 'custom_maps');
    const isIC = app.name === APPS.IC.name;
    const isTD = app.name === APPS.TD.name;

    const [tabs, setTabs] = useState([]);
    const [hideTabs, setHideTabs] = useState(false);

    useEffect(() => {
        let tabObject = [];
        if (authInfo.role === LEAD_ANALYST || authInfo.role === ADMINISTRATOR) {
            tabObject = [
                {
                    title: 'Users',
                    view: <UserManagement setHideTabs={setHideTabs} />
                }
            ];
        }

        if (isIC && isCustomMapsEnabled) {
            tabObject.push({ title: 'Maps', view: <MapManagement /> });
        }

        if (isTD) {
            tabObject.push({
                title: 'RSS Feeds',
                view: <RSSFeeds />
            });
            tabObject.push({
                title: 'Twitter Accounts',
                view: <TwitterKnownAccounts />
            });
            tabObject.push({
                title: 'Twitter Filters',
                view: <TwitterFilters />
            });
        }

        // If we are not in production, display the "DevTools" tab
        // TODO: AWS environment variable Tasks do not work the same as regular env variables. These do not get
        // seen on the frontend as expected
        // if (
        //     process.env.REACT_APP_ENV !== 'prod' &&
        //     process.env.REACT_APP_ENV !== 'production' &&
        //     process.env.REACT_APP_ENV !== 'staging'
        // ) {
        //     // tabObject.push({ title: 'DevTools', view: <DevToolsTab /> });
        // }

        setTabs(tabObject);
    }, [authInfo]);

    function onCloseHelper() {
        setHideTabs(false);
        onClose();
    }

    return (
        <div className="ConfigureSystem-Modal">
            <Modal
                isOpen={isOpen}
                contentLabel="Configuration"
                ariaHideApp={false}
            >
                <Row className="ConfigureSystem-Row">
                    <Col className="ConfigureSystem-Column">
                        <button
                            className="ReactModal__CloseButton"
                            onClick={onCloseHelper}
                            data-testid={`${TEST_ATTRIBUTES.BUTTONS.CLOSE_SYSTEM_MODAL}`}
                        >
                            <i className="fa fa-window-close" />
                        </button>
                    </Col>
                </Row>
                <Tabs>
                    {!hideTabs && (
                        <TabList>
                            {tabs.map((tab) => (
                                <Tab key={tab.title}>{tab.title}</Tab>
                            ))}
                        </TabList>
                    )}
                    {tabs.map((tab) => (
                        <TabPanel key={`${tab.title}Panel`}>
                            {tab.view}
                        </TabPanel>
                    ))}
                </Tabs>
            </Modal>
        </div>
    );
}
