import _ from 'lodash';
import moment from 'moment';

import {
    DRAFT_AUTHOR_DISPLAY_OPTIONS,
    MINUTES_IN_MS,
    VIEWS
} from '../../config/constants';
import { doesItemMatchAnyColumn } from '../../utils/utils';
import {
    ADD_THREAT_DECK_FEED_ITEM,
    ARCHIVE_EVENT,
    CLEAR_MESSAGES_LIST,
    DECREMENT_REPLY_COUNT,
    DELETE_CHAT_MESSAGE,
    EXPIRE_THREAT_DECK_FEED_ITEMS,
    FILTER_INITIAL_FEED_ITEMS,
    GET_BOUNDARY,
    GROUP_CREATED,
    GROUP_DELETED,
    GROUP_UPDATED,
    INCREMENT_REPLY_COUNT,
    ON_COLUMN_SAVED,
    REFRESH_THREAT_DECK_FEED_ITEMS,
    REMOVE_THREAT_DECK_FEED_ITEM,
    SET_ALERT_FILTERS,
    SET_ALERT_MODAL,
    SET_APP,
    SET_APP_REGIONS,
    SET_APP_USERS,
    SET_AVAILABLE_GROUP_COLUMNS,
    SET_CATEGORIES_LIST,
    SET_CURRENT_VIEW,
    SET_DRAFT_AUTHOR_CONTENT_FILTER,
    SET_ENABLED_CAPABILITIES_LIST,
    SET_EVENT_FILTERS,
    SET_GROUPS,
    SET_MESSAGE_READ_STATUS,
    SET_MESSAGES_LIST,
    SET_SAVED_BOUNDARY_SEARCH,
    SET_THREAD_READ_STATUS,
    SET_THREAT_DECK_EVENTS,
    SET_THREAT_DECK_FEED_ITEMS,
    SET_TWITTER_RULES,
    SET_USER_GROUPS,
    TOGGLE_MODAL,
    UPDATE_EVENT,
    UPDATE_THREAT_DECK_FEED_ITEM_ANNOTATION
} from '../actionTypes';

const initialState = {
    application: {},
    enabledCapabilities: [],
    users: [],
    categories: [],
    alertFilters: JSON.parse(window.localStorage.getItem('alertFilters')) || {},
    eventFilters: JSON.parse(window.localStorage.getItem('eventFilters')) || {},
    draftAuthorContentFilter: DRAFT_AUTHOR_DISPLAY_OPTIONS[1].value,
    alertModal: {
        id: false,
        editMode: false,
        isUpdate: false,
        modalCallback: () => {}
    },
    savedBoundarySearch: {
        active: false
    },
    currentView: VIEWS.CREATE_VIEW,
    chatMessages: [],
    threatdeckFeedItems: [],
    availableGroupColumns: [],
    groups: [],
    userGroups: [],
    userGroupColumns: [],
    modals: {},
    threatDeckEvents: new Map(),
    twitterRules: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_APP: {
            return {
                ...state,
                application: action.payload
            };
        }
        case SET_APP_REGIONS: {
            return {
                ...state,
                applicationRegions: action.payload
            };
        }
        case SET_APP_USERS: {
            return {
                ...state,
                users: action.payload
            };
        }
        case TOGGLE_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.payload.modalName]: {
                        data: action.payload.data,
                        isOpen: action.payload.value
                    }
                }
            };
        }
        case SET_ALERT_MODAL: {
            return {
                ...state,
                alertModal: {
                    id: action.payload.id,
                    editMode: action.payload.editMode || false,
                    isUpdate: action.payload.isUpdate || false,
                    modalCallback: action.payload.modalCallback
                }
            };
        }
        case SET_SAVED_BOUNDARY_SEARCH: {
            return {
                ...state,
                savedBoundarySearch: {
                    active: action.payload.active,
                    editMode: action.payload.editMode
                }
            };
        }
        case GET_BOUNDARY: {
            return {
                ...state,
                boundary: {
                    id: action.payload.id
                }
            };
        }
        case SET_ALERT_FILTERS: {
            window.localStorage.setItem(
                'alertFilters',
                JSON.stringify(action.payload)
            );
            return {
                ...state,
                alertFilters: action.payload
            };
        }
        case SET_EVENT_FILTERS: {
            window.localStorage.setItem(
                'eventFilters',
                JSON.stringify(action.payload)
            );
            return {
                ...state,
                eventFilters: action.payload
            };
        }
        case SET_DRAFT_AUTHOR_CONTENT_FILTER: {
            return {
                ...state,
                draftAuthorContentFilter: action.payload
            };
        }
        case SET_CATEGORIES_LIST: {
            return {
                ...state,
                categories: action.payload
            };
        }
        case SET_ENABLED_CAPABILITIES_LIST: {
            let enabledCapabilities = _.map(
                // Let's filter out any disabled capabilities
                _.reject(action.payload, (c) => {
                    return !c.enabled;
                }),
                // Then just create an array of enabled capabilities
                (c) => {
                    return c.name;
                }
            );
            return {
                ...state,
                enabledCapabilities: enabledCapabilities
            };
        }
        case SET_CURRENT_VIEW: {
            return {
                ...state,
                currentView: action.payload
            };
        }
        case CLEAR_MESSAGES_LIST: {
            return {
                ...state,
                chatMessages: []
            };
        }
        case SET_MESSAGES_LIST: {
            return {
                ...state,
                chatMessages: _.uniqBy(
                    [...state.chatMessages, ...action.payload],
                    'id'
                )
            };
        }
        case DELETE_CHAT_MESSAGE: {
            return {
                ...state,
                chatMessages: _.filter(
                    state.chatMessages,
                    (m) => m.id !== action.payload.id
                )
            };
        }
        case INCREMENT_REPLY_COUNT: {
            return {
                ...state,
                chatMessages: _.map(state.chatMessages, (chatMessage) => {
                    if (chatMessage.id === action.payload.replyToId) {
                        return {
                            ...chatMessage,
                            numberOfReplies:
                                (chatMessage.numberOfReplies || 0) + 1,
                            lastReply: moment(action.payload.createdAt).unix()
                        };
                    } else {
                        return chatMessage;
                    }
                })
            };
        }
        case DECREMENT_REPLY_COUNT: {
            const { lastReply, replyToId } = action.payload;
            return {
                ...state,
                chatMessages: _.map(state.chatMessages, (chatMessage) => {
                    if (chatMessage.id === replyToId) {
                        return {
                            ...chatMessage,
                            numberOfReplies: chatMessage.numberOfReplies - 1,
                            lastReply: lastReply
                                ? moment(lastReply.createdAt).unix()
                                : null
                        };
                    } else {
                        return chatMessage;
                    }
                })
            };
        }
        case SET_MESSAGE_READ_STATUS: {
            return {
                ...state,
                chatMessages: _.map(state.chatMessages, (chatMessage) => {
                    if (chatMessage.id === action.payload.id) {
                        return {
                            ...chatMessage,
                            readByAnalystName: action.payload.readByAnalystName,
                            updatedAt: action.payload.updatedAt
                        };
                    } else {
                        return chatMessage;
                    }
                })
            };
        }
        case SET_THREAD_READ_STATUS: {
            return {
                ...state,
                chatMessages: _.map(state.chatMessages, (chatMessage) => {
                    if (chatMessage.id === action.payload.replyToId) {
                        return {
                            ...chatMessage,
                            hasUnreadReplies:
                                action.payload.parentUnreadReplyCount > 0
                        };
                    } else {
                        return chatMessage;
                    }
                })
            };
        }
        // Threat Deck Reducers
        case SET_THREAT_DECK_FEED_ITEMS: {
            return {
                ...state,
                threatdeckFeedItems: [
                    ...state.threatdeckFeedItems,
                    ...action.payload
                ]
            };
        }
        case ADD_THREAT_DECK_FEED_ITEM: {
            return {
                ...state,
                threatdeckFeedItems: _.uniqBy(
                    [action.payload, ...state.threatdeckFeedItems],
                    'id'
                )
            };
        }
        case REFRESH_THREAT_DECK_FEED_ITEMS: {
            return {
                ...state,
                threatdeckFeedItems: [...state.threatdeckFeedItems]
            };
        }
        case UPDATE_THREAT_DECK_FEED_ITEM_ANNOTATION: {
            return {
                ...state,
                threatdeckFeedItems: _.map(
                    state.threatdeckFeedItems,
                    (feedItem) => {
                        if (
                            feedItem.id === action.payload.ThreatDeckFeedItemId
                        ) {
                            return {
                                ...feedItem,
                                annotation: action.payload
                            };
                        } else {
                            return feedItem;
                        }
                    }
                )
            };
        }
        case REMOVE_THREAT_DECK_FEED_ITEM: {
            return {
                ...state,
                threatdeckFeedItems: _.reject(
                    state.threatdeckFeedItems,
                    (item) => item.id === action.payload.id
                )
            };
        }

        case SET_TWITTER_RULES: {
            return {
                ...state,
                twitterRules: action.payload
            };
        }
        case EXPIRE_THREAT_DECK_FEED_ITEMS: {
            // We need to make sure that we keep the list of feed items fresh
            // No item should be in the set longer than 30 minutes
            const currentTime = new Date().getTime();

            const unexpiredItems = _.reject(
                state.threatdeckFeedItems,
                (item) => {
                    const itemTime = new Date(item.createdAt).getTime();
                    let needsExpired =
                        currentTime - itemTime > MINUTES_IN_MS(30);
                    return needsExpired;
                }
            );
            if (_.size(unexpiredItems) === _.size(state.threatdeckFeedItems)) {
                // if we do not expire anything, we do not need to trigger a re-render on every column/item
                return state;
            } else {
                return {
                    ...state,
                    threatdeckFeedItems: unexpiredItems
                };
            }
        }
        case SET_AVAILABLE_GROUP_COLUMNS: {
            return {
                ...state,
                availableGroupColumns: action.payload
            };
        }
        case SET_GROUPS: {
            return {
                ...state,
                groups: action.payload
            };
        }
        case GROUP_CREATED: {
            console.log('GROUP_CREATED', action.payload);
            return {
                ...state,
                groups: _([...state.groups, action.payload])
                    .orderBy('name')
                    .value()
            };
        }
        case GROUP_UPDATED: {
            console.log('GROUP_UPDATED', action.payload);
            return {
                ...state,
                groups: _(state.groups)
                    .map((group) => {
                        if (group.id === action.payload.id) {
                            return action.payload;
                        } else {
                            return group;
                        }
                    })
                    .value()
            };
        }
        case GROUP_DELETED: {
            console.log('GROUP_DELETED', action.payload);
            return {
                ...state,
                groups: _.reject(state.groups, (group) => {
                    return group.id === action.payload;
                })
            };
        }
        case SET_USER_GROUPS: {
            const userGroupColumns = _(action.payload)
                .map((group) => {
                    if (group) {
                        const { columns, ...rest } = group;
                        const remappedColumns = _.map(columns, (column) => ({
                            ...column,
                            group: [{ ...rest }]
                        }));
                        return remappedColumns;
                    }
                })
                .flatten()
                .value();

            return {
                ...state,
                userGroups: action.payload,
                userGroupColumns
            };
        }
        case ON_COLUMN_SAVED: {
            /**
             * The action.payload has:
             * - groupId
             * - columnId
             * - threatDeckNamespace
             * if the userGroups has the groupId, then we need to refresh the user's columns
             */
            if (_.find(state.userGroups, { id: action.payload.groupId })) {
                action.payload.threatDeckNamespace.emit('getUserColumns');
            }

            return state;
        }
        case SET_THREAT_DECK_EVENTS: {
            // Events payload is in ascending order
            const threatDeckEventsMap = new Map(
                action.payload.map((event) => [event.id, event])
            );

            return {
                ...state,
                threatDeckEvents: threatDeckEventsMap
            };
        }
        case ARCHIVE_EVENT: {
            const newThreatDeckEventsMap = new Map(state.threatDeckEvents);
            newThreatDeckEventsMap.delete(action.payload.id);
            return {
                ...state,
                threatDeckEvents: newThreatDeckEventsMap
            };
        }
        case UPDATE_EVENT: {
            // state.threatDeckEvents is in ascending order
            // This map will preserve insertion order
            const newThreatDeckEventsMap = new Map(state.threatDeckEvents);

            // if it is updating an existing event the insertion order is preserved
            newThreatDeckEventsMap.set(action.payload.id, action.payload);

            return {
                ...state,
                threatDeckEvents: newThreatDeckEventsMap
            };
        }
        case FILTER_INITIAL_FEED_ITEMS: {
            const removedItems = [];
            const filteredFeedItems = _.filter(
                state.threatdeckFeedItems,
                (item) => {
                    const shouldReturnItem = doesItemMatchAnyColumn(
                        item,
                        action.payload.columns
                    );
                    if (!shouldReturnItem) {
                        removedItems.push({
                            id: item.id
                        });
                    }

                    return shouldReturnItem;
                }
            );
            // TODO: We can probably remove this logging and the removedItems array
            console.log('Filtered Initial Feed Items', {
                preFilteredLength: state.threatdeckFeedItems.length,
                postFilteredLength: filteredFeedItems.length,
                removedItems
            });

            return {
                ...state,
                threatdeckFeedItems: filteredFeedItems
            };
        }
        default:
            return state;
    }
}
