import React from 'react';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../../../config/constants';
import { getNamespace } from '../../../../../redux/selectors';
import ICButton from '../../../../shared/ICButton';

export default function MergeEventButton({ event, setMergeList }) {
    // redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const handleMergeClick = () => {
        threatDeckNamespace.on('threatDeckEventsListCategory', (eventList) => {
            setMergeList(eventList.filter((e) => e.id !== event?.id));
        });
        threatDeckNamespace.emit('getThreatDeckEvents', {
            category: event?.category
        });
    };

    return (
        <ICButton
            onClick={handleMergeClick}
            color="blue"
            disabled={event?.status === 'ARCHIVED'}
            text="Merge"
        />
    );
}
