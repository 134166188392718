import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { TEST_ATTRIBUTES } from '../../config/testConstants';
import { setFilters, toggleModal } from '../../redux/actions';
import {
    getCategoriesList,
    getFilters,
    getModalState
} from '../../redux/selectors';
import ICButton from './ICButton';

export default function FiltersModal({
    modalType, // alert or event
    modalName
}) {
    // redux
    const categoriesList = useSelector(getCategoriesList);
    const filtersModalState = useSelector(getModalState(modalName));
    let filters = useSelector(getFilters(modalType));
    const dispatch = useDispatch();

    function closeModal() {
        dispatch(
            toggleModal({
                data: {},
                modalName,
                value: false
            })
        );
    }

    function toggleFilter(filterType, displayValue, storeValue) {
        let newFilters;
        if (storeValue) {
            newFilters = {
                ...filters,
                [filterType]: {
                    ...filters[filterType],
                    [storeValue]: !get(filters, [filterType, storeValue], true)
                }
            };
        } else {
            newFilters = {
                ...filters,
                [filterType]: {
                    ...filters[filterType],
                    [displayValue]: !get(
                        filters,
                        [filterType, displayValue],
                        true
                    )
                }
            };
        }
        dispatch(setFilters(modalType, newFilters));
    }

    function isEnabled(type, value, storeValue) {
        if (storeValue) {
            return get(filters, [type, storeValue], true);
        } else {
            return get(filters, [type, value], true);
        }
    }

    function displayCheckbox(filterType, displayValue, storeValue) {
        return (
            <div
                className={'Filter-checkbox'}
                key={`${filterType}_filter_${displayValue}`}
            >
                <input
                    id={`${filterType}_filter_${displayValue}`}
                    type="checkbox"
                    defaultChecked={isEnabled(
                        filterType,
                        displayValue,
                        storeValue
                    )}
                    onClick={() => {
                        toggleFilter(filterType, displayValue, storeValue);
                    }}
                    data-TESTID={TEST_ATTRIBUTES.ALERT_FORM.WORLDWIDE}
                />

                <label htmlFor={`${filterType}_filter_${displayValue}`}>
                    {displayValue}
                </label>
            </div>
        );
    }

    function displayCategoryFilters() {
        return (
            <>
                <div className={'Filter-checkbox-grid'}>
                    {categoriesList.map((category, index) =>
                        displayCheckbox('category', category.value)
                    )}
                </div>
            </>
        );
    }

    if (!filtersModalState?.isOpen) {
        return null;
    }
    return (
        <div>
            {`${JSON.parse(window.localStorage.getItem('eventFilters'))}`}
            <Modal
                htmlOpenClassName="FilterModal"
                isOpen={filtersModalState?.isOpen}
                contentLabel={`${capitalize(modalType)} Filters`}
                ariaHideApp={false}
                onAfterOpen={() => {}}
                modalCallback={() => {}}
                onRequestClose={() => {
                    closeModal(modalName);
                }}
            >
                <div className="FilterModal-header">
                    <h2>Filter {capitalize(modalType)} Categories</h2>
                    <button
                        className="ReactModal__CloseButton"
                        onClick={closeModal}
                        data-testid={`${TEST_ATTRIBUTES.MODALS.CLOSE_MODAL(
                            modalName
                        )}`}
                    >
                        <i className="fa fa-window-close" />
                    </button>
                </div>
                <div className="FilterModal-body">
                    {displayCategoryFilters()}
                    <ICButton
                        color={'blue'}
                        className={'FilterSave-button'}
                        data-testid={TEST_ATTRIBUTES.BUTTONS.FILTER_SAVE}
                        onClick={closeModal}
                    >
                        Save and Close
                    </ICButton>
                </div>
            </Modal>
        </div>
    );
}
