import { map } from 'lodash';
import React from 'react';

import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import AlertPanel from '../shared/alerts/AlertPanel';

export default function SituationReportRelatedThreats({
    alerts = [],
    allowEdit = true,
    onRemoveFromSituation,
    openAlertsModal
}) {
    return (
        <div className="ContentForm-area">
            <div className="ContentForm-area-header">
                <h3>Related Threats</h3>
                {allowEdit && (
                    <div className="actions">
                        <button
                            className="anchor-button"
                            onClick={openAlertsModal}
                            data-testid={
                                TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                    .ADD_NEW_RELATED_THREAT
                            }
                        >
                            Add New
                        </button>
                    </div>
                )}
            </div>
            <div className="ContentForm-area-body">
                {alerts.length > 0 ? (
                    map(alerts, (alert) => {
                        return (
                            <AlertPanel
                                key={alert.id}
                                alert={alert}
                                dateField="published_date"
                                type={
                                    allowEdit
                                        ? 'contentManagementRemove'
                                        : 'presentational'
                                }
                                onRemoveFromSituation={onRemoveFromSituation}
                            />
                        );
                    })
                ) : (
                    <p>No threats have been added yet.</p>
                )}
            </div>
        </div>
    );
}
