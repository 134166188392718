import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../../../config/constants';
import { getNamespace } from '../../../../../redux/selectors';
import ICButton from '../../../../shared/ICButton';

export default function ArchiveEventButton({ event, onArchive }) {
    // redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const handleArchiveClick = () => {
        confirmAlert({
            title: 'Confirm Archive',
            message: `Are you sure you want to archive ${event?.title}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        threatDeckNamespace.emit('archiveThreatDeckEvent', {
                            id: event?.id
                        });
                        threatDeckNamespace.emit('getThreatDeckEvents');
                        if (onArchive) {
                            onArchive();
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    };
    return (
        <ICButton
            onClick={handleArchiveClick}
            color="white"
            disabled={event?.status === 'ARCHIVED'}
            text={event?.status === 'ARCHIVED' ? 'Archived' : 'Archive'}
        />
    );
}
