import '../AlertDetails.css';

import { find } from 'lodash';
import moment from 'moment';
import React from 'react';

import { URGENCIES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';

function ReadOnlyAlertDetailsHeader({ mostRecentAlertDetails }) {
    const urgencyLabel = find(
        URGENCIES,
        (urgency) => urgency.value === parseInt(mostRecentAlertDetails.urgency)
    ).label;

    return (
        <>
            {
                <div
                    className="AlertDetails-published"
                    data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.PUBLISHED_DATE}-${mostRecentAlertDetails.id}`}
                >
                    {mostRecentAlertDetails.published_date
                        ? `Published - ${moment(
                              mostRecentAlertDetails.published_date
                          ).format('MMM D, Y h:mm A z')}`
                        : `Last Updated - ${moment(
                              mostRecentAlertDetails.updatedAt
                          ).format('MMM D, Y h:mm A z')}`}
                </div>
            }
            {
                <div className="AlertDetails-tags">
                    <div
                        className="AlertDetails-category"
                        data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.CATEGORY}-${mostRecentAlertDetails.id}`}
                    >
                        {mostRecentAlertDetails.category}
                    </div>
                    {
                        <div
                            className={`AlertDetails-urgency AlertUrgency-${urgencyLabel}`}
                            data-testid={`${TEST_ATTRIBUTES.ALERT_DETAILS.URGENCY}-${mostRecentAlertDetails.id}`}
                        >
                            {urgencyLabel}
                        </div>
                    }
                </div>
            }
        </>
    );
}

export default ReadOnlyAlertDetailsHeader;
