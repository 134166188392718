import './UserDetails.css';

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

import { APPS, NAMESPACES, ROLES } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import {
    getApplication,
    getAuthInfo,
    getNamespace
} from '../../../redux/selectors';
import ICButton from '../ICButton';

const { ANALYST } = ROLES;

export default function UserDetailsModal({ isOpen, onClose }) {
    // redux
    const app = useSelector(getApplication);
    const authInfo = useSelector(getAuthInfo);
    const usersNamespace = useSelector(
        getNamespace(NAMESPACES.USERS_NAMESPACE)
    );

    const isIC = app.name === APPS.IC.name;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');

    const [allowChatNotifications, setAllowChatNotifications] = useState(true);
    const [allowApprovalNotifications, setAllowApprovalNotifications] =
        useState(true);
    const [allowRejectionNotifications, setAllowRejectionNotifications] =
        useState(true);

    useEffect(() => {
        setName(authInfo.user.name);
        setEmail(authInfo.user.email);
        setRole(authInfo.user.role);
        if (authInfo.user.settings) {
            setAllowChatNotifications(
                authInfo.user.settings.allowChatNotifications
            );
            setAllowApprovalNotifications(
                authInfo.user.settings.allowApprovalNotifications
            );
            setAllowRejectionNotifications(
                authInfo.user.settings.allowRejectionNotifications
            );
        }
    }, [authInfo]);

    const updateUserName = (event) => {
        setName(event.target.value);
    };

    const saveUserInfo = () => {
        usersNamespace.emit('updateUserInfo', {
            name,
            email,
            role,
            settings: {
                allowChatNotifications,
                allowApprovalNotifications,
                allowRejectionNotifications
            }
        });
        onClose();
    };

    return (
        <div className="UserDetails-Modal">
            <Modal
                isOpen={isOpen}
                contentLabel="User Details"
                ariaHideApp={false}
            >
                <Container className="UserDetails-Container">
                    <Row className="UserDetails-Row">
                        <Col className="UserDetails-Column">
                            <button
                                className="ReactModal__CloseButton"
                                onClick={onClose}
                                data-testid={`${TEST_ATTRIBUTES.BUTTONS.CLOSE_USER_DETAILS}`}
                            >
                                <i className="fa fa-window-close" />
                            </button>
                        </Col>
                    </Row>
                    <Row className="UserDetails-Row">
                        <Col className="UserDetails-Column">
                            <div className="UserDetails-form-group">
                                <label className="UserDetails-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={updateUserName}
                                    className="UserDetails-form-control"
                                    placeholder="First and Last Name"
                                    autoComplete="off"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="UserDetails-Row">
                        <Col className="UserDetails-Column">
                            <div className="UserDetails-form-group">
                                <label className="UserDetails-label">
                                    Email
                                </label>
                                <input
                                    type="text"
                                    value={email}
                                    readOnly={true}
                                    className="UserDetails-form-control"
                                    autoComplete="off"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="UserDetails-Row">
                        <Col className="UserDetails-Column">
                            <div className="UserDetails-form-group">
                                <label className="UserDetails-label">
                                    Role
                                </label>
                                <input
                                    type="text"
                                    value={role}
                                    readOnly={true}
                                    className="UserDetails-form-control"
                                    autoComplete="off"
                                />
                            </div>
                        </Col>
                    </Row>
                    {
                        // Only show options specific to IC when the app is IC
                        isIC && (
                            <Row className="UserDetails-Row">
                                <Col className="UserDetails-Column">
                                    <div className="UserDetails-form-group">
                                        <label className="UserDetails-label">
                                            Notification Sounds
                                        </label>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        allowChatNotifications
                                                    }
                                                    onChange={(e) => {
                                                        setAllowChatNotifications(
                                                            !allowChatNotifications
                                                        );
                                                    }}
                                                    data-testid={`${TEST_ATTRIBUTES.USER_SETTINGS.TOGGLE_ALLOW_CHAT_NOTIFICATIONS}`}
                                                />
                                                New Chat Message
                                            </label>
                                        </div>
                                        {role !== ANALYST && (
                                            <div>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            allowApprovalNotifications
                                                        }
                                                        onChange={(e) => {
                                                            setAllowApprovalNotifications(
                                                                !allowApprovalNotifications
                                                            );
                                                        }}
                                                        data-testid={`${TEST_ATTRIBUTES.USER_SETTINGS.TOGGLE_ALLOW_APPROVAL_NOTIFICATIONS}`}
                                                    />
                                                    Alert Pending Approval
                                                </label>
                                            </div>
                                        )}
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        allowRejectionNotifications
                                                    }
                                                    onChange={(e) => {
                                                        setAllowRejectionNotifications(
                                                            !allowRejectionNotifications
                                                        );
                                                    }}
                                                    data-testid={`${TEST_ATTRIBUTES.USER_SETTINGS.TOGGLE_ALLOW_REJECTION_NOTIFICATIONS}`}
                                                />
                                                Rejected Pending Alert
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    }
                    <Row className="UserDetails-Row">
                        <Col className="UserDetails-Column UserDetails-button-container">
                            <ICButton
                                onClick={saveUserInfo}
                                color="blue"
                                className="UserDetails-button"
                                data-testid={`${TEST_ATTRIBUTES.BUTTONS.SAVE_USER_DETAILS}`}
                            >
                                Save
                            </ICButton>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </div>
    );
}
