import './SituationReports.css';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../config/constants';
import { getNamespace } from '../../../redux/selectors';
import ReportsLandingPage from '../shared/content-management/ReportsLandingPage';

export default function SituationReports() {
    const situationsNamespace = useSelector(
        getNamespace(NAMESPACES.SITUATIONS_NAMESPACE)
    );
    const [reports, setReports] = useState([]);
    const [sort, setSort] = useState({});
    const [statusFilter, setStatusFilter] = useState();

    useEffect(() => {
        if (situationsNamespace) {
            situationsNamespace.on('situationReportsList', (data) => {
                setReports(data);
            });
            situationsNamespace.on('situationReportDeleted', (id) => {
                setReports((prevReports) => {
                    return _.reject(prevReports, { id });
                });
            });
            situationsNamespace.on('situationReportUpdated', fetchReports);
            situationsNamespace.emit('getSituationReports');
        }

        return () => {
            situationsNamespace.off('situationReportDeleted');
            situationsNamespace.off('situationReportsList');
            situationsNamespace.off('situationReportUpdated');
        };
    }, []);

    // When the sort changes, refetch results
    useEffect(() => {
        if (situationsNamespace) {
            fetchReports();
        }
    }, [statusFilter, sort]);

    function fetchReports() {
        const message = {};
        if (!_.isEmpty(sort)) {
            const sortToSend = {};
            sortToSend.name = Object.keys(sort)[0];
            sortToSend.order = sort[sortToSend.name];
            message.sort = sortToSend;
        }
        if (statusFilter) {
            message.filter = { status: statusFilter };
        }
        situationsNamespace.emit('getSituationReports', message);
    }

    function onSortChanged(fieldToSortBy) {
        // We will follow the traditional sort order found on most sites:
        //    neutral -> desc -> asc -> neutral
        setSort((currentSort = {}) => {
            let newSort = {};
            // If the fieldToSortBy is already in the sort, adjust the order accordingly
            if (fieldToSortBy in currentSort) {
                if (currentSort[fieldToSortBy] === 'DESC') {
                    newSort[fieldToSortBy] = 'ASC';
                } else if (currentSort[fieldToSortBy] === 'ASC') {
                    // Do nothing; removes the sort
                }
            } else {
                newSort[fieldToSortBy] = 'DESC';
            }
            return newSort;
        });
    }

    function onFilterChanged(selectedList, item) {
        setStatusFilter(item.value);
    }

    function archiveReport(reportId) {
        confirmAlert({
            title: 'Confirm Archive',
            message: `Are you sure you want to archive this report?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        situationsNamespace.emit(
                            'deleteSituationReport',
                            reportId
                        );
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    return (
        <ReportsLandingPage
            newButtonText="Add New Situation Report"
            onArchive={archiveReport}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            reports={reports}
            sort={sort}
            title="Situation Reports"
        />
    );
}
