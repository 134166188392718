import { stripTags } from '../utils/utils';

export function tinyMceConfig(placeholderText) {
    return {
        placeholder: placeholderText,
        height: 250,
        menubar: false,
        plugins: [
            'autolink',
            'lists',
            'advlist',
            'searchreplace',
            'table',
            'link',
            'autolink'
        ],
        toolbar:
            'blocks |  bold italic underline | numlist bullist | link paste | hr',
        paste_preprocess: function (plugin, args) {
            // We will do our own pasting processing - we allow only certain HTML tags below
            args.content = stripTags(
                args.content,
                'b',
                'i',
                'u',
                'p',
                'br',
                'a',
                'strong',
                'em'
            );
        },
        extended_valid_elements: 'span',
        link_default_target: '_blank',
        browser_spellcheck: true,
        contextmenu: false
    };
}
