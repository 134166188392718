import './ThreatDeckCard.css';

import React from 'react';
import { useDispatch } from 'react-redux';

import { MODALS } from '../../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';
import { toggleModal } from '../../../../../redux/actions';
import ICButton from '../../../../shared/ICButton';

export default function ThreatDeckCardReview({ item, markAsRead }) {
    // redux
    const dispatch = useDispatch();

    function showReviewModal() {
        dispatch(
            toggleModal({
                data: item,
                modalName: MODALS.FEED_ITEM_REVIEW,
                value: true
            })
        );
        markAsRead();
    }

    return (
        <div id={`td-card-review-${item.id}`} className="ThreatDeckCard-review">
            <ICButton
                color="blue"
                onClick={showReviewModal}
                className="ThreatDeckCard-review-button"
                data-testid={`${
                    TEST_ATTRIBUTES.THREAT_DECK.CARDS.BUTTONS.REVIEW
                }${item?.id ? `-${item.id}` : ''}`}
            >
                Review
            </ICButton>
        </div>
    );
}
