import '../ThreatDeckSources.css';

import React, { useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { NAMESPACES, ROLES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import {
    getAuthInfo,
    getNamespace,
    getTwitterRules
} from '../../../../redux/selectors';
import ICButton from '../../../shared/ICButton';
import ICTable from '../../../shared/ICTable';
import TwitterFiltersForm from './TwitterFiltersForm';

const { ADMINISTRATOR } = ROLES;

export default function TwitterFilters() {
    // REDUX
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );
    const authInfo = useSelector(getAuthInfo);
    const twitterRules = useSelector(getTwitterRules);

    // STATE
    const [createTwitterRuleActive, setCreateTwitterRuleActive] =
        useState(false);
    const [twitterRuleToEdit, setTwitterRuleToEdit] = useState(null);

    function editTwitterRule(item) {
        if (authInfo.role === ADMINISTRATOR) {
            setTwitterRuleToEdit(item);
            setCreateTwitterRuleActive(!createTwitterRuleActive);
        }
    }

    function deleteTwitterRule(item) {
        if (authInfo.role === ADMINISTRATOR) {
            threatDeckNamespace.emit('deleteTwitterRule', {
                id: item.id
            });
            threatDeckNamespace.emit('getTwitterRules');
        }
    }

    function toggleTwitterRuleActive(item) {
        if (authInfo.role === ADMINISTRATOR) {
            threatDeckNamespace.emit('setTwitterRuleActive', {
                id: item.id,
                active: !item.active
            });
        }
    }

    const twitterRuleColumns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'tag',
                customColumnRender: {
                    render: ({ item }) => {
                        return (
                            <span>
                                {item.tag}
                                {authInfo.role === ADMINISTRATOR && (
                                    <ICButton
                                        key={`twitter_rule_delete_${item.id}`}
                                        color="red"
                                        className="ThreatDeckSource-delete-button"
                                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.DELETE_RULE}-${item.id}`}
                                        onClick={() => {
                                            confirmAlert({
                                                title: 'Confirm Delete',
                                                message: `Are you sure you want to delete this rule?`,
                                                buttons: [
                                                    {
                                                        label: 'Yes',
                                                        onClick: () =>
                                                            deleteTwitterRule(
                                                                item
                                                            )
                                                    },
                                                    {
                                                        label: 'No',
                                                        onClick: () => {}
                                                    }
                                                ]
                                            });
                                        }}
                                    >
                                        Delete
                                    </ICButton>
                                )}
                                {authInfo.role === ADMINISTRATOR && (
                                    <ICButton
                                        key={`twitter_rule_edit_${item.id}`}
                                        color="blue"
                                        className="ThreatDeckSource-edit-button"
                                        data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.EDIT_RULE}-${item.id}`}
                                        onClick={() => {
                                            editTwitterRule(item);
                                        }}
                                    >
                                        Edit
                                    </ICButton>
                                )}
                            </span>
                        );
                    },
                    args: { item: 'row.original' }
                }
            },
            {
                Header: 'Rules',
                accessor: 'rule',
                customColumnRender: {
                    render: ({ rule }) => {
                        return (
                            <div className="ThreatDeckSource-twitter-rule">
                                {rule}
                            </div>
                        );
                    },
                    args: { rule: 'value' }
                }
            },
            {
                Header: 'Status',
                accessor: 'active',
                Filter: false,
                customColumnRender: {
                    render: ({ item }) => {
                        let text = item.active ? 'Active' : 'Not Active';
                        let color = item.active ? 'green' : 'red';
                        return (
                            <ICButton
                                className="ThreatDeckSource-status-button"
                                key={`twitter_rule_${item.id}_status`}
                                data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.STATUS_RULE}-${item.id}`}
                                disabled={authInfo.role !== ADMINISTRATOR}
                                color={color}
                                onClick={() => {
                                    toggleTwitterRuleActive(item);
                                }}
                            >
                                {text}
                            </ICButton>
                        );
                    },
                    args: { item: 'row.original' }
                }
            }
        ];
    }, []);

    return (
        <Container className="ConfigureSystem-Container">
            {!createTwitterRuleActive && (
                <span>
                    <Row>
                        <Col>
                            <h1>Twitter Filter Rules</h1>
                        </Col>
                        {authInfo.role === ADMINISTRATOR && (
                            <Col>
                                <ICButton
                                    color="green"
                                    className="ThreatDeckSource-create-button"
                                    data-testid={`${TEST_ATTRIBUTES.TWITTER_SETTINGS.BUTTONS.CREATE_RULE}`}
                                    onClick={() => {
                                        setCreateTwitterRuleActive(
                                            !createTwitterRuleActive
                                        );
                                    }}
                                >
                                    Create Filter Rule
                                </ICButton>
                            </Col>
                        )}
                    </Row>
                    <ICTable
                        columns={twitterRuleColumns}
                        data={twitterRules || []}
                        reference={'TwitterFilterRules'}
                    />
                </span>
            )}
            {createTwitterRuleActive && (
                <TwitterFiltersForm
                    setCreateTwitterRuleActive={setCreateTwitterRuleActive}
                    twitterRuleToEdit={twitterRuleToEdit}
                    setTwitterRuleToEdit={setTwitterRuleToEdit}
                />
            )}
        </Container>
    );
}
