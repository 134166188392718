import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';

import { NAMESPACES } from '../../../../../config/constants';
import { getNamespace } from '../../../../../redux/selectors';
import ICButton from '../../../../shared/ICButton';

export default function CreateEventThreatButton({ event }) {
    // redux
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const handleCreateNewThreatClick = () => {
        confirmAlert({
            title: 'Confirm New Threat',
            message: `Are you sure you want to create a new threat from this event?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        threatDeckNamespace.emit('createAlertFromEvent', {
                            id: event?.id
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    };

    return (
        <ICButton
            onClick={handleCreateNewThreatClick}
            color="green"
            disabled={event?.AlertId || event?.status === 'ARCHIVED'}
            text={event?.AlertId ? 'Threat Created' : 'Create Threat'}
        />
    );
}
