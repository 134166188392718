import './ICButton.css';

import React from 'react';

/**
 * A base button that provides consistent styling throughout the app
 *
 *  * ------------------------------------------------------------------------ *
 *  *  A note about overriding styles: because of the cascading nature of CSS,
 *  *  applying additional classNames may not be enough to override the de-
 *  *  fault styling. Two ways I've found around this:
 *  *
 *  *     - Add a className to a parent component and use both classes to give
 *  *        your CSS more weight
 *  *     - Add the styles for your extra class to a CSS file that gets directly
 *  *        imported into your component. This way, it will get applied last
 *  *        and override the button's default style.
 *
 * @param {HTML} children text or html you want rendered inside the button
 * @param {String} className you can add more classNames if you need to tweak
 *                            the default styling. As usual, pass them as a string
 *                            separated by spaces, just like in native HTML.
 * @param {String} color choices are: green, dark-green, red, dark-red, yellow,
 *                        blue, grey, black. See styling/ICButton.css for all the
 *                        available color-related classes.
 * @param {String} data-testid the cypress attribute you want applied to this button.
 *                          Pass as a hyphenated attribute, just like you would
 *                          when applying to a normal HTML component.
 * @param {Boolean} disabled make the button disabled
 * @param {Function} onClick callback for when the button is clicked
 * @param {String} text alternate to children if you just want to pass in text
 *                       for the button
 */
export default function ICButton({
    children,
    className,
    color,
    'data-testid': dataTestId,
    disabled = false,
    onClick,
    text
}) {
    let finalClassName = 'BaseICButton';

    if (color) {
        finalClassName += ` ${color}-button`;
    }

    if (className) {
        finalClassName += ` ${className}`;
    }

    return (
        <button
            className={finalClassName}
            data-testid={dataTestId}
            disabled={disabled}
            onClick={onClick}
        >
            {text || children}
        </button>
    );
}
