import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React from 'react';
import { Link } from 'react-router-dom';

import {
    CONTENT_MANAGEMENT_STATUS_OPTIONS,
    MULTISELECT_COMPONENT_STYLES
} from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import ICButton from '../../../shared/ICButton';
import ReportsTable from './ReportsTable';

const { REPORTS_LANDING_PAGE } = TEST_ATTRIBUTES.CONTENT_MANAGEMENT;

// We need to override the margin so the dropdown will align with the "create" button
const LANDING_PAGE_MULTISELECT_STYLES = _.cloneDeep(
    MULTISELECT_COMPONENT_STYLES
);
LANDING_PAGE_MULTISELECT_STYLES.searchBox.margin = 0;

export default function ReportsLandingPage({
    newButtonText,
    onArchive,
    onFilterChanged,
    onSortChanged,
    reports,
    sort,
    title
}) {
    return (
        <div className="ContentManagement">
            <div className="ContentManagement-header">
                <h2 className="ContentManagement-title">{title}</h2>
                <div className="ContentManagement-buttons">
                    <Multiselect
                        className="ContentManagement-status-dropdown"
                        id="updateTypeMultiselect"
                        selectionLimit={1}
                        options={CONTENT_MANAGEMENT_STATUS_OPTIONS[title]}
                        onSelect={onFilterChanged}
                        displayValue="label"
                        singleSelect={true}
                        placeholder="Status"
                        style={LANDING_PAGE_MULTISELECT_STYLES}
                        selectedValues={[]}
                        data-testid={`${REPORTS_LANDING_PAGE.STATUS_FILTER_DROPDOWN}`}
                    />
                    <Link to="edit">
                        <ICButton
                            color="green"
                            className="ContentManagement-new-content-button"
                            data-testid={`${REPORTS_LANDING_PAGE.CREATE_NEW_BUTTON}`}
                        >
                            {newButtonText}
                        </ICButton>
                    </Link>
                </div>
            </div>
            <div className="ContentManagement-table">
                <ReportsTable
                    reports={reports}
                    onArchive={onArchive}
                    onSortChanged={onSortChanged}
                    sort={sort}
                />
            </div>
        </div>
    );
}
