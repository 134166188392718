// Authorization Action Types
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_COGNITO_SESSION = 'SET_COGNITO_SESSION';
export const SET_USER = 'SET_USER';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_REGIONS = 'SET_USER_REGIONS';
export const SET_USER_SESSION = 'SET_USER_SESSION';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
// Socket Action Types
export const SOCKET_MANAGER = 'SOCKET_MANAGER';
export const SOCKET_NAMESPACE = 'SOCKET_NAMESPACE';
export const SOCKETS_CONNECTED = 'SOCKETS_CONNECTED';
export const SOCKETS_DISCONNECTED = 'SOCKETS_DISCONNECTED';
export const SOCKETS_RECONNECTING = 'SOCKETS_RECONNECTING';
export const SOCKETS_RECONNECTED = 'SOCKETS_RECONNECTED';
// Application Action Types
export const SET_APP = 'SET_APP';
export const SET_APP_USERS = 'SET_APP_USERS';
export const SET_ALERT_MODAL = 'SET_ALERT_MODAL';
export const SET_FILTERS = (modalType) => {
    return `SET_${modalType.toUpperCase()}_FILTERS`;
};
export const SET_ALERT_FILTERS = 'SET_ALERT_FILTERS';
export const SET_EVENT_FILTERS = 'SET_EVENT_FILTERS';
export const SET_APP_REGIONS = 'SET_APP_REGIONS';
export const SET_SAVED_BOUNDARY_SEARCH = 'SET_SAVED_BOUNDARY_SEARCH';
export const GET_BOUNDARY = 'GET_BOUNDARY';
export const SET_HIDE_THIRD_PARTY_CONTENT = 'SET_HIDE_THIRD_PARTY_CONTENT';
export const SET_DRAFT_AUTHOR_CONTENT_FILTER =
    'SET_DRAFT_AUTHOR_CONTENT_FILTER';
export const SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST';
export const SET_ENABLED_CAPABILITIES_LIST = 'SET_ENABLED_CAPABILITIES_LIST';
export const SET_THREAD_READ_STATUS = 'SET_THREAD_READ_STATUS';
export const SET_CAPABILITIES_LIST = 'SET_CAPABILITIES_LIST';
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const SET_MESSAGES_LIST = 'SET_MESSAGES_LIST';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const CLEAR_MESSAGES_LIST = 'CLEAR_MESSAGES_LIST';
export const DECREMENT_REPLY_COUNT = 'DECREMENT_REPLY_COUNT';
export const DELETE_CHAT_MESSAGE = 'DELETE_CHAT_MESSAGE';
export const INCREMENT_REPLY_COUNT = 'INCREMENT_REPLY_COUNT';
export const SET_MESSAGE_READ_STATUS = 'SET_MESSAGE_READ_STATUS';
// Threat Deck Action Types
export const ARCHIVE_EVENT = 'ARCHIVE_EVENT';
export const ADD_THREAT_DECK_FEED_ITEM = 'ADD_THREAT_DECK_FEED_ITEM';
export const GROUP_CREATED = 'GROUP_CREATED';
export const GROUP_DELETED = 'GROUP_DELETED';
export const GROUP_UPDATED = 'GROUP_UPDATED';
export const FILTER_INITIAL_FEED_ITEMS = 'FILTER_INITIAL_FEED_ITEMS';
export const ON_COLUMN_SAVED = 'ON_COLUMN_SAVED';
export const REFRESH_THREAT_DECK_FEED_ITEMS = 'REFRESH_THREAT_DECK_FEED_ITEMS';
export const REMOVE_THREAT_DECK_FEED_ITEM = 'REMOVE_THREAT_DECK_FEED_ITEM';
export const SET_AVAILABLE_GROUP_COLUMNS = 'SET_AVAILABLE_GROUP_COLUMNS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_THREAT_DECK_FEED_ITEMS = 'SET_THREAT_DECK_FEED_ITEMS';
export const SET_TWITTER_RULES = 'SET_TWITTER_RULES';
export const UPDATE_THREAT_DECK_FEED_ITEM_ANNOTATION =
    'UPDATE_THREAT_DECK_FEED_ITEM_ANNOTATION';
export const EXPIRE_THREAT_DECK_FEED_ITEMS = 'EXPIRE_THREAT_DECK_FEED_ITEMS';
export const SET_THREAT_DECK_EVENTS = 'SET_THREAT_DECK_EVENTS';
export const UPDATE_EVENT = 'UPDATE_EVENT';
