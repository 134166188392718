import { map } from 'lodash';
import React from 'react';

import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import ICPencilIcon from '../../../shared/ICPencilIcon';
import ICTrashIcon from '../../../shared/ICTrashIcon';

export default function Sources({
    allowEdit = true,
    handleDelete,
    openSourceModal,
    sources
}) {
    function renderSources() {
        return (
            <ul className="ContentForm-sources-list">
                {map(sources, (source) => {
                    return (
                        <li
                            key={source.id}
                            className="ContentForm-source-container"
                        >
                            <a
                                href={source.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {source.title}
                            </a>
                            {allowEdit && (
                                <span className="icons-container">
                                    <ICTrashIcon
                                        onClick={() => handleDelete(source)}
                                        data-testid={`${TEST_ATTRIBUTES.CONTENT_MANAGEMENT.SOURCE_OPTIONS.DELETE_SOURCE}-${source.id}`}
                                    />
                                    <ICPencilIcon
                                        onClick={() => openSourceModal(source)}
                                        data-testid={`${TEST_ATTRIBUTES.CONTENT_MANAGEMENT.SOURCE_OPTIONS.EDIT_SOURCE}-${source.id}`}
                                    />
                                </span>
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <div className="ContentForm-area">
            <div className="ContentForm-area-header">
                <h3>Sources</h3>
                {allowEdit && (
                    <div className="actions">
                        <button
                            className="anchor-button"
                            onClick={openSourceModal}
                            data-testid={
                                TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                    .ADD_NEW_SOURCE
                            }
                        >
                            Add New
                        </button>
                    </div>
                )}
            </div>
            <div className="ContentForm-area-body">
                {sources.length === 0 ? (
                    <p>No sources have been added yet.</p>
                ) : (
                    renderSources()
                )}
            </div>
        </div>
    );
}
