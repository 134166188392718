import includes from 'lodash/includes';
import kebabCase from 'lodash/kebabCase';
import size from 'lodash/size';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';

import { MODALS } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { toggleModal } from '../../../../redux/actions';

export default function EventCard({ event, style = {}, view = 'MAP' }) {
    // redux
    const dispatch = useDispatch();

    function openDetailsModal(event) {
        dispatch(
            toggleModal({
                data: event,
                modalName: MODALS.EVENT_DETAILS,
                value: true
            })
        );
    }

    // TODO: use constants  and settle on the actual date we want to use
    // do we want the event status to even display in the column view?
    const displayEventStatus = () => {
        let statusClass = 'ThreatDeckEvent-published';
        let statusDate = event?.startDate * 1000; // TODO: Why start date? and why is it in just seconds
        let statusLabel = 'Published ';
        if (event?.status === 'ARCHIVED') {
            statusClass = 'ThreatDeckEvent-archived';
            statusDate = event?.archivedDate;
            statusLabel = 'Archived ';
        }
        return (
            <div className={`ThreatDeckEvent-status ${statusClass}`}>
                {statusLabel}
                {moment(statusDate).format('MMM D, Y h:mm A z')}
            </div>
        );
    };

    return (
        <div
            className="ThreatDeckEvent-summary"
            data-testid={TEST_ATTRIBUTES.THREAT_DECK.EVENTS.EVENT.TD_EVENT}
            onClick={() => openDetailsModal(event)}
            style={style}
        >
            <div
                className={`ThreatDeckCard-category ${kebabCase(
                    event.category
                )}`}
                data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.EVENTS.EVENT.CATEGORY}-${event.id}`}
            >
                {event.category}
            </div>
            <div className={`ThreatDeckEvent-title ${view.toLowerCase()}-view`}>
                {event.title}
            </div>
            {includes(['COLUMN'], view) && displayEventStatus()}
            <div
                className="ThreatDeckCard-feedCount"
                data-testid={
                    TEST_ATTRIBUTES.THREAT_DECK.EVENTS.EVENT.FEED_ITEMS_COUNT
                }
            >
                {size(event.feedItems) > 1 || size(event.feedItems) === 0
                    ? `${size(event.feedItems)} Feed Items`
                    : `${size(event.feedItems)} Feed Item`}
            </div>
        </div>
    );
}
