import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { CONTENT_MANAGEMENT, ROLES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { getAuthInfo } from '../../../../redux/selectors';
import ICButton from '../../../shared/ICButton';

const { STATUS_ACTIVE, STATUS_PENDING } = CONTENT_MANAGEMENT;
const { ADMINISTRATOR, LEAD_ANALYST } = ROLES;

export default function ActionForm({
    onDiscard,
    onMarkInactive,
    onPublish,
    onSave,
    onSendForApproval,
    status,
    disablePublish,
    bumpDate
}) {
    const authInfo = useSelector(getAuthInfo);

    function publish() {
        onPublish();
    }

    // function reject() {
    //     console.log('reject');
    // }

    function renderReviewButtons() {
        if (
            _.includes([ADMINISTRATOR, LEAD_ANALYST], authInfo.role) &&
            status === STATUS_PENDING
        ) {
            return (
                <div className="ContentForm-area">
                    <div className="ContentForm-area-header">
                        <h3>Review Actions</h3>
                    </div>

                    <div className="ContentForm-area-body ContentActionForm">
                        <ICButton
                            onClick={publish}
                            color="green"
                            data-testid={
                                TEST_ATTRIBUTES.CONTENT_MANAGEMENT.PUBLISH
                            }
                            disabled={disablePublish}
                        >
                            Publish
                        </ICButton>
                        {/* <ICButton
                            onClick={reject}
                            color="dark-red"
                            data-testid={TEST_ATTRIBUTES.CONTENT_MANAGEMENT.REJECT}
                        >
                            Reject
                        </ICButton> */}
                    </div>
                </div>
            );
        }
    }

    function renderInactiveButton() {
        if (
            _.includes([ADMINISTRATOR, LEAD_ANALYST], authInfo.role) &&
            status === STATUS_ACTIVE &&
            _.isFunction(onMarkInactive)
        ) {
            return (
                <ICButton
                    onClick={onMarkInactive}
                    color="grey"
                    data-testid={
                        TEST_ATTRIBUTES.CONTENT_MANAGEMENT.MARK_INACTIVE
                    }
                >
                    Mark Inactive
                </ICButton>
            );
        }
    }

    return (
        <div>
            {renderReviewButtons()}
            <div className="ContentForm-area">
                <div className="ContentForm-area-header">
                    <h3>Edit Actions</h3>
                </div>

                <div className="ContentForm-area-body ContentActionForm">
                    {bumpDate &&
                        status === STATUS_ACTIVE &&
                        _.includes(
                            [ADMINISTRATOR, LEAD_ANALYST],
                            authInfo.role
                        ) && (
                            <ICButton
                                onClick={bumpDate}
                                color="green"
                                data-testid={
                                    TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                        .UPDATE_DATE
                                }
                            >
                                Update Date
                            </ICButton>
                        )}
                    <ICButton
                        onClick={onSendForApproval}
                        color="blue"
                        data-testid={
                            TEST_ATTRIBUTES.CONTENT_MANAGEMENT.SEND_FOR_APPROVAL
                        }
                    >
                        Send for Approval
                    </ICButton>
                    <ICButton
                        onClick={onSave}
                        color="yellow"
                        data-testid={
                            TEST_ATTRIBUTES.CONTENT_MANAGEMENT.SAVE_DRAFT
                        }
                    >
                        Save Draft
                    </ICButton>
                    <ICButton
                        onClick={onDiscard}
                        color="red"
                        data-testid={TEST_ATTRIBUTES.CONTENT_MANAGEMENT.DISCARD}
                    >
                        Discard Changes
                    </ICButton>
                    {renderInactiveButton()}
                </div>
            </div>
        </div>
    );
}
