import React, { useState } from 'react';

import AlertMap from '../../shared/map/AlertMap';

export default function TravelBriefMap({ boundary, updateBoundary }) {
    // since we are not doing anything with the overlays, especially insofar as editing them
    // or extracting locations, this component may end up being quite simple
    // but should any complexity arise regarding the map, we probably want to handle it here
    // if possible
    const [overlays, setOverlays] = useState(undefined);

    function onSaveOverlays(overlays) {
        setOverlays(overlays);
    }

    return (
        <AlertMap
            boundary={boundary}
            editMode={false}
            onSaveOverlays={onSaveOverlays}
            skipCircles={true}
            skipPolygons={true}
            skipSavedBoundaries={false}
            createMode={false}
            loadSearchBox={true}
            updateBoundary={updateBoundary}
        />
    );
}
