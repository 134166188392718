import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';

import { ROLES } from '../../../../../config/constants';
import { getAuthInfo } from '../../../../../redux/selectors';
import ArchiveEventButton from '../buttons/ArchiveEventButton';
import CreateEventThreatButton from '../buttons/CreateEventThreatButton';
import MergeEventButton from '../buttons/MergeEventButton';
import UpdateSummaryButton from '../buttons/UpdateSummaryButton';

const { ADMINISTRATOR, LEAD_ANALYST } = ROLES;

export default function EventDetailsHeader({
    event,
    isModalView = false,
    setMergeList,
    closeModal
}) {
    const authInfo = useSelector(getAuthInfo);

    const displayEventStatus = () => {
        let statusClass = 'ThreatDeckEventDetails-published';
        let statusDate = event?.startDate * 1000; // TODO: Why start date? and why is it in just seconds
        let statusLabel = 'Published ';
        if (event?.status === 'ARCHIVED') {
            statusClass = 'ThreatDeckEventDetails-archived';
            statusDate = event?.archivedDate;
            statusLabel = 'Archived ';
        }
        return (
            <div className={`ThreatDeckEventDetails-status ${statusClass}`}>
                {statusLabel}
                {moment(statusDate).format('MMM D, Y h:mm A z')}
            </div>
        );
    };

    const popOutEvent = () => {
        window.open(`/events/${event?.id}`, '_blank');
    };

    return (
        <div>
            {isModalView && closeModal && (
                <>
                    <button
                        className="ReactModal__CloseButton"
                        onClick={() => closeModal()}
                    >
                        <i className="fa fa-window-close" />
                    </button>
                    <button
                        className="ReactModal__PopOutButton"
                        onClick={popOutEvent}
                    >
                        <i className="fa fa-share-square" />
                    </button>
                </>
            )}
            <div>
                {displayEventStatus()}
                <div className="ThreatDeckEventDetails-action-buttons">
                    {(authInfo.role === LEAD_ANALYST ||
                        authInfo.role === ADMINISTRATOR) && (
                        <UpdateSummaryButton event={event} />
                    )}
                    <ArchiveEventButton event={event} onArchive={closeModal} />
                    <MergeEventButton
                        event={event}
                        setMergeList={setMergeList}
                    />
                    <CreateEventThreatButton event={event} />
                </div>
            </div>
        </div>
    );
}
