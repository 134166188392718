import jwt_decode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRuntimeValue, loadRuntimeValues } from '../config/runtimeConfig';
import {
    logoutUser,
    setCognitoSession,
    setUserSession
} from '../redux/actions';
import {
    checkSocketConnections,
    getCognitoSession,
    getUserSession
} from '../redux/selectors';
import {
    getCurrentSession,
    refreshCurrentSession
} from '../utils/awsAmplifyUtils';

async function isTestMode() {
    // TODO: This is temporary to get by connectivity issues with
    //  pipeline cypress to Cognito
    const appEnv = await getRuntimeValue('APP_ENV');
    return appEnv === 'cypress';
}

// Everything to manage a user's cognito session. This gets used to ensure socket connections are maintained.
function useCognitoSession({ dependencies = [] }) {
    // redux
    const dispatch = useDispatch();
    const cognitoSession = useSelector(getCognitoSession);
    const userSession = useSelector(getUserSession);
    const areSocketsConnected = useSelector(checkSocketConnections);

    // state
    const [isCheckingSession, setIsCheckingSession] = useState(true);
    const sessionToken = useRef(null);
    const sessionEmail = useRef(null);
    const sessionGroup = useRef(null);

    useEffect(() => {
        async function manageSession() {
            await loadRuntimeValues();

            if (
                (await isTestMode()) &&
                window.localStorage.getItem('sessionEmail')
            ) {
                if (!userSession && !cognitoSession && isCheckingSession) {
                    sessionEmail.current =
                        window.localStorage.getItem('sessionEmail');
                    setIsCheckingSession(false);
                    dispatch(setCognitoSession({ test: true }));
                    dispatch(
                        setUserSession({ sessionEmail: sessionEmail.current })
                    );
                }
                return;
            }

            let session;
            // if we haven't checked the user's session yet, get it
            if (!cognitoSession) {
                console.log(`Attempting to get current session`);
                try {
                    const currentSession = await getCurrentSession();
                    // if there is no cognito session, the user must login
                    if (!currentSession) {
                        console.log(
                            `No current session found... user must login`
                        );

                        // set that we are no longer checking the session
                        // and logout the user
                        setIsCheckingSession(false);
                        dispatch(logoutUser());
                        return;
                    }

                    // set out cognitoSession, this is a dependency and will trigger this effect again
                    dispatch(setCognitoSession(currentSession));
                    console.log('Setting session in store...');
                    return;
                } catch (error) {
                    console.log(
                        `Error getting current session: ${error.message}`
                    );
                }
            }

            let tokenDecoded, currentTokenValid;
            // we should have a cognito session at this point
            // let's make sure it is still valid
            if (cognitoSession) {
                try {
                    tokenDecoded = await jwt_decode(
                        cognitoSession.idToken.jwtToken
                    );
                    // @ts-ignore
                    currentTokenValid = tokenDecoded.exp > moment.utc().unix();
                } catch (error) {
                    console.log('Failed to decode token: ', error.message);
                }
            }

            // if it is valid, we can continue with the current session
            if (currentTokenValid) {
                console.log(`Current token is valid... `);
                sessionToken.current = cognitoSession.idToken.jwtToken;
                sessionEmail.current = cognitoSession.idToken.payload.email;
                sessionGroup.current =
                    cognitoSession.idToken.payload['cognito:groups'][0];

                session = {
                    sessionEmail: sessionEmail.current,
                    group: sessionGroup.current,
                    token: sessionToken.current
                };
            } else {
                console.log(`Current token is expired... `, {
                    cognitoSession
                });
                try {
                    // if the token is expired, we should refresh the session if we can
                    console.log('Attempting token refresh...');
                    const refreshedSession = await refreshCurrentSession();
                    if (!refreshedSession) {
                        console.log(
                            `Unable to refresh session... user must login`,
                            {
                                refreshedSession
                            }
                        );

                        // track that we are no logner checking the session
                        // and logout the user
                        setIsCheckingSession(false);
                        dispatch(logoutUser());
                        return;
                    }

                    // I'm not sure that setting these refs is necessary since we will rerun this effect with the refreshed session
                    sessionToken.current = refreshedSession.idToken.jwtToken;
                    sessionEmail.current =
                        refreshedSession.idToken.payload.email;
                    sessionGroup.current =
                        refreshedSession.idToken.payload['cognito:groups'][0];

                    // set the cognitoSession
                    console.log('Session refreshed...', { refreshedSession });
                    dispatch(setCognitoSession(refreshedSession));
                    return;
                } catch (error) {
                    console.log(`Error refreshing session: ${error.message}`);
                }
            }

            // if we have made it to this point, The user has either:
            // started a session through login
            // used an existing session
            // refreshed an expired session
            // all successfully
            setIsCheckingSession(false);
            console.log(`Current session: `, { userSession: session });
            dispatch(setUserSession(session));
        }

        if (
            !areSocketsConnected ||
            isEmpty(userSession) ||
            isEmpty(cognitoSession)
        ) {
            manageSession();
        }
    }, [...dependencies, areSocketsConnected, cognitoSession, userSession]);

    return { isCheckingSession };
}

export { useCognitoSession };
