import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { FaChevronLeft } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import validate from 'validate.js';

import {
    CONTENT_MANAGEMENT,
    NAMESPACES,
    TRAVEL_BRIEFS
} from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { getNamespace } from '../../../../redux/selectors';
import { auditsFailedModal } from '../../../../utils/utils';
import PageNotFound from '../../../shared/error-pages/PageNotFound';
import AnalystNotes from '../../shared/alerts/AnalystNotes';
import ActionForm from '../../shared/content-management/ActionForm';
import Sources from '../../shared/content-management/Sources';
import StatusBadge from '../../shared/content-management/StatusBadge';
import SectionsTable from '../SectionsTable';
import SectionForm from './SectionForm';
import SourceForm from './SourceForm';
import TravelBriefMap from './TravelBriefMap';

const { STATUS_DRAFT, STATUS_INACTIVE, STATUS_PENDING } = CONTENT_MANAGEMENT;
const { SECTION_TYPES } = TRAVEL_BRIEFS;

export default function TravelBriefForm() {
    const briefsNamespace = useSelector(
        getNamespace(NAMESPACES.BRIEFS_NAMESPACE)
    );
    const [brief, setBrief] = useState({});
    const [title, setTitle] = useState('');
    const [boundary, setBoundary] = useState();
    const [notes, setNotes] = useState('');
    const [displayTitle, setDisplayTitle] = useState('');
    const [sections, setSections] = useState([]);
    const [sources, setSources] = useState([]);
    const [sourceToEdit, setSourceToEdit] = useState();
    const [showSourceModal, setShowSourceModal] = useState(false);
    const [sectionToEdit, setSectionToEdit] = useState();
    const [showSectionModal, setShowSectionModal] = useState(false);
    const [status, setStatus] = useState();
    const [doesNotExist, setDoesNotExist] = useState(false);
    const [loading, setLoading] = useState(true);
    // existing note is split from the brief to avoid losing changes when notes are saved
    const [existingNote, setExistingNote] = useState();

    // React-Router
    const { travelBriefId } = useParams();
    const navigate = useNavigate();

    /* ---------------------- */
    /* Hooks */
    /* ---------------------- */
    // Add namespace listeners
    useEffect(() => {
        if (briefsNamespace) {
            briefsNamespace.on('saveBriefSucceeded', _navigateToListView);
            briefsNamespace.on('saveBriefFailed', (message) => {
                confirmAlert({
                    title: 'Save Failed',
                    message: message
                        ? message
                        : 'There was an error saving the Travel Brief.',
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            });
            briefsNamespace.on('sectionSavedSuccessfully', (updatedSection) => {
                // We need to modify the section in place to preserve order
                setSections((currSections) => {
                    const ix = _.findIndex(
                        currSections,
                        (section) => section.id === updatedSection.id
                    );
                    currSections[ix] = updatedSection;
                    return currSections;
                });
                // Close modal
                closeSectionModal();
            });
            briefsNamespace.on('briefStatusChanged', (status) =>
                setStatus(status)
            );
            briefsNamespace.on('briefSourceSavedSuccessfully', (source) => {
                // Handle
                setSources((currVal) => {
                    return _([source, ...currVal])
                        .uniqBy('id')
                        .orderBy('createdAt', 'desc')
                        .value();
                });
                setShowSourceModal(false);
            });
            briefsNamespace.on('briefSourceSaveFailed', (message) => {
                confirmAlert({
                    title: 'Source Save Failed',
                    message: message
                        ? message
                        : 'There was an error saving the source.',
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            });
            briefsNamespace.on('briefSourceDeletedSuccessfully', (id) => {
                setSources((currVal) => {
                    return _(currVal)
                        .reject({ id })
                        .orderBy('createdAt', 'desc')
                        .value();
                });
            });
        }
        return () => {
            briefsNamespace.off('briefStatusChanged');
            briefsNamespace.off('saveBriefSucceeded');
            briefsNamespace.off('saveBriefFailed');
            briefsNamespace.off('sectionSavedSuccessfully');
            briefsNamespace.off('briefSourceDeletedSuccessfully');
            briefsNamespace.off('briefSourceDeleteFailed');
            briefsNamespace.off('briefSourceSavedSuccessfully');
            briefsNamespace.off('briefSourceSaveFailed');
        };
    }, []);

    // When we get the travelBriefId from the URL, load data; otherwise, create default sections
    useEffect(() => {
        if (!travelBriefId) {
            setSections(
                SECTION_TYPES.map((type) => {
                    return {
                        defaultTitle: type
                    };
                })
            );
            setLoading(false);
        } else {
            briefsNamespace.once(
                `travelBriefDetails_${travelBriefId}`,
                (travelBrief) => {
                    setLoading(false);
                    if (!travelBrief) {
                        // We must set doesNotExist first, otherwise
                        setDoesNotExist(true);
                        return;
                    }
                    setTitle(travelBrief.title);
                    setDisplayTitle(travelBrief.title);
                    setStatus(travelBrief.status);
                    setNotes(travelBrief.analystNotes);
                    setExistingNote(travelBrief.analystNotes);
                    setBoundary(travelBrief.boundary);
                    setBrief(travelBrief);
                }
            );
            briefsNamespace.once(
                `travelBriefSections_${travelBriefId}`,
                (sections) => {
                    if (_.isEmpty(sections)) return;
                    // We need to order the sections by the order in SECTION_TYPES
                    // but we aren't guaranteeing that the order in which they are
                    // created will always be consistent with this.
                    const sectionsInOrder = _.map(
                        SECTION_TYPES,
                        (sectionTypeTitle) =>
                            _.find(sections, { defaultTitle: sectionTypeTitle })
                    );
                    setSections(sectionsInOrder);
                }
            );
            briefsNamespace.once(
                `travelBriefSources_${travelBriefId}`,
                (sources) => {
                    setSources(sources);
                }
            );

            briefsNamespace.emit('getSectionsByBriefId', travelBriefId);
            briefsNamespace.emit('getTravelBriefById', travelBriefId);
            briefsNamespace.emit('getSourcesByBriefId', travelBriefId);
        }
    }, [travelBriefId]);

    // When a section is selected for editing, it must be loaded from the DB
    //  and set to state
    useEffect(() => {
        if (sectionToEdit && sectionToEdit.id) {
            briefsNamespace.on(`sectionDetail_${sectionToEdit?.id}`, (data) => {
                setSectionToEdit(data);
                setShowSectionModal(true);
            });
        }

        return () => {
            briefsNamespace.off(`sectionDetail_${sectionToEdit?.id}`);
        };
    }, [sectionToEdit]);

    /* ---------------------- */
    /* User-exposed functions */
    /* ---------------------- */
    function closeSectionModal() {
        setShowSectionModal(false);
        setSectionToEdit(null);
    }

    function fetchSection(id) {
        setSectionToEdit({ id });
        briefsNamespace.emit('getSectionById', id);
    }

    function onDiscard() {
        confirmAlert({
            title: 'Confirm Discard',
            message: `Are you sure you want to discard your changes?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: _navigateToListView
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    function onPublish() {
        _confirmationModal('publishTravelBrief', travelBriefId, {
            title: 'Confirm Publish',
            message: `Are you sure you want to publish all pending items for: ${displayTitle}?`
        });
    }

    function bumpDate() {
        _confirmationModal('bumpTravelBriefDate', travelBriefId, {
            title: 'Confirm Date',
            message: `This will update the Last Updated date and Publish the following Travel Brief: ${displayTitle}. Proceed?`
        });
    }

    function saveDraft() {
        _saveBrief(STATUS_DRAFT, {
            title: 'Confirm Save',
            message: `Are you sure you want to save ${displayTitle} as a draft?`,
            additionalModalMessage: travelBriefId
                ? () => (
                      <p>
                          **NOTE** This brief, as well as any sections that were
                          in Pending status, will be reverted to Draft status.
                      </p>
                  )
                : null
        });
    }

    function sendForApproval() {
        _saveBrief(STATUS_PENDING, {
            title: 'Confirm Send for Approval',
            message: `Are you sure you want to send ${displayTitle} for approval?`
        });
    }

    function updateSection(section) {
        // We need to find the section and update it in the array
        setSections((oldSections) => {
            const ix = _.findIndex(oldSections, {
                defaultTitle: section.defaultTitle
            });
            oldSections[ix] = section;
            return oldSections;
        });
    }

    // to be passed down to the depths of saved boundary search
    // so that we can swap boundaries out on the map when it is updated
    function updateBoundary(boundary) {
        setBoundary(boundary);
    }

    function updateTitle(e) {
        setTitle(e.target.value);
    }

    /* ------------------------- */
    /* Internal/helper functions */
    /* ------------------------- */
    function _allowEdits() {
        return status !== STATUS_INACTIVE;
    }

    function _auditData(brief) {
        const validationMessages =
            validate(brief, {
                title: {
                    presence: {
                        allowEmpty: false,
                        message: '^Travel Briefs must have a title.'
                    }
                },
                boundaryId: {
                    presence: {
                        allowEmpty: false,
                        message: '^Travel Briefs must have one map boundary.'
                    }
                }
            }) || {};

        // If sending for approval and this is first creation, all sections need to be filled in
        if (brief.status === STATUS_PENDING && !travelBriefId) {
            // Ensure there are as many sections as possible types, and ensure they are all filled in
            const emptySections = _.filter(brief.sections, (section) => {
                return !section.content;
            });
            if (
                brief.sections?.length !== SECTION_TYPES.length ||
                !_.isEmpty(emptySections)
            ) {
                validationMessages['sections'] = [
                    `Please ensure the following sections are not blank: ${emptySections
                        .map((section) =>
                            section.customTitle
                                ? section.customTitle
                                : section.defaultTitle
                        )
                        .join(', ')}`
                ];
            }
        }

        return validationMessages;
    }

    function _compileBriefData(status) {
        return {
            id: travelBriefId,
            status,
            title,
            notes,
            sections,
            boundaryId: boundary?.id
        };
    }

    function _confirmationModal(eventName, brief, modalOptions) {
        const { title, message, additionalModalMessage } = modalOptions;
        const alertOptions = {
            title,
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        briefsNamespace.emit(eventName, brief);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        };
        if (additionalModalMessage) {
            alertOptions['childrenElement'] = additionalModalMessage;
        }
        confirmAlert(alertOptions);
    }

    function _getEventName() {
        return travelBriefId ? 'updateTravelBrief' : 'createTravelBrief';
    }

    function _navigateToListView() {
        navigate('/travel-briefs');
    }

    function _saveBrief(status, modalOptions) {
        const brief = _compileBriefData(status);
        const validationMessages = _auditData(brief);
        if (_.isEmpty(validationMessages)) {
            _confirmationModal(_getEventName(), brief, modalOptions);
        } else {
            auditsFailedModal(validationMessages);
        }
    }

    function saveNotes() {
        briefsNamespace.once('updateTravelBriefNotesSuccess', (note) => {
            setExistingNote(note);
            setNotes(note);
            briefsNamespace.off('updateTravelBriefNotesFailure');
        });
        briefsNamespace.once(
            'updateTravelBriefNotesFailure',
            (errorMessage) => {
                briefsNamespace.off('updateTravelBriefNotesSuccess');
                confirmAlert({
                    title: 'Save Notes Failed',
                    message: errorMessage,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {}
                        }
                    ]
                });
            }
        );
        briefsNamespace.emit('updateTravelBriefNotes', {
            travelBriefId,
            notes
        });
    }

    function deleteSource(source) {
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this source?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        briefsNamespace.emit('deleteBriefSource', source.id);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    function openSourceModal(source) {
        if (source) {
            setSourceToEdit(source);
        }
        setShowSourceModal(true);
    }

    function closeSourceModal() {
        setShowSourceModal(false);
        setSourceToEdit(null);
    }

    /* ------ */
    /* Render */
    /* ------ */
    if (doesNotExist) {
        return <PageNotFound link="/travel-briefs" objectName="Travel Brief" />;
    }
    if (loading) {
        return (
            <div className="IntelCenter-loader">
                <i className="fa fa-spinner fa-spin" />
            </div>
        );
    }
    return (
        <div className="ContentForm">
            <div className="ContentForm-header">
                <FaChevronLeft
                    className="back-arrow"
                    onClick={_navigateToListView}
                />
                <h2>
                    {displayTitle && travelBriefId
                        ? displayTitle
                        : 'New Travel Brief'}
                </h2>
                <StatusBadge status={status} />
            </div>

            <div className="ContentForm-contents">
                <div className="ContentForm-column">
                    {/* Edit brief */}
                    <div className="ContentForm-area">
                        <div className="ContentForm-area-header">
                            <h3>Travel Brief</h3>
                        </div>

                        {!_allowEdits() && (
                            <p>
                                This Travel Brief has been marked inactive. No
                                further updates can be made.
                            </p>
                        )}

                        <form>
                            <div className="ContentForm-input-container">
                                <label htmlFor="content-title">
                                    Travel Brief Title
                                </label>
                                <div className="input">
                                    <input
                                        id="content-title"
                                        className="ContentForm-form-control"
                                        type="text"
                                        value={title}
                                        onChange={updateTitle}
                                        disabled={!_allowEdits()}
                                        data-testid={
                                            TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                                .CONTENT_TITLE
                                        }
                                    />
                                </div>
                            </div>
                            <div className="ContentForm-input-container-map">
                                <TravelBriefMap
                                    boundary={boundary}
                                    updateBoundary={updateBoundary}
                                    travelBriefId={travelBriefId}
                                />
                            </div>
                            <div className="ContentForm-input-container">
                                <label
                                    htmlFor={`${
                                        TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                            .ANALYST_NOTES
                                    }${
                                        travelBriefId ? `-${travelBriefId}` : ''
                                    }`}
                                >
                                    Analyst Notes
                                </label>

                                <div className="input">
                                    <AnalystNotes
                                        analystNote={notes}
                                        existingNote={existingNote}
                                        id={`${
                                            TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                                .ANALYST_NOTES
                                        }${
                                            travelBriefId
                                                ? `-${travelBriefId}`
                                                : ''
                                        }`}
                                        saveNotes={saveNotes}
                                        showSave={!!travelBriefId}
                                        updateNote={setNotes}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                    {travelBriefId ? (
                        <SectionsTable
                            sections={sections}
                            onEdit={fetchSection}
                        />
                    ) : (
                        _.map(sections, (section, ix) => {
                            return (
                                <SectionForm
                                    initialEditorKey={ix}
                                    key={section.defaultTitle}
                                    allowEdit={_allowEdits()}
                                    section={section}
                                    travelBriefId={travelBriefId}
                                    updateSection={updateSection}
                                />
                            );
                        })
                    )}
                </div>
                <div className="ContentForm-column">
                    <ActionForm
                        onDiscard={onDiscard}
                        onSave={saveDraft}
                        onPublish={onPublish}
                        onSendForApproval={sendForApproval}
                        status={status}
                        disablePublish={
                            brief.title !== title ||
                            brief.boundary?.id !== boundary?.id
                        }
                        bumpDate={bumpDate}
                    />
                    {travelBriefId && sources && (
                        <>
                            {/* Sources */}
                            <Sources
                                allowEdit={_allowEdits()}
                                handleDelete={deleteSource}
                                openSourceModal={openSourceModal}
                                sources={sources}
                            />
                        </>
                    )}
                </div>
            </div>

            <Modal
                isOpen={showSourceModal}
                contentLabel="Travel Brief Source Form"
                ariaHideApp={false}
                style={{
                    content: {
                        top: '20%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -20%)',
                        width: '50%'
                    }
                }}
            >
                <SourceForm
                    closeSourceModal={closeSourceModal}
                    travelBriefId={travelBriefId}
                    source={sourceToEdit}
                />
            </Modal>

            <Modal
                isOpen={showSectionModal}
                contentLabel="Travel Brief Section Form"
                ariaHideApp={false}
                style={{
                    content: {
                        top: '20%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -20%)',
                        width: '70%'
                    }
                }}
            >
                {sectionToEdit && (
                    <SectionForm
                        allowEdit={_allowEdits()}
                        section={sectionToEdit}
                        closeModal={closeSectionModal}
                        isModalView={true}
                        travelBriefId={travelBriefId}
                        updateSection={updateSection}
                    />
                )}
            </Modal>
        </div>
    );
}
