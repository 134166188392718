import './ThreatDeckCard.css';

import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import React from 'react';

import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';

export default function CardContent({
    item,
    isRead,
    cardView = false,
    markAsRead
}) {
    const noTitle = isEmpty(item?.title) || item?.type === 'NAP';

    let displayTitle, displayContent, includeBreak;
    if (cardView) {
        if (!noTitle) {
            const titleLength = item?.title?.length;
            // 30 is the approximate number of characters that can fit on one line of the card
            if (titleLength + 30 < 260 && item?.content?.length) {
                // use the whole title
                displayTitle = item?.title;
                // add a break if there is content
                includeBreak = true;
                // truncate the content to fit in the remaining space below the title and line break
                displayContent = truncate(item?.content, {
                    length: 260 - 30 - titleLength,
                    separator: ' '
                });
            } else {
                // if the title is longer than our limit, truncate it
                displayTitle = truncate(item?.title, {
                    length: 260,
                    separator: ' '
                });
                // don't add a break
                includeBreak = false;
                // don't include the content
            }
        } else {
            // when we don't have a title we don't need a line break
            includeBreak = false;
            // if there is no title we will display only the content, truncated
            displayContent = truncate(item?.content, {
                length: 260,
                separator: ' '
            });
        }
    } else {
        // when we are looking at the details view
        // we don't truncate here
        if (noTitle) {
            // if there is no title, just display the content
            displayContent = item?.content;
        } else {
            if (item?.content) {
                // if there is a title and content, display them both
                displayTitle = `${item?.title}`;
                // with a break
                includeBreak = true;
                displayContent = item?.content;
            } else {
                // if there is a title but no content, just display the title
                displayTitle = `${item?.title}`;
            }
        }
    }

    return (
        <div
            className={`ThreatDeckCard-${cardView ? 'content' : 'details'}`}
            data-testid={TEST_ATTRIBUTES.THREAT_DECK.CARDS.CONTENT}
        >
            {displayTitle}
            {includeBreak && <br />}
            {displayContent}
        </div>
    );
}
