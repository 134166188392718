import includes from 'lodash/includes';
import React, { useState } from 'react';

import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';

const {
    THREAT_DECK: {
        COLUMNS: { COLUMN_CONFIG }
    }
} = TEST_ATTRIBUTES;

export default function ColumnSources({ onChange, sources = [] }) {
    const [currentSources, setCurrentSources] = useState(sources);

    const handleSourceChange = (e) => {
        if (e.target.checked) {
            // add the source to the list
            setCurrentSources([
                ...currentSources,
                e.target.labels[0].innerText
            ]);
            onChange([...currentSources, e.target.labels[0].innerText]);
        } else {
            const filteredSources = currentSources.filter(
                (s) => s !== e.target.labels[0].innerText
            );
            // remove the source from the list
            setCurrentSources(filteredSources);
            onChange(filteredSources);
        }
    };

    return (
        <div className="ThreatDeck-input-container column">
            <div className="input-header">Sources:</div>
            <div className="input ThreatDeck-sources-container">
                <div className="column-sources">
                    <input
                        id="column-sources-rss"
                        className="ContentForm-form-control"
                        type="checkbox"
                        checked={includes(currentSources, 'RSS')}
                        onChange={handleSourceChange}
                        data-testid={COLUMN_CONFIG.SOURCES.RSS}
                    />
                    <label className="input-label" htmlFor="column-sources-rss">
                        RSS
                    </label>
                </div>
                <div className="column-sources">
                    <input
                        id="column-sources-twitter"
                        className="ContentForm-form-control"
                        type="checkbox"
                        checked={includes(currentSources, 'TWITTER')}
                        onChange={handleSourceChange}
                        data-testid={COLUMN_CONFIG.SOURCES.TWITTER}
                    />
                    <label
                        className="input-label"
                        htmlFor="column-sources-twitter"
                    >
                        TWITTER
                    </label>
                </div>

                <div className="column-sources">
                    <input
                        id="column-sources-nap"
                        className="ContentForm-form-control"
                        type="checkbox"
                        checked={includes(currentSources, 'NAP')}
                        onChange={handleSourceChange}
                        data-testid={COLUMN_CONFIG.SOURCES.NAP}
                    />
                    <label className="input-label" htmlFor="column-sources-nap">
                        NAP
                    </label>
                </div>

                <div className="column-sources">
                    <input
                        id="column-sources-alertpage"
                        className="ContentForm-form-control"
                        type="checkbox"
                        checked={includes(currentSources, 'ALERTPAGE')}
                        onChange={handleSourceChange}
                        data-testid={COLUMN_CONFIG.SOURCES.ALERTPAGE}
                    />
                    <label
                        className="input-label"
                        htmlFor="column-sources-alertpage"
                    >
                        ALERTPAGE
                    </label>
                </div>
            </div>
        </div>
    );
}
