import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROLES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { getAuthInfo } from '../../../../redux/selectors';
import ICPencilIcon from '../../../shared/ICPencilIcon';
import ICTrashIcon from '../../../shared/ICTrashIcon';
import StatusBadge from './StatusBadge';

const { REPORTS_TABLE } = TEST_ATTRIBUTES.CONTENT_MANAGEMENT;
const { ADMINISTRATOR, LEAD_ANALYST } = ROLES;

export default function ReportsTable({
    onArchive,
    onSortChanged,
    reports,
    sort
}) {
    const authInfo = useSelector(getAuthInfo);

    function renderCaret(sortName) {
        if (sort[sortName] === 'DESC') return <FaSortDown />;
        if (sort[sortName] === 'ASC') return <FaSortUp />;
        return <FaSort />;
    }

    const showDelete =
        _.includes([ADMINISTRATOR, LEAD_ANALYST], authInfo.role) &&
        _.isFunction(onArchive);

    return (
        <table className="ContentManagement-table">
            <thead className="ContentManagement-table-header">
                <tr>
                    <th
                        className="sortable-header"
                        onClick={() => onSortChanged('title')}
                        style={{ width: '35%' }}
                    >
                        TITLE {renderCaret('title')}
                    </th>
                    <th
                        className="sortable-header"
                        onClick={() => onSortChanged('creator')}
                        style={{ width: '20%' }}
                    >
                        AUTHOR {renderCaret('creator')}
                    </th>
                    <th
                        className="sortable-header"
                        onClick={() => onSortChanged('updateDate')}
                        style={{ width: '25%' }}
                    >
                        LAST UPDATE {renderCaret('updateDate')}
                    </th>
                    <th
                        className="sortable-header"
                        onClick={() => onSortChanged('status')}
                        style={{ width: '10%' }}
                    >
                        STATUS {renderCaret('status')}
                    </th>
                    <th id="actions-header" style={{ width: '10%' }}>
                        ACTIONS
                    </th>
                </tr>
            </thead>
            <tbody className="ContentManagement-table-body">
                {_.map(reports, (report) => (
                    <tr
                        key={report.id}
                        data-testid={`${REPORTS_TABLE.ROW}-${report.id}`}
                    >
                        <td
                            data-testid={`${REPORTS_TABLE.ROW_TITLE}-${report.id}`}
                        >
                            <Link
                                to={`edit/${report.id}`}
                                className="ContentManagement-title-cell"
                            >
                                {report.title}
                            </Link>
                        </td>
                        <td
                            data-testid={`${REPORTS_TABLE.ROW_AUTHOR}-${report.id}`}
                        >
                            {report.creator.name}
                        </td>
                        <td>
                            {report.updateDate &&
                                moment(report.updateDate * 1000).format(
                                    'MMMM Do, YYYY - h:mm A'
                                )}
                        </td>
                        <td
                            data-testid={`${REPORTS_TABLE.ROW_STATUS}-${report.id}`}
                        >
                            <StatusBadge status={report.status} />
                        </td>
                        <td>
                            <span className="icons-container">
                                {showDelete && (
                                    <ICTrashIcon
                                        onClick={() => onArchive(report.id)}
                                        data-testid={`${REPORTS_TABLE.ROW_DELETE_BUTTON}-${report.id}`}
                                    />
                                )}
                                <Link to={`edit/${report.id}`}>
                                    <ICPencilIcon
                                        data-testid={`${REPORTS_TABLE.ROW_EDIT_BUTTON}-${report.id}`}
                                    />
                                </Link>
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
