import './styling/ThreatDeckApp.css';

import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Login from './components/shared/Login';
import ThreatDeck from './components/threat-deck/ThreatDeck';
import { APPS, NAMESPACES } from './config/constants';
import { useCognitoSession } from './hooks/useCognitoSession';
import { useSocketManager } from './hooks/useSocketManager';
import {
    setApplication,
    setApplicationRegions,
    setApplicationUsers,
    setCapabilitiesList,
    setCategoriesList,
    setUser,
    setUserRole,
    socketsConnected
} from './redux/actions';
import {
    checkSocketConnections,
    checkSocketsReconnecting,
    getAuthInfo,
    getLoggedInUser,
    getNamespace,
    getSocketManager,
    getUserSession
} from './redux/selectors';

const { THREATDECK_NAMESPACE, USERS_NAMESPACE } = NAMESPACES;

export default function ThreatDeckApp() {
    // Redux
    const authInfo = useSelector(getAuthInfo);
    const user = useSelector(getLoggedInUser);
    const areSocketsConnected = useSelector(checkSocketConnections);
    const areSocketsReconnecting = useSelector(checkSocketsReconnecting);
    const dispatch = useDispatch();
    // React Router
    const navigate = useNavigate();
    useEffect(() => {
        if (authInfo?.role === 'Labeler' && userIsLoggedIn(authInfo?.user)) {
            navigate('/labeling');
        }
    }, [authInfo, navigate]);

    const userSession = useSelector(getUserSession);
    const { isCheckingSession } = useCognitoSession({
        dependencies: []
    });

    const socketManager = useSelector(getSocketManager);
    useSocketManager({
        url: process.env.REACT_APP_SOCKET_ENDPOINT,
        namespaces: [
            {
                namespace: USERS_NAMESPACE,
                onConnect: (socket) => {
                    console.log(
                        `${socket.id} successfully connected to ${USERS_NAMESPACE}`
                    );

                    // It's good to know this exist if we need it
                    // but we currently watch when a socket changes state and get fresh data then.
                    if (socket.recovered) {
                        // any event missed during the disconnection period will be received now
                    } else {
                        // new or unrecoverable session
                        // get fresh data when a socket reconnects
                    }
                }
            },
            {
                namespace: THREATDECK_NAMESPACE,
                onConnect: (socket) => {
                    console.log(
                        `${socket.id} successfully connected to ${THREATDECK_NAMESPACE}`
                    );

                    // It's good to know this exist if we need it
                    // but we currently watch when a socket changes state and get fresh data then.
                    if (socket.recovered) {
                        // any event missed during the disconnection period will be received now
                    } else {
                        // new or unrecoverable session
                        // get fresh data when a socket reconnects
                    }
                }
            }
        ],
        options: {},
        dependencies: []
    });

    const threatDeckNamespace = useSelector(getNamespace(THREATDECK_NAMESPACE));
    const usersNamespace = useSelector(getNamespace(USERS_NAMESPACE));

    useEffect(() => {
        dispatch(socketsConnected(every(socketManager.nsps, 'connected')));
    }, [socketManager, usersNamespace, threatDeckNamespace]);

    useEffect(() => {
        if (usersNamespace && areSocketsConnected) {
            usersNamespace.on('usersList', (userList) => {
                dispatch(setApplicationUsers(userList));
            });
            usersNamespace.on(
                'applicationData',
                ({
                    userInfo,
                    applicationRegions,
                    applicationCategories,
                    applicationCapabilities
                }) => {
                    // Load user-dependent data needed throughout the whole app
                    dispatch(setUser(userInfo));
                    dispatch(setUserRole(userInfo.role));

                    // no need to update anything that doesn't have any data
                    if (!isEmpty(applicationRegions)) {
                        dispatch(setApplicationRegions(applicationRegions));
                    }
                    if (!isEmpty(applicationCategories)) {
                        dispatch(setCategoriesList(applicationCategories));
                    }
                    if (!isEmpty(applicationCapabilities)) {
                        dispatch(setCapabilitiesList(applicationCapabilities));
                    }
                }
            );
            usersNamespace.on('userInfo', (userInfo) => {
                dispatch(setUser(userInfo));
            });

            usersNamespace.emit('getApplicationData');
        }

        return () => {
            if (usersNamespace) {
                usersNamespace.off('usersList');
                usersNamespace.off('applicationData');
                usersNamespace.off('userInfo');
            }
        };
    }, [usersNamespace, areSocketsConnected]);

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on('connect', () => {
                console.log(
                    `${threatDeckNamespace.id} successfully connected to ${THREATDECK_NAMESPACE}`
                );
            });
        }
    }, [threatDeckNamespace]);

    function userIsLoggedIn(user) {
        return !isEmpty(user) && !!user?.id;
    }

    useEffect(() => {
        async function setUp() {
            try {
                // TODO: Remove this down the road - it's pretty useful right now
                window.LOG_LEVEL = 'DEBUG';

                // Swap out the default favicon for Gator
                const favicon = document.getElementById('favicon');
                favicon.href = 'favicon_gator.png';

                // Set application info
                dispatch(setApplication(APPS.TD));

                // Make sure title matches application
                document.title = APPS.TD.label;
            } catch (e) {
                console.log(e);
            }
        }
        setUp();
    }, []);

    return (
        <div
            className={`ThreatDeck${
                areSocketsConnected
                    ? ' socket-connected'
                    : ' socket-disconnected'
            }`}
        >
            {isCheckingSession ? (
                <div className="ThreatDeck-loader">
                    <i className="fa fa-spinner fa-spin" />
                </div>
            ) : (userSession &&
                  userIsLoggedIn(user) &&
                  areSocketsReconnecting) ||
              (userSession && userIsLoggedIn(user) && areSocketsConnected) ? (
                <ThreatDeck />
            ) : (
                <Login />
            )}
        </div>
    );
}
