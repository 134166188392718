import { Editor } from '@tinymce/tinymce-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import validate from 'validate.js';

import { NAMESPACES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { getNamespace } from '../../../../redux/selectors';
import { auditsFailedModal, stripTags } from '../../../../utils/utils';
import ICButton from '../../../shared/ICButton';

const { CONTENT_MANAGEMENT } = TEST_ATTRIBUTES;

export default function SectionForm({
    allowEdit = true,
    section = {},
    closeModal,
    initialEditorKey = 0,
    contentOnly = false,
    travelBriefId,
    updateSection
}) {
    const displayTitle = section.customTitle
        ? section.customTitle
        : section.defaultTitle;
    const [content, setContent] = useState(section?.content || '');
    const [editorKey, setEditorKey] = useState(initialEditorKey); // Used to reset tinyMCE
    const [customTitle, setCustomTitle] = useState(displayTitle);
    const briefsNamespace = useSelector(
        getNamespace(NAMESPACES.BRIEFS_NAMESPACE)
    );

    const TINY_MCE_CONFIG = {
        placeholder: 'Type content here',
        height: 250,
        width: '100%',
        plugins: [
            'autolink',
            'lists',
            'advlist',
            'searchreplace',
            'pasearchreplaceste',
            'table',
            'lintablek',
            'autolink',
            'fullscreen'
        ],
        toolbar: `blocks |  bold italic underline | numlist bullist | blockquote hr | alignleft aligncenter alignright | link paste | fullscreen `,
        paste_preprocess: function (plugin, args) {
            // We will do our own pasting processing - we allow only certain HTML tags below
            args.content = stripTags(
                args.content,
                'b',
                'i',
                'u',
                'p',
                'br',
                'a',
                'strong',
                'em'
            );
        },
        extended_valid_elements: 'span',
        link_default_target: '_blank',
        browser_spellcheck: true,
        contextmenu: false
    };

    useEffect(() => {
        if (briefsNamespace) {
            briefsNamespace.on('sectionSaveFailed', () => {
                confirmAlert({
                    title: 'Save Failed',
                    message: 'There was an error.',
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            });
        }

        return () => {
            briefsNamespace.off('sectionSaveFailed');
        };
    }, [briefsNamespace]);

    useEffect(() => {
        updateSection({ ...section, content });
    }, [content]);

    function confirmCancel() {
        if (!allowEdit || !content) {
            // If the user hasn't updated anything, no confirmation needed
            clearAndCloseModal();
            return;
        }
        confirmAlert({
            title: 'Confirm Cancel',
            message: `Are you sure you want to discard your edits?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: clearAndCloseModal
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function auditData() {
        return validate(
            { content },
            {
                content: {
                    presence: {
                        allowEmpty: false,
                        message: '^Sections must have content.'
                    }
                }
            }
        );
    }

    function confirmSave() {
        const validationMessages = auditData();
        if (_.isEmpty(validationMessages)) {
            confirmAlert({
                title: 'Confirm Save',
                message: `Are you sure you want to save your changes?`,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: saveSection
                    },
                    {
                        label: 'No',
                        onClick: () => {}
                    }
                ]
            });
        } else {
            auditsFailedModal(validationMessages);
        }
    }

    function saveSection() {
        briefsNamespace.emit('updateSection', {
            id: section?.id,
            content,
            customTitle,
            travelBriefId
        });
    }

    function clearAndCloseModal() {
        clearData();
        closeModal();
    }

    function clearData() {
        setContent('');
        setCustomTitle(displayTitle);
        setEditorKey((currVal) => currVal + 1);
    }

    function updateContent(e) {
        setContent(e);
    }

    function updateTitle(e) {
        setCustomTitle(e.target.value);
    }

    return (
        <div className="ContentForm-area">
            {closeModal ? (
                <div className="ContentManagementModal-header">
                    <h2>{displayTitle}</h2>
                    <button
                        className="ReactModal__CloseButton"
                        onClick={confirmCancel}
                        data-testid={`${
                            CONTENT_MANAGEMENT.CLOSE_SECTION_MODAL
                        }${section?.id ? `-${section.id}` : ''}`}
                    >
                        <i className="fa fa-window-close" />
                    </button>
                </div>
            ) : (
                <div className="ContentForm-area-header">
                    <h3>{displayTitle}</h3>
                </div>
            )}
            <div className="ContentForm-area-body">
                <form>
                    {travelBriefId && allowEdit && (
                        <div className="ContentForm-input-container">
                            <label
                                htmlFor={`${'section_content_title'}${
                                    section?.id
                                        ? `-${section.id}`
                                        : `-${initialEditorKey}`
                                }`}
                            >
                                Title
                            </label>
                            <div className="input">
                                <input
                                    id="section_content_title"
                                    className="ContentForm-form-control"
                                    type="text"
                                    value={customTitle}
                                    onChange={updateTitle}
                                    disabled={!allowEdit}
                                    data-testid={
                                        CONTENT_MANAGEMENT.SECTION_FORM
                                            .SECTION_TITLE
                                    }
                                />
                            </div>
                        </div>
                    )}
                    <div className="ContentForm-input-container">
                        <label
                            htmlFor={`${'section_content_tinymce'}${
                                section && section.id
                                    ? `-${section.id}`
                                    : `-${initialEditorKey}`
                            }`}
                        >
                            Content
                        </label>
                        <div className="input">
                            <Editor
                                key={editorKey}
                                id={`${'section_content_tinymce'}${
                                    section && section.id
                                        ? `-${section.id}`
                                        : `-${initialEditorKey}`
                                }`}
                                value={content || null}
                                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                                init={TINY_MCE_CONFIG}
                                onEditorChange={updateContent}
                                disabled={!allowEdit}
                                data-testid={CONTENT_MANAGEMENT.ARTICLE_CONTENT}
                            />
                        </div>
                    </div>
                </form>
            </div>
            {closeModal && (
                <div className="ContentManagementModal-footer">
                    <ICButton color="white" onClick={confirmCancel}>
                        Cancel
                    </ICButton>
                    <ICButton
                        disabled={!allowEdit}
                        color="blue"
                        onClick={confirmSave}
                    >
                        Save
                    </ICButton>
                </div>
            )}
        </div>
    );
}
