import './ThreatDeckCard.css';

import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MODALS, NAMESPACES } from '../../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';
import {
    isItemRead,
    markItemAsRead
} from '../../../../../localStorage/readItemsStorage';
import {
    refreshThreatDeckFeedItems,
    toggleModal
} from '../../../../../redux/actions';
import { getNamespace } from '../../../../../redux/selectors';
import CardCategory from './CardCategory';
import CardContent from './CardContent';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';
import CardTimestamp from './CardTimestamp';

export default function ThreatDeckCard({
    item,
    localColumnConfig,
    isEventView = false,
    style,
    cardView
}) {
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    const dispatch = useDispatch();

    // We need to mark this as read when the review modal is opened,
    // both storing that read state, and then triggering a re-render of
    // feed item cards, as the same item could be displayed in any
    // number of columns
    function markAsRead() {
        if (isEventView) {
            return;
        }
        markItemAsRead(item.id);
        dispatch(refreshThreatDeckFeedItems());
    }

    // If this has been annotated by a user, use that annotation
    // to determine how to highlight the card, otherwise use
    // the ML annotation
    const isThreat = isNil(item?.annotation?.ui_type)
        ? item?.annotation?.ml_type
        : item?.annotation?.ui_type;
    const highlight = isThreat ? 'accepted' : 'rejected';

    // Save annotation on switch change, updating only ui_type
    function onSwitchChange(state) {
        // This shouldn't happen, but we don't want to accidentally
        // create a new duplicate feed item
        if (!item?.id || !item?.annotation) {
            console.error(
                'Unable to update annotation type, id or annotation is missing. Item: ',
                item
            );
        } else {
            threatDeckNamespace.emit('saveFeedItemAnnotation', {
                ThreatDeckFeedItemId: item.annotation.ThreatDeckFeedItemId,
                ui_category: item.annotation.ui_category,
                ui_confidence: item.annotation.ui_confidence,
                ui_severity: item.annotation.ui_severity,
                ui_type: state
            });
        }

        // Also mark as read if needed
        if (!isItemRead(item.id)) {
            markAsRead();
        }
    }

    function openReviewModal(event) {
        if (
            includes(
                [event.target.id, event.target.parentElement.id],
                `td-card-${item.id}`
            )
        ) {
            markAsRead();
            dispatch(
                toggleModal({
                    data: item,
                    modalName: MODALS.FEED_ITEM_REVIEW,
                    value: true
                })
            );
        }
    }

    function localColumnConfigCheck() {
        const isRead = isItemRead(item.id);

        if (localColumnConfig?.showViewed && localColumnConfig?.showUnviewed) {
            // if both configs are true, show the card
            return true;
        } else {
            return isRead
                ? localColumnConfig.showViewed // if it is read only show it if the viewed config is true
                : localColumnConfig.showUnviewed; // if it isn't viewed, only show it if the unviewed config is true
        }
    }

    return (
        <>
            {(isEventView || localColumnConfigCheck()) && (
                <div
                    id={`td-card-${item.id}`}
                    className={`ThreatDeckCard highlight-${highlight} ${
                        isItemRead(item.id) ? 'ThreatDeckCard-read' : ''
                    }`}
                    data-test={`${TEST_ATTRIBUTES.THREAT_DECK.CARDS.STATUS}-${item.id}-${highlight}`}
                    data-testid={TEST_ATTRIBUTES.THREAT_DECK.CARDS.CARD}
                    onClick={openReviewModal}
                    style={style}
                >
                    <CardHeader item={item} cardView={cardView} />
                    {/* Will not be showing location information yet */}
                    {/* <CardLocation item={item} /> */}
                    {!isEventView && <CardCategory item={item} />}
                    <CardContent
                        item={item}
                        isRead={isItemRead(item.id)}
                        cardView={cardView}
                        markAsRead={markAsRead}
                    />
                    <CardTimestamp item={item} />
                    <CardFooter
                        item={item}
                        onSwitchChange={onSwitchChange}
                        markAsRead={markAsRead}
                        isEventView={isEventView}
                    />
                </div>
            )}
        </>
    );
}
