import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { FaChevronLeft } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import validate from 'validate.js';

import { CONTENT_MANAGEMENT, NAMESPACES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { getNamespace } from '../../../../redux/selectors';
import { auditsFailedModal } from '../../../../utils/utils';
import PageNotFound from '../../../shared/error-pages/PageNotFound';
import AnalystNotes from '../../shared/alerts/AnalystNotes';
import ActionForm from '../../shared/content-management/ActionForm';
import Sources from '../../shared/content-management/Sources';
import StatusBadge from '../../shared/content-management/StatusBadge';
import ArticlesTable from '../ArticlesTable';
import RelatedAlerts from '../RelatedAlerts';
import AlertForm from './AlertForm';
import ArticleForm from './ArticleForm';
import SourceForm from './SourceForm';

const { STATUS_ACTIVE, STATUS_DRAFT, STATUS_INACTIVE, STATUS_PENDING } =
    CONTENT_MANAGEMENT;

export default function SituationReportForm() {
    const situationsNamespace = useSelector(
        getNamespace(NAMESPACES.SITUATIONS_NAMESPACE)
    );
    const [title, setTitle] = useState('');
    const [notes, setNotes] = useState('');
    const [displayTitle, setDisplayTitle] = useState('');
    const [sources, setSources] = useState([]);
    const [articles, setArticles] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [article, setArticle] = useState();
    const [sourceToEdit, setSourceToEdit] = useState();
    const [showSourceModal, setShowSourceModal] = useState(false);
    const [showAlertsModal, setShowAlertsModal] = useState(false);
    const [articleToEdit, setArticleToEdit] = useState();
    const [showArticleModal, setShowArticleModal] = useState(false);
    const [status, setStatus] = useState();
    const [doesNotExist, setDoesNotExist] = useState(false);
    // existing note is split from the situation report to avoid losing changes when notes are saved
    const [existingNote, setExistingNote] = useState();

    // React-Router
    const { situationId } = useParams();
    const navigate = useNavigate();

    /* ----- */
    /* Hooks */
    /* ----- */
    // Load data if a situationId was passed in
    useEffect(() => {
        // Use situationId to fetch details
        if (situationId && situationsNamespace) {
            // Register listeners
            situationsNamespace.on(
                `situationReportAlerts_${situationId}`,
                (data) => setAlerts(data)
            );
            situationsNamespace.on(
                `situationReportArticles_${situationId}`,
                (data) => setArticles(data)
            );
            situationsNamespace.on(
                `situationReportDetails_${situationId}`,
                (situationReport) => {
                    if (!situationReport) {
                        setDoesNotExist(true);
                        return;
                    }
                    setTitle(situationReport.title);
                    setDisplayTitle(situationReport.title);
                    setStatus(situationReport.status);
                    setNotes(situationReport.analystNotes);
                    setExistingNote(situationReport.analystNotes);
                }
            );
            situationsNamespace.on(
                `situationReportSources_${situationId}`,
                (data) => setSources(data)
            );

            // Emit events
            situationsNamespace.emit('getSituationReportById', situationId);
            situationsNamespace.emit('getArticlesBySituationId', situationId);
            situationsNamespace.emit('getAlertsBySituationId', situationId);
            situationsNamespace.emit('getSourcesBySituationId', situationId);
        }

        return () => {
            // Teardown
            situationsNamespace.off(`situationReportAlerts_${situationId}`);
            situationsNamespace.off(`situationReportArticles_${situationId}`);
            situationsNamespace.off(`situationReportDetails_${situationId}`);
            situationsNamespace.off(`situationReportSources_${situationId}`);
        };
    }, []);

    // Make UI reflect success or failure of DB operations
    useEffect(() => {
        if (situationsNamespace) {
            // Register listeners
            situationsNamespace.on('saveReportSucceeded', () => {
                _navigateToListView();
            });
            situationsNamespace.on('saveReportFailed', (message) => {
                confirmAlert({
                    title: 'Save Failed',
                    message,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            });
            situationsNamespace.on('publishSituationSucceeded', () => {
                _navigateToListView();
            });
            situationsNamespace.on('publishSituationFailed', (message) => {
                confirmAlert({
                    title: 'Publish Failed',
                    message,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            });
            situationsNamespace.on('addAlertSucceeded', (newAlert) => {
                setAlerts((prevAlerts) => [newAlert, ...prevAlerts]);
            });
            situationsNamespace.on('removeAlertSucceeded', (newAlert) => {
                setAlerts((prevAlerts) =>
                    _.reject(prevAlerts, { id: newAlert.id })
                );
            });
            situationsNamespace.on('addRemoveAlertFailed', (message) => {
                confirmAlert({
                    title: 'Save Failed',
                    message,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            });
            situationsNamespace.on('situationStatusChanged', (data) =>
                setStatus(data.status)
            );
            situationsNamespace.on(
                'situationSourceSavedSuccessfully',
                (source) => {
                    // Handle
                    setSources((currVal) => {
                        // De-dupe in case this was an update
                        return _.orderBy(
                            _.uniqBy([source, ...currVal], 'id'),
                            ['createdAt'],
                            ['desc']
                        );
                    });
                    setShowSourceModal(false);
                }
            );
            situationsNamespace.on('situationSourceDeleted', (id) => {
                // Handle
                setSources((currVal) => _.reject(currVal, { id }));
            });
            situationsNamespace.on('articleSavedSuccessfully', (newArticle) => {
                // Add article to state
                setArticles((currArticles) => {
                    return _.orderBy(
                        _.uniqBy([newArticle, ...currArticles], 'id'),
                        ['pinned', 'updatedAt'],
                        ['desc', 'desc']
                    );
                });
                // Close modal
                closeArticleModal();
            });
            situationsNamespace.on('articleDeletedSuccessfully', (article) => {
                // Remove article from state
                setArticles((currArticles) => {
                    return _.reject(currArticles, { id: article.id });
                });
            });
            situationsNamespace.on(
                'markInactiveSucceeded',
                _navigateToListView
            );
            situationsNamespace.on('markInactiveFailed', (message) => {
                confirmAlert({
                    title: 'Mark Inactive Failed',
                    message,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            });
        }

        return () => {
            // Teardown
            situationsNamespace.off('addAlertSucceeded');
            situationsNamespace.off('addRemoveAlertFailed');
            situationsNamespace.off('articleDeletedSuccessfully');
            situationsNamespace.off('articleSavedSuccessfully');
            situationsNamespace.off('markInactiveFailed');
            situationsNamespace.off('markInactiveSucceeded');
            situationsNamespace.off('publishSituationFailed');
            situationsNamespace.off('publishSituationSucceeded');
            situationsNamespace.off('removeAlertSucceeded');
            situationsNamespace.off('saveReportFailed');
            situationsNamespace.off('saveReportSucceeded');
            situationsNamespace.off('situationSourceDeleted');
            situationsNamespace.off('situationSourceSavedSuccessfully');
            situationsNamespace.off('situationStatusChanged');
        };
    }, []);

    // When an article is selected for editing, it must be loaded from the DB
    //  and set to state
    useEffect(() => {
        if (articleToEdit && articleToEdit.id) {
            situationsNamespace.on(
                `articleDetail_${articleToEdit?.id}`,
                (data) => {
                    setArticleToEdit(data);
                    setShowArticleModal(true);
                }
            );
        }

        return () => {
            situationsNamespace.off(`articleDetail_${articleToEdit?.id}`);
        };
    }, [articleToEdit]);

    /* ---------------------- */
    /* User-exposed functions */
    /* ---------------------- */
    function closeAlertsModal() {
        setShowAlertsModal(false);
    }

    function addArticle() {
        setShowArticleModal(true);
    }

    function closeSourceModal() {
        setShowSourceModal(false);
        setSourceToEdit(null);
    }

    function closeArticleModal() {
        setShowArticleModal(false);
        setArticleToEdit(null);
    }

    function deleteSource(source) {
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this source?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        situationsNamespace.emit(
                            'deleteSituationSource',
                            source.id
                        );
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    function fetchArticle(id) {
        setArticleToEdit({ id });
        situationsNamespace.emit('getArticleById', id);
    }

    function onDiscard() {
        confirmAlert({
            title: 'Confirm Discard',
            message: `Are you sure you want to discard your changes?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: _navigateToListView
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    function openAlertsModal() {
        setShowAlertsModal(true);
    }

    function openSourceModal(source) {
        if (source) {
            setSourceToEdit(source);
        }
        setShowSourceModal(true);
    }

    function removeFromSituation(alertId) {
        // TODO: we need a socket event for this. Do we allow them to remove the
        //  alert or does it have to be approved? If approved, how in the world
        //  will that actually work in the DB??
        confirmAlert({
            title: 'Confirm Remove',
            message: `Are you sure you want to remove this threat?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () =>
                        situationsNamespace.emit(
                            'removeAlertFromSituationReport',
                            {
                                alertId,
                                situationReportId: situationId
                            }
                        )
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function markInactive() {
        confirmAlert({
            title: 'Confirm Mark Inactive',
            message: `Are you sure you want to mark ${displayTitle} inactive?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        situationsNamespace.emit(
                            'markReportInactive',
                            situationId
                        );
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        });
    }

    function onPublish() {
        _publishSituationReport(STATUS_ACTIVE, {
            title: 'Confirm Publish',
            message: `Are you sure you want to publish all pending items for: ${displayTitle}`,
            additionalModalMessage: () => {} // maybe one day we list out the pending items here.
        });
    }

    function saveDraft() {
        _saveReport(STATUS_DRAFT, {
            title: 'Confirm Save',
            message: `Are you sure you want to save ${displayTitle} as a draft?`,
            additionalModalMessage: situationId
                ? () => (
                      <p>
                          **NOTE** This report, as well as any articles/sources
                          that were in Pending status, will be reverted to Draft
                          status.
                      </p>
                  )
                : null
        });
    }

    function sendForApproval() {
        _saveReport(STATUS_PENDING, {
            title: 'Confirm Send for Approval',
            message: `Are you sure you want to send ${displayTitle} for approval?`
        });
    }

    function updateArticle(article) {
        setArticle(article);
    }

    function updateTitle(e) {
        setTitle(e.target.value);
    }

    /* ------------------------- */
    /* Internal/helper functions */
    /* ------------------------- */
    function _allowEdits() {
        return status !== STATUS_INACTIVE;
    }

    function _auditData(situation) {
        const situationMessages = validate(situation, {
            title: {
                presence: {
                    allowEmpty: false,
                    message: '^Situation Reports must have a title.'
                }
            }
        });

        let articleMessages = {};
        if (situation.article) {
            articleMessages = validate(situation.article, {
                title: {
                    presence: {
                        allowEmpty: false,
                        message: '^Articles must have a title.'
                    }
                },
                date: {
                    presence: {
                        allowEmpty: false,
                        message: '^Articles must have a date.'
                    }
                }
            });
        }

        const validationMessages = { ...situationMessages };
        for (let key in articleMessages) {
            // Since the article fields have the same name as the situation fields,
            //  we need to modify the key name.
            validationMessages[`article${key}`] = articleMessages[key];
        }
        return validationMessages;
    }

    function _compileSituationData(status) {
        const situation = { id: situationId, status, title, notes };
        if (article?.title || article?.content) {
            situation['article'] = article;
        }
        return situation;
    }

    function _confirmationModal(eventName, situation, modalOptions) {
        const { title, message, additionalModalMessage } = modalOptions;
        const alertOptions = {
            title,
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        situationsNamespace.emit(eventName, situation);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do Nothing
                }
            ]
        };
        if (additionalModalMessage) {
            alertOptions['childrenElement'] = additionalModalMessage;
        }
        confirmAlert(alertOptions);
    }

    function _getEventName() {
        return situationId ? 'updateSituationReport' : 'createSituationReport';
    }

    function _navigateToListView() {
        navigate('/situation-reports');
    }

    function _saveReport(status, modalOptions) {
        const situation = _compileSituationData(status);
        const validationMessages = _auditData(situation);
        if (_.isEmpty(validationMessages)) {
            _confirmationModal(_getEventName(), situation, modalOptions);
        } else {
            auditsFailedModal(validationMessages);
        }
    }

    function _publishSituationReport(status, modalOptions) {
        const situation = _compileSituationData(status);
        const validationMessages = _auditData(situation);
        if (_.isEmpty(validationMessages)) {
            _confirmationModal(
                'publishSituationReport',
                situationId,
                modalOptions
            );
        } else {
            auditsFailedModal(validationMessages);
        }
    }

    function saveNotes() {
        situationsNamespace.once(
            'updateSituationReportNotesSuccess',
            (note) => {
                setExistingNote(note);
                setNotes(note);
                situationsNamespace.off('updateSituationReportNotesFailure');
            }
        );
        situationsNamespace.once(
            'updateSituationReportNotesFailure',
            (errorMessage) => {
                situationsNamespace.off('updateSituationReportNotesSuccess');
                confirmAlert({
                    title: 'Save Notes Failed',
                    message: errorMessage,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {}
                        }
                    ]
                });
            }
        );
        situationsNamespace.emit('updateSituationReportNotes', {
            situationId,
            notes
        });
    }

    /* ------ */
    /* Render */
    /* ------ */
    if (doesNotExist) {
        return (
            <PageNotFound
                link="/situation-reports"
                objectName="Situation Report"
            />
        );
    }
    return (
        <div className="ContentForm">
            <div className="ContentForm-header">
                <FaChevronLeft
                    className="back-arrow"
                    onClick={_navigateToListView}
                />
                <h2>
                    {displayTitle && situationId
                        ? displayTitle
                        : 'New Situation Report'}
                </h2>
                <StatusBadge status={status} />
            </div>

            <div className="ContentForm-contents">
                <div className="ContentForm-column">
                    {/* Edit situation report */}
                    <div className="ContentForm-area">
                        <div className="ContentForm-area-header">
                            <h3>Situation Report</h3>
                        </div>

                        {_allowEdits() ? (
                            <p>
                                Situation Reports require, at a minimum, a title
                                and at least one article before they can be
                                published.
                            </p>
                        ) : (
                            <p>
                                This Situation Report has been marked inactive.
                                No further updates can be made.
                            </p>
                        )}

                        <form>
                            <div className="ContentForm-input-container">
                                <label htmlFor="content-title">
                                    Situation Report Title
                                </label>
                                <div className="input">
                                    <input
                                        id="content-title"
                                        className="ContentForm-form-control"
                                        type="text"
                                        value={title}
                                        onChange={updateTitle}
                                        disabled={!_allowEdits()}
                                        data-testid={
                                            TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                                .CONTENT_TITLE
                                        }
                                    />
                                </div>
                            </div>
                            <div className="ContentForm-input-container">
                                <label
                                    htmlFor={`${
                                        TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                            .ANALYST_NOTES
                                    }${situationId ? `-${situationId}` : ''}`}
                                >
                                    Analyst Notes
                                </label>

                                <div className="input">
                                    <AnalystNotes
                                        analystNote={notes}
                                        existingNote={existingNote}
                                        id={`${
                                            TEST_ATTRIBUTES.CONTENT_MANAGEMENT
                                                .ANALYST_NOTES
                                        }${
                                            situationId ? `-${situationId}` : ''
                                        }`}
                                        saveNotes={saveNotes}
                                        showSave={!!situationId}
                                        updateNote={setNotes}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                    {situationId ? (
                        <ArticlesTable
                            allowEdit={_allowEdits()}
                            articles={articles}
                            onAdd={addArticle}
                            onEdit={fetchArticle}
                        />
                    ) : (
                        <ArticleForm
                            allowEdit={_allowEdits()}
                            article={article}
                            updateArticle={updateArticle}
                        />
                    )}
                </div>

                <div className="ContentForm-column">
                    {/* Actions */}
                    {status !== STATUS_INACTIVE && (
                        <ActionForm
                            onDiscard={onDiscard}
                            onSave={saveDraft}
                            onMarkInactive={markInactive}
                            onPublish={onPublish}
                            onSendForApproval={sendForApproval}
                            status={status}
                        />
                    )}

                    {/* We will only allow adding sources and alerts after a situation report has been created */}
                    {situationId && (
                        <>
                            {/* Sources */}
                            <Sources
                                allowEdit={_allowEdits()}
                                handleDelete={deleteSource}
                                openSourceModal={openSourceModal}
                                sources={sources}
                            />

                            {/* Related Threats */}
                            <RelatedAlerts
                                alerts={alerts}
                                allowEdit={_allowEdits()}
                                onRemoveFromSituation={removeFromSituation}
                                openAlertsModal={openAlertsModal}
                            />
                        </>
                    )}
                </div>
            </div>

            {/* Modals */}
            <Modal
                isOpen={showSourceModal}
                contentLabel="Situation Report Source Form"
                ariaHideApp={false}
                style={{
                    content: {
                        top: '20%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -20%)',
                        width: '50%'
                    }
                }}
            >
                <SourceForm
                    closeSourceModal={closeSourceModal}
                    situationReportId={situationId}
                    source={sourceToEdit}
                />
            </Modal>

            <Modal
                isOpen={showAlertsModal}
                contentLabel="Situation Report Alerts Form"
                ariaHideApp={false}
                style={{
                    content: {
                        top: '20%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -20%)',
                        width: '50%',
                        height: '75%'
                    }
                }}
            >
                <AlertForm
                    closeModal={closeAlertsModal}
                    currentAlerts={alerts}
                    onRemoveFromSituation={removeFromSituation}
                    situationReportId={situationId}
                />
            </Modal>

            <Modal
                isOpen={showArticleModal}
                contentLabel="Situation Report Article Form"
                ariaHideApp={false}
                style={{
                    content: {
                        top: '20%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -20%)',
                        width: '70%'
                    }
                }}
            >
                <ArticleForm
                    allowEdit={_allowEdits()}
                    article={articleToEdit}
                    closeModal={closeArticleModal}
                    isModalView={true}
                    situationReportId={situationId}
                    updateArticle={updateArticle}
                />
            </Modal>
        </div>
    );
}
