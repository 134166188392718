import './GroupForm.css';

import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import validate from 'validate.js';

import { NAMESPACES, ROLES } from '../../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';
import { getAuthInfo, getNamespace } from '../../../../../redux/selectors';
import { auditsFailedModal } from '../../../../../utils/utils';
import ICButton from '../../../../shared/ICButton';
import GroupFormColumns from './GroupFormColumns';

const {
    THREAT_DECK: { GROUPS }
} = TEST_ATTRIBUTES;
const { ADMINISTRATOR } = ROLES;

export default function GroupForm({ closeModal, group = {} }) {
    // STATE
    const [name, setName] = useState(group?.name || '');
    const [selectedColumns, setSelectedColumns] = useState([]);
    const authInfo = useSelector(getAuthInfo);

    // REDUX
    const threatDeckNamespace = useSelector(
        getNamespace(NAMESPACES.THREATDECK_NAMESPACE)
    );

    useEffect(() => {
        if (threatDeckNamespace) {
            threatDeckNamespace.on('saveGroupFailed', () => {
                confirmAlert({
                    title: 'Save Failed',
                    message: 'There was an error.',
                    buttons: [
                        {
                            label: 'No',
                            onClick: () => {}
                        }
                    ]
                });
            });
        }
        return () => {
            threatDeckNamespace.off('saveGroupFailed');
        };
    }, []);

    function auditData() {
        return validate(
            {
                name,
                columns: selectedColumns
            },
            {
                name: {
                    presence: {
                        allowEmpty: false,
                        message: '^A group must have a name.'
                    }
                },
                columns: {
                    presence: {
                        allowEmpty: false,
                        message: '^A group must have at least one column.'
                    }
                }
            },
            {
                format: 'flat'
            }
        );
    }

    function clearAndCloseModal() {
        clearData();
        closeModal();
    }

    function clearData() {
        setName('');
        setSelectedColumns([]);
    }

    function confirmDelete() {
        confirmAlert({
            title: 'Confirm Delete',
            message: `Are you sure you want to delete this group?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: deleteGroup
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function confirmSave() {
        const validationMessages = auditData();
        if (isEmpty(validationMessages)) {
            saveGroup();
        } else {
            auditsFailedModal(validationMessages);
        }
    }

    function deleteGroup() {
        threatDeckNamespace.emit('deleteGroup', group);
        closeModal();
    }

    function saveGroup() {
        threatDeckNamespace.emit('saveGroup', {
            id: group?.id,
            name,
            columns: map(selectedColumns, 'id')
        });
        closeModal();
    }

    function updateName(e) {
        setName(e.target.value);
    }

    function updateColumns(selectedColumns) {
        setSelectedColumns(selectedColumns);
    }

    return (
        <div>
            <div className="ThreatDeckGroupForm-header">
                <h2>{`${group?.id ? 'Edit Group' : 'Create New Group'}`}</h2>
                <button
                    className="ReactModal__CloseButton"
                    onClick={clearAndCloseModal}
                    data-testid={`${GROUPS.CLOSE_MODAL}${
                        group?.id ? `-${group.id}` : ''
                    }`}
                >
                    <i className="fa fa-window-close" />
                </button>
            </div>

            <div className="ContentManagementModal-body">
                <div className="ThreatDeck-input-container">
                    <div className="input column">
                        <label
                            className="input-label"
                            htmlFor="ThreatDeck-group-name"
                        >
                            Group Name:
                        </label>
                        <input
                            id="ThreatDeck-group-name"
                            className="ContentForm-form-control"
                            type="text"
                            value={name}
                            onChange={updateName}
                            data-testid={GROUPS.NAME}
                            disabled={!includes([ADMINISTRATOR], authInfo.role)}
                        />
                    </div>
                </div>

                <GroupFormColumns
                    group={group}
                    selectedColumns={selectedColumns}
                    onChange={updateColumns}
                />

                <div className="ThreatDeck-group-form-footer row">
                    <div className="group-footer-left">
                        {group?.id &&
                            includes([ADMINISTRATOR], authInfo.role) && (
                                <ICButton
                                    color="red"
                                    onClick={confirmDelete}
                                    data-testid={`${GROUPS.BUTTONS.DELETE}`}
                                >
                                    Delete Group
                                </ICButton>
                            )}
                    </div>
                    <div className="group-footer-right">
                        <ICButton
                            color="white"
                            onClick={clearAndCloseModal}
                            data-testid={`${GROUPS.BUTTONS.CANCEL}`}
                        >
                            Cancel
                        </ICButton>
                        {includes([ADMINISTRATOR], authInfo.role) && (
                            <ICButton
                                color="blue"
                                onClick={confirmSave}
                                data-testid={
                                    group?.id
                                        ? GROUPS.BUTTONS.SAVE
                                        : GROUPS.BUTTONS.CREATE
                                }
                            >
                                {`${group?.id ? 'Save' : 'Create'}`}
                            </ICButton>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
