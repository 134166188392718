import differenceBy from 'lodash/differenceBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import reject from 'lodash/reject';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { ROLES } from '../../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';
import {
    getAuthInfo,
    getAvailableGroupColumns
} from '../../../../../redux/selectors';
import ICButton from '../../../../shared/ICButton';

export default function GroupFormColumns({ onChange, group = {} }) {
    const allAvailableGroupColumns = useSelector(getAvailableGroupColumns);
    const authInfo = useSelector(getAuthInfo);
    const [availableColumns, setAvailableColumns] = useState(
        group?.id
            ? reject(allAvailableGroupColumns, (column) => {
                  // reject columns that are already part of a different group
                  return (
                      column?.group[0]?.id && column?.group[0]?.id !== group.id
                  );
              })
            : []
    );

    // currently selected columns are the columns that will be associated with the group on creation
    const [currentlySelectedColumns, setCurrentlySelectedColumns] = useState(
        []
    );

    useEffect(() => {
        setAvailableColumns(
            reject(allAvailableGroupColumns, (column) => {
                // reject columns that are already part of a different group
                return (
                    column?.group[0]?.id && column?.group[0]?.id !== group.id
                );
            })
        );

        if (group?.id) {
            const currentColumns = filter(
                allAvailableGroupColumns,
                (column) => column?.group[0]?.id === group.id
            );
            setCurrentlySelectedColumns(currentColumns);
            onChange(currentColumns);
        }
    }, [allAvailableGroupColumns, group]);

    function selectColumn(index) {
        const column = differenceBy(
            availableColumns,
            currentlySelectedColumns,
            'name'
        )[index];

        let newColumns;
        if (includes(currentlySelectedColumns, column)) {
            newColumns = sortBy(
                filter(currentlySelectedColumns, (c) => c !== column),
                'label'
            );
        } else {
            newColumns = sortBy([...currentlySelectedColumns, column], 'label');
        }

        setCurrentlySelectedColumns(newColumns);
        onChange(newColumns);
    }

    function deSelectColumn(index) {
        let column = currentlySelectedColumns[index];
        const selectedColumns = reject(
            currentlySelectedColumns,
            (c) => c === column
        );
        setCurrentlySelectedColumns(
            reject(selectedColumns, (c) => c === column)
        );
        onChange(reject(selectedColumns, (c) => c === column));
    }

    function selectAllColumns() {
        setCurrentlySelectedColumns(availableColumns);
        onChange(availableColumns);
    }

    function removeAllColumns() {
        setCurrentlySelectedColumns([]);
        onChange([]);
    }

    return (
        <div className="ThreatDeck-input-container">
            <div className="ThreatDeck-row ThreatDeck-group-columns-row">
                <div className="ThreatDeck-group">
                    <div className={'ThreatDeck-group-columns-header'}>
                        Available Columns:
                    </div>
                    <div className="ThreatDeck-group-columns-container">
                        {differenceBy(
                            availableColumns,
                            currentlySelectedColumns,
                            'name'
                        ).map((column, index) => {
                            return (
                                <div
                                    className="ThreatDeck-group-column"
                                    data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.GROUPS.COLUMNS.SELECT_ALL}-${column?.id}`}
                                    key={column?.id}
                                    onClick={() => {
                                        if (
                                            includes(
                                                [ROLES.ADMINISTRATOR],
                                                authInfo.role
                                            )
                                        ) {
                                            selectColumn(index);
                                        }
                                    }}
                                >
                                    {column?.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="ThreatDeck-group ThreatDeck-carets">
                    <IoChevronForward />
                    <IoChevronBack />
                </div>
                <div className="ThreatDeck-group">
                    <div className={'ThreatDeck-group-columns-header'}>
                        Selected Columns:
                    </div>
                    <div className="ThreatDeck-group-columns-container">
                        {map(currentlySelectedColumns, (column, index) => {
                            return (
                                <div
                                    className="ThreatDeck-group-column"
                                    data-testid={`${TEST_ATTRIBUTES.THREAT_DECK.COLUMNS.COLUMN_CONFIG.CATEGORIES.SELECTED}-${column?.id}`}
                                    key={column?.id}
                                    onClick={() => {
                                        if (
                                            includes(
                                                [ROLES.ADMINISTRATOR],
                                                authInfo.role
                                            )
                                        ) {
                                            deSelectColumn(index);
                                        }
                                    }}
                                >
                                    {column.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {includes([ROLES.ADMINISTRATOR], authInfo.role) && (
                <div className="ThreatDeck-group-buttons">
                    <ICButton
                        color="white"
                        onClick={selectAllColumns}
                        className="ThreatDeck-group-column-button"
                        data-testid={
                            TEST_ATTRIBUTES.THREAT_DECK.GROUPS.COLUMNS
                                .SELECT_ALL
                        }
                    >
                        Select All
                    </ICButton>
                    <ICButton
                        color="white"
                        onClick={removeAllColumns}
                        className="ThreatDeck-group-column-button"
                        data-testid={
                            TEST_ATTRIBUTES.THREAT_DECK.GROUPS.COLUMNS
                                .REMOVE_ALL
                        }
                    >
                        Remove All
                    </ICButton>
                </div>
            )}
        </div>
    );
}
