import React from 'react';
import { FaTimes } from 'react-icons/fa';

import ICButton from '../../../../../shared/ICButton';

export default function TriStateLeftButton({ dataTestId, onClick }) {
    return (
        <ICButton
            color="white"
            onClick={onClick}
            className="TriStateLeftButton"
            data-testid={dataTestId}
        >
            <FaTimes size={20} />
        </ICButton>
    );
}
