import map from 'lodash/map';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';

import ThreatDeckCard from '../../cards/card/ThreatDeckCard';
import EventMap from '../EventMap';
import EventMergeFields from './EventMergeFields';

export default function EventDetailsBody({
    event,
    mergeList,
    setMergeList,
    isModalView = false
}) {
    return (
        <div>
            <div
                className={`ThreatDeckEventDetails${
                    isModalView ? 'Modal' : ''
                }`}
            >
                <div className="ThreadDeckEventDetails-header">
                    <h1>{event?.title}</h1>
                    <div className="ThreadDeckEventDetails-category">
                        {event?.category}
                    </div>
                </div>
                <Container fluid>
                    <Row>
                        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <div className="ThreatDeckEventDetails-summary">
                                {event?.message}
                            </div>
                        </Col>
                    </Row>
                    {!mergeList && (
                        <Row>
                            <Col
                                md={8}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <div
                                    className={`ThreatDeckEventDetail${
                                        isModalView ? 'Modal' : ''
                                    }-map`}
                                >
                                    <EventMap singleEvent={event} />
                                </div>
                            </Col>
                            <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <div
                                    className={`ThreatDeckEventDetails${
                                        isModalView ? 'Modal' : ''
                                    }-feedItems`}
                                >
                                    {map(event?.feedItems, (item) => (
                                        <ThreatDeckCard
                                            item={item}
                                            isEventView={true}
                                            key={`td-card-${item.id}`}
                                        />
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    )}
                    {mergeList && (
                        <EventMergeFields
                            event={event}
                            mergeList={mergeList}
                            setMergeList={setMergeList}
                        />
                    )}
                </Container>
            </div>
        </div>
    );
}
