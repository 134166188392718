import React from 'react';
import { useDispatch } from 'react-redux';

import { TEST_ATTRIBUTES } from '../../../../../config/testConstants';
import { setAlertModal } from '../../../../../redux/actions';
import ICButton from '../../../../shared/ICButton';

export default function UpdateAlertButton({
    alert,
    displayAlertModal,
    isUpdate,
    onAfterClick
}) {
    const dispatch = useDispatch();

    function updateAlert() {
        dispatch(
            setAlertModal({
                alert: alert.id,
                editMode: true,
                isUpdate: alert.isUpdate || isUpdate
            })
        );
        if (onAfterClick) {
            onAfterClick();
        }
    }

    let editLabel = 'Update';
    if (
        alert.status === 'PENDING' ||
        alert.status === 'DRAFT' ||
        alert.status === 'DRAFT_REJECTED'
    ) {
        editLabel = 'Edit';
    }

    return (
        <ICButton
            onClick={updateAlert}
            color={editLabel.toLowerCase() === 'edit' ? 'yellow' : 'dark-green'}
            className={
                editLabel.toLowerCase() === 'edit' ? 'Alert-edit-button' : ''
            }
            data-testid={`${TEST_ATTRIBUTES.BUTTONS.UPDATE}-${alert.id}`}
        >
            {editLabel}
        </ICButton>
    );
}
