import './Events.css';

import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CAPABILITIES, MODALS } from '../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { setFilters, toggleModal } from '../../../redux/actions';
import {
    getApplicationRegions,
    getCategoriesList,
    getEnabledCapabilities,
    getModalState
} from '../../../redux/selectors';
import FiltersModal from '../../shared/FiltersModal';
import ICButton from '../../shared/ICButton';
import RegionSelector from '../../shared/RegionSelector';
import EventDetails from '../shared/events/event-details/EventDetails';
import EventCard from '../shared/events/EventCard';
import EventMap from '../shared/events/EventMap';

export default function ThreatDeckEvents() {
    // redux
    const dispatch = useDispatch();
    const eventDetailsModalState = useSelector(
        getModalState(MODALS.EVENT_DETAILS)
    );
    const events = useSelector((state) => {
        return reverse(Array.from(state.app.threatDeckEvents.values()));
    });
    const enabledCapabilities = useSelector(getEnabledCapabilities);
    const eventFilters = useSelector((state) => state.app.eventFilters);
    const applicationRegions = useSelector(getApplicationRegions);
    const categoriesList = useSelector(getCategoriesList);
    const [regionSelectorFilters, setRegionSelectorFilters] =
        useState(undefined);

    const navigate = useNavigate();
    useEffect(() => {
        if (!enabledCapabilities.includes(CAPABILITIES.EVENT_GROUPING)) {
            navigate('/');
        }
    }, [navigate, enabledCapabilities]);

    useEffect(() => {
        if (!isEmpty(applicationRegions) && !isEmpty(categoriesList)) {
            if (isEmpty(eventFilters)) {
                let initialFilters = {
                    category: {},
                    region: {}
                };
                categoriesList.forEach((category) => {
                    initialFilters.category[category.value] = true;
                });
                applicationRegions.forEach((region) => {
                    initialFilters.region[region.id] = true;
                });
                dispatch(setFilters('event', initialFilters));
            }
            if (regionSelectorFilters === undefined && !isEmpty(eventFilters)) {
                setRegionSelectorFilters(
                    applicationRegions.filter(
                        (region) => eventFilters.region[region.id] === true
                    )
                );
            }
        }
    }, [applicationRegions, categoriesList, regionSelectorFilters]);

    function closeModal(modalName) {
        dispatch(
            toggleModal({
                data: {},
                modalName,
                value: false
            })
        );
    }

    function updateRegionFilters(regionId, enabled) {
        let newFilters = {
            ...eventFilters,
            region: {
                ...eventFilters['region'],
                [regionId]: enabled
            }
        };
        dispatch(setFilters('event', newFilters));
    }

    function regionSelect(regions, regionAdded) {
        updateRegionFilters(regionAdded.id, true);
    }

    function regionRemove(regions, regionRemoved) {
        updateRegionFilters(regionRemoved.id, false);
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className="ThreatDeckEvent-header">
                        {regionSelectorFilters && (
                            <RegionSelector
                                regions={applicationRegions}
                                onSelect={regionSelect}
                                onRemove={regionRemove}
                                baseClassName={'RegionSelector'}
                                app="events"
                                itemRegions={regionSelectorFilters}
                            />
                        )}
                        <ICButton
                            onClick={() => {
                                dispatch(
                                    toggleModal({
                                        modalName: MODALS.EVENT_FILTERS,
                                        data: eventFilters,
                                        value: true
                                    })
                                );
                            }}
                            data-testid={TEST_ATTRIBUTES.MODALS.TOGGLE_MODAL(
                                MODALS.EVENT_FILTERS
                            )}
                            className="BaseICButton grey-button Filter-button"
                        >
                            <i className="fa fa-filter" /> Filters
                        </ICButton>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col
                    xl={8}
                    lg={7}
                    md={6}
                    style={{ paddingLeft: 10, paddingRight: 0 }}
                >
                    <div className="ThreatDeckEvent-map">
                        <EventMap
                            events={filter(events, (e) => {
                                if (isEmpty(eventFilters)) {
                                    return true;
                                } else {
                                    const { region, category } = eventFilters;
                                    if (region && category) {
                                        return (
                                            region[e.regionId] &&
                                            category[e.category]
                                        );
                                    }
                                }
                            })}
                        />
                    </div>
                </Col>
                <Col style={{ paddingLeft: 0, paddingRight: 10 }}>
                    <div className="ThreatDeckEvent-list">
                        {map(
                            filter(events, (e) => {
                                if (isEmpty(eventFilters)) {
                                    return true;
                                } else {
                                    const { region, category } = eventFilters;
                                    if (region && category) {
                                        return (
                                            region[e.regionId] &&
                                            category[e.category]
                                        );
                                    }
                                }
                            }),
                            (event) => (
                                <EventCard
                                    key={`td-event-${event.id}`}
                                    event={event}
                                    slim={true}
                                    view="MAP"
                                />
                            )
                        )}

                        <FiltersModal
                            modalType="event"
                            modalName={MODALS.EVENT_FILTERS}
                            displayRegions={true}
                        />
                        {eventDetailsModalState && (
                            <Modal
                                isOpen={
                                    eventDetailsModalState?.data?.id &&
                                    eventDetailsModalState?.isOpen
                                }
                                contentLabel="Source Details"
                                ariaHideApp={false}
                                onRequestClose={() => {
                                    closeModal(MODALS.EVENT_DETAILS);
                                }}
                                style={{
                                    content: {
                                        top: '20%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -20%)',
                                        // width: '900px',
                                        minHeight: '42%',
                                        maxHeight: '80%',
                                        overflowY: 'hidden'
                                    }
                                }}
                            >
                                <EventDetails
                                    eventId={eventDetailsModalState?.data?.id}
                                    isModalView={true}
                                    setDoesNotExist={() => {}}
                                    closeModal={() => {
                                        closeModal(MODALS.EVENT_DETAILS);
                                    }}
                                />
                            </Modal>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
