import './AnalystNotes.css';

import { Editor } from '@tinymce/tinymce-react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { tinyMceConfig } from '../../../../config/tinyMce';
import ICButton from '../../..//shared/ICButton';

const { BUTTONS } = TEST_ATTRIBUTES;

export default function AnalystNotes({
    analystNote,
    closeModal,
    editorKey = 0,
    existingNote,
    id = TEST_ATTRIBUTES.ALERT_DETAILS.ANALYST_NOTES,
    saveNotes,
    showSave,
    updateNote
}) {
    const [dirtyFlag, setDirtyFlag] = useState(false);
    const [charCount, setCharCount] = useState(0);

    let tinyMCERef = useRef(null);

    useEffect(() => {
        if (_.isString(analystNote)) {
            setCharCount(analystNote.replace(/(<([^>]+)>)/gi, '').length);
        }

        setDirtyFlag(
            existingNote ? analystNote !== existingNote : analystNote !== ''
        );
    }, [analystNote, existingNote]);

    function initEditor(event, editor) {
        if (tinyMCERef.current) {
            return;
        }
        editor.on('init', function () {
            tinyMCERef.current = editor;
            if (tinyMCERef.current) {
                // using setContent to set update content, this is triggering a change event which updates state
                tinyMCERef.current.setContent(existingNote);
            }
        });
    }

    return (
        <div className="AnalystNotes">
            <Editor
                id={id}
                key={editorKey}
                initialValue={existingNote}
                content={analystNote || null}
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                init={tinyMceConfig('Type notes here')}
                onInit={(event, editor) => initEditor(event, editor)}
                onEditorChange={updateNote}
                disabled={false}
            />
            {charCount > 0 && (
                <label className="AlertForm-details-count">
                    {charCount} Characters
                </label>
            )}
            {saveNotes && showSave && (
                <ICButton
                    color="green"
                    onClick={(e) => {
                        e.preventDefault();
                        saveNotes(analystNote);
                        if (closeModal) {
                            closeModal();
                        }
                    }}
                    disabled={!dirtyFlag}
                    className="AnalystNotes-save-button"
                    data-testid={`${BUTTONS.SAVE_ANALYST_NOTES}`}
                >
                    Save Notes
                </ICButton>
            )}
        </div>
    );
}
