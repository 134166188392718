import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ic_chat_message_received from '../../../assets/ic_chat_message_received.mp3';
import { MODALS, NAMESPACES, VIEWS } from '../../../config/constants';
import {
    getAuthInfo,
    getCurrentView,
    getEnabledCapabilities,
    getFilters,
    getModalState,
    getNamespace
} from '../../../redux/selectors';
import AlertList from './AlertList';
import AnalystChat from './analyst-chat/AnalystChat';
import CreateAlert from './CreateAlert';
import ViewSelectorButton from './ViewSelectorButton';

const { CHAT_VIEW, DRAFTS_VIEW, CREATE_VIEW } = VIEWS;

function MultiView({ loading, draftAlerts = [], draftUpdates = [] }) {
    const alertFilters = useSelector(getFilters('alert'));
    const alertFiltersModalState = useSelector(
        getModalState(MODALS.ALERT_FILTERS)
    );
    const currentView = useSelector(getCurrentView);
    const capabilities = useSelector(getEnabledCapabilities);
    const chatNamespace = useSelector(getNamespace(NAMESPACES.CHAT_NAMESPACE));
    const authInfo = useSelector(getAuthInfo);
    const [numberOfDrafts, setNumberOfDrafts] = useState(0);
    const [numberOfNewMessages, setNumberOfNewMessages] = useState(0);
    const messageReceivedSound = new Audio(ic_chat_message_received);

    useEffect(() => {
        setNumberOfDrafts(getNumberOfDrafts([...draftAlerts, ...draftUpdates]));
    }, [draftAlerts, draftUpdates, alertFilters, alertFiltersModalState]);

    useEffect(() => {
        chatNamespace.on('analystUnreadMessagesCount', (data) => {
            setNumberOfNewMessages(data.count);
        });

        // make emits
        chatNamespace.emit('getAnalystUnreadMessageCount');

        // component unmount
        // cleanup socket listeners
        return () => {
            chatNamespace.off('newChatMessage');
            chatNamespace.off('analystUnreadMessagesCount');
            chatNamespace.off('playChatSound');
        };
    }, []);

    useEffect(() => {
        // add separate hook with listener for sound notifications and refresh socket on each call
        chatNamespace.off('playChatSound');
        chatNamespace.on('playChatSound', (newMessage) => {
            try {
                messageReceivedSound.volume = 0.5;
                if (authInfo.user.settings.allowChatNotifications) {
                    messageReceivedSound.play();
                }
            } catch (error) {
                // do nothing
                // playing sounds will sometimes throw when the
                // user has not interacted with the page yet
            }
        });
    }, [authInfo.user.settings]);

    const getNumberOfDrafts = (drafts = []) => {
        return _(drafts)
            .filter(
                (alert) =>
                    alert.category === null ||
                    alertFilters.category[alert.category]
            )
            .size();
    };

    const views = {
        [CREATE_VIEW]: {
            label: 'Create'
        },
        [DRAFTS_VIEW]: {
            label: 'Drafts',
            count: numberOfDrafts
        }
    };

    if (capabilities.includes('analyst_chat')) {
        views[CHAT_VIEW] = {
            label: 'Chat',
            count: numberOfNewMessages
        };
    }

    return (
        <>
            <div className={'MultiView'}>
                <div className={'ViewSelectorButtons'}>
                    {_.map(_.keys(views), (view) => (
                        <ViewSelectorButton
                            key={`${view}-change-button`}
                            view={view}
                            viewLabel={views[view].label}
                            count={views[view].count}
                            isLoading={
                                loading.draftAlerts || loading.draftUpdates
                            }
                        />
                    ))}
                </div>
                {currentView === DRAFTS_VIEW && (
                    <AlertList
                        title="Drafts"
                        type="draftsList"
                        alerts={[...draftAlerts, ...draftUpdates]}
                        isLoading={loading.draftAlerts || loading.draftUpdates}
                        dateField="created_date"
                    />
                )}
                {currentView === CREATE_VIEW && <CreateAlert title="Create" />}
                {currentView === CHAT_VIEW && <AnalystChat />}
            </div>
        </>
    );
}

export default MultiView;
