import { getRuntimeValue } from './runtimeConfig';

export const AuthConfig = async () => {
    return {
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        endpoint: await getRuntimeValue('COGNITO_ENDPOINT'),

        // OPTIONAL - Amazon Cognito User Pool ID
        // default is dev-IC user pool
        // process.env.REACT_APP_USER_POOL_ID is substituted at client build time
        userPoolId: await getRuntimeValue('USER_POOL_ID'),

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        // default is dev-IC user pool
        // process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID is substituted at client build time
        userPoolWebClientId: await getRuntimeValue('USER_POOL_WEB_CLIENT_ID'),

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            // Hard-code qa01.alertmedia.com for now
            domain: await getRuntimeValue('APP_DOMAIN'),
            // OPTIONAL - Cookie path
            path: '/',
            // OPTIONAL - Cookie expiration in days
            expires: 365,
            // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: 'lax',
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: await getRuntimeValue('APP_SECURE_COOKIE')
        },

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    };
};
