import '../../../../../node_modules/react-simple-tree-menu/dist/main.css';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import TreeMenu from 'react-simple-tree-menu';

import { NAMESPACES } from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { setSavedBoundarySearch } from '../../../../redux/actions';
import {
    getNamespace,
    isSavedBoundarySearchOpen
} from '../../../../redux/selectors';
import store from '../../../../redux/store';

const { ALERT_FORM } = TEST_ATTRIBUTES;

export default function SavedBoundarySearch({
    drawSavedBoundary,
    updateBoundary
}) {
    const [boundaryList, setBoundaryList] = useState([]);
    const savedBoundarySearchOpen = useSelector(isSavedBoundarySearchOpen);
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );

    useEffect(() => {
        threatsNamespace.on('enabledBoundariesList', (list) => {
            didLoadBoundaries(list);
        });

        return () => {
            threatsNamespace.off('enabledBoundariesList');
        };
    }, []);

    function boundaryToNode(boundary, boundaries) {
        return {
            key: boundary.id,
            // We need to add primaryKey because the TreeMenu library messes with the `key` value when
            // rendering into the DOM. They look like this `key: '550/607'` where 550 is the parentId
            // of boundary 604
            primaryKey: boundary.id,
            label: boundary.title,
            nodes: _.orderBy(
                boundaries
                    .filter((b) => b.parentId === boundary.id)
                    .map((b) => boundaryToNode(b, boundaries)),
                ['label', 'asc']
            )
        };
    }

    function processBoundariesIntoTree(boundaries) {
        let treeData = [];
        let topLevel = _.orderBy(
            boundaries.filter((b) => b.parentId === null),
            ['title', 'asc']
        );
        topLevel.forEach((boundary) => {
            treeData.push(boundaryToNode(boundary, boundaries));
        });
        // Here we are pulling USA to the front of the tree so it is first in the select dropdown
        const [usa] = _.remove(treeData, (i) => {
            return i.label === 'United States of America';
        });
        // USA *SHOULD* always exist, but just in case...
        if (usa) {
            treeData = [usa, ...treeData];
        }
        return treeData;
    }

    function boundaryClicked(e) {
        store.dispatch(setSavedBoundarySearch(false));
        threatsNamespace.emit('getBoundary', {
            id: e.primaryKey
        });
        threatsNamespace.once('boundaryById', (boundary) => {
            didLoadBoundary(boundary);
        });
    }

    function didLoadBoundary(boundary) {
        if (boundary !== undefined && boundary !== null) {
            if (updateBoundary) {
                // update boundary only passed from TravelBriefMap
                // logic only allows for one boundary to be selected at a time
                return updateBoundary(boundary);
            }
            drawSavedBoundary(boundary);
        }
    }

    function didLoadBoundaries(boundaries) {
        if (boundaries !== undefined && boundaries !== null) {
            let processedBoundaries = processBoundariesIntoTree(boundaries);
            setBoundaryList(processedBoundaries);
        }
    }

    function toggleSavedBoundarySearch() {
        store.dispatch(setSavedBoundarySearch(!savedBoundarySearchOpen));
    }

    if (!savedBoundarySearchOpen) {
        return <p></p>;
    }

    return (
        <div
            className={`SavedBoundarySearch MapContainer`}
            style={{
                height: `200px`,
                overflow: 'scroll'
            }}
            data-testid={ALERT_FORM.MAP_SAVED_POLYGON_SEARCH}
        >
            <FaTimes
                onClick={toggleSavedBoundarySearch}
                className={`SavedBoundarySearch-close-button`}
                data-testid={ALERT_FORM.MAP_SAVED_POLYGON_SEARCH_CLOSE}
            />
            <TreeMenu data={boundaryList} onClickItem={boundaryClicked} />
        </div>
    );
}
