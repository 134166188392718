import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';

import { getApplicationRegions } from '../../../../../redux/selectors';
import RegionSelector from '../../../../shared/RegionSelector';

export default function ColumnRegions({ updateRegions, column }) {
    const regionOptions = useSelector(getApplicationRegions);

    return (
        <RegionSelector
            regions={regionOptions}
            onSelect={updateRegions}
            onRemove={updateRegions}
            baseClassName="ThreatDeck-region"
            app="td"
            itemRegions={get(column, 'regions', [])}
        />
    );
}
