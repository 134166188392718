import { map } from 'lodash';
import React from 'react';

import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import Message from './Message';

export default function Section({
    date,
    handleDelete,
    messages,
    openThread,
    isThread,
    canDelete
}) {
    return (
        date &&
        messages && (
            <div className="ChatSection">
                <div className="ChatSection-date-container">
                    <div className="ChatSection-line"></div>
                    <div
                        className="ChatSection-date"
                        data-testid={`${TEST_ATTRIBUTES.CHAT_VIEW.CHAT_SECTION.CHAT_SECTION_DATE}`}
                    >
                        {date}
                    </div>
                    <div className="ChatSection-line"></div>
                </div>
                {map(messages, (m) => (
                    <Message
                        key={m.id}
                        handleDelete={handleDelete}
                        message={m}
                        openThread={openThread}
                        isThread={isThread}
                        canDelete={canDelete}
                    />
                ))}
            </div>
        )
    );
}
