import './PageNotFound.css';

import React from 'react';

import { TEST_ATTRIBUTES } from '../../../config/testConstants';

export default function Unauthorized() {
    return (
        <div
            className="PageNotFound-container"
            data-testid={TEST_ATTRIBUTES.UNAUTHORIZED}
        >
            <h2>401 - Unauthorized</h2>

            <p>You are not authorized to access this page.</p>
        </div>
    );
}
