import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import ICPencilIcon from '../../shared/ICPencilIcon';
import StatusBadge from '../shared/content-management/StatusBadge';

export default function SectionsTable({
    allowEdit = true,
    sections = [],
    onEdit
}) {
    function renderTable() {
        return (
            <table className="ArticlesTable">
                <thead className="ArticlesTable-head">
                    <tr>
                        <th>TITLE</th>
                        <th>LAST UPDATED</th>
                        <th>STATUS</th>
                        {allowEdit && <th id="actions-header">ACTIONS</th>}
                    </tr>
                </thead>
                <tbody className="ArticlesTable-body">
                    {_.map(sections, (section) => {
                        return (
                            <tr key={section.id}>
                                <td>
                                    <strong
                                        className="ContentManagement-title-cell"
                                        onClick={() => onEdit(section.id)}
                                    >
                                        {section.customTitle
                                            ? section.customTitle
                                            : section.defaultTitle}
                                    </strong>
                                </td>
                                <td>
                                    {moment(section.updatedAt).format(
                                        'MMM D, h:mm A'
                                    )}
                                </td>
                                <td>
                                    <StatusBadge status={section.status} />
                                </td>
                                {allowEdit && (
                                    <td className="icons-container">
                                        <ICPencilIcon
                                            onClick={() => onEdit(section.id)}
                                        />
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
    return (
        <div className="ContentForm-area">
            <div className="ContentForm-area-header">
                <h3>Sections</h3>
                {/*
                // For now, there is no ability to add new sections
                allowEdit && (
                    <div className="actions">
                        <a href="#" onClick={onAdd}>
                            Add New
                        </a>
                    </div>
                )
                */}
            </div>
            {sections.length === 0 ? (
                <p>No sections have been added yet.</p>
            ) : (
                renderTable()
            )}
        </div>
    );
}
