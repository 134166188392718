import './ThreatDeckCard.css';

import React from 'react';

import CardRemoveEvent from './CardRemoveEvent';
import CardReviewButton from './CardReviewButton';
import CardAcceptReject from './review/CardAcceptReject';

export default function CardFooter({
    item,
    markAsRead,
    onSwitchChange,
    isEventView = false
}) {
    return (
        <div className="ThreatDeckCard-footer">
            <div className="footer-left">
                <CardAcceptReject
                    onSwitchChange={onSwitchChange}
                    state={item?.annotation?.ui_type}
                />
            </div>
            <div className="footer-right">
                {!isEventView && (
                    <CardReviewButton item={item} markAsRead={markAsRead} />
                )}
                {isEventView && <CardRemoveEvent item={item} />}
            </div>
        </div>
    );
}
