import React from 'react';
import { BiPencil } from 'react-icons/bi';

import ICButton from './ICButton';

export default function ICPencilIcon({ 'data-testid': dataTestId, onClick }) {
    return (
        <ICButton
            color="white"
            onClick={onClick}
            className="ContentManagement-action-icon"
            data-testid={dataTestId}
        >
            <BiPencil size={20} />
        </ICButton>
    );
}
