import './AnalystChat.css';

import { filter, groupBy, includes, isEmpty, map, sortBy } from 'lodash';
import find from 'lodash/find';
import moment from 'moment';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { FaChevronLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import {
    MULTISELECT_COMPONENT_STYLES,
    NAMESPACES,
    ROLES
} from '../../../../config/constants';
import { TEST_ATTRIBUTES } from '../../../../config/testConstants';
import { usePrevious } from '../../../../hooks/shared';
import {
    CLEAR_MESSAGES_LIST,
    DECREMENT_REPLY_COUNT,
    DELETE_CHAT_MESSAGE,
    INCREMENT_REPLY_COUNT,
    SET_MESSAGE_READ_STATUS,
    SET_MESSAGES_LIST,
    SET_THREAD_READ_STATUS
} from '../../../../redux/actionTypes';
import {
    getAuthInfo,
    getChatMessages,
    getNamespace
} from '../../../../redux/selectors';
import NewMessage from './NewMessage';
import Section from './Section';

export const ANALYST_CHAT_DEFAULT_PAGE_SIZE = 25;

const {
    CHAT_VIEW,
    CHAT_VIEW: {
        CHAT_OPTIONS: { UNREAD_CHAT, ALL_CHAT }
    }
} = TEST_ATTRIBUTES;
const { ADMINISTRATOR, LEAD_ANALYST } = ROLES;
export default function AnalystChat() {
    const [sortedMessages, setSortedMessages] = useState([]);
    const chatNamespace = useSelector(getNamespace(NAMESPACES.CHAT_NAMESPACE));
    const dispatch = useDispatch();
    const chatMessages = useSelector(getChatMessages);
    const authInfo = useSelector(getAuthInfo);
    const messagesEndRef = useRef(null);
    const threadEndRef = useRef(null);

    const [threadMessages, setThreadMessages] = useState([]);
    const [sortedThreadMessages, setSortedThreadMessages] = useState([]);
    const threadParentIdRef = useRef();
    const prevSortedMessages = usePrevious(sortedMessages);
    const prevSortedThreadMessages = usePrevious(sortedThreadMessages);

    const [noMoreMessages, setNoMoreMessages] = useState(false);
    const [allOrUnread, setAllOrUnread] = useState(UNREAD_CHAT);

    const allOrUnreadOptions = [
        { label: 'All Messages', value: ALL_CHAT },
        { label: 'Unread Messages', value: UNREAD_CHAT }
    ];

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({
            block: 'end'
        });
    };

    // Scrolling behavior
    useEffect(() => {
        if (isFirstPageLoad()) {
            // We only want to scroll to the bottom on first page load, not
            //  every time new chats come in
            scrollToRef(messagesEndRef);
        }

        if (threadHasLoaded()) {
            scrollToRef(threadEndRef);
        }
        // TODO: what dependency does this useEffect need?
    });

    function threadHasLoaded() {
        // We know the thread has loaded for the first time when the length goes
        //  from 1 (which is what it's initially set to when we add the parent
        //  message before requesting replies from the server) to more than 1
        //  (which indicates the replies have come back from the server)
        return (
            getNumSortedMessages(prevSortedThreadMessages) === 1 &&
            getNumSortedMessages(sortedThreadMessages) > 1
        );
    }

    function isFirstPageLoad() {
        // We can tell if it is the initial page load when the sortedMessages go
        //  from {} to a non-empty object
        return isEmpty(prevSortedMessages) && !isEmpty(sortedMessages);
    }

    function getNumSortedMessages(sortedMsgs) {
        // This is a janky function, I admit. Bc of the structure of the
        //  sortedMessages and sortedThreadMessages as an object with arrays as
        //  values, this was the best way I could think to find the total length
        //  of all arrays, aka total number of messages
        return Object.values(sortedMsgs || {}).reduce(
            (prevVal, currItem) => prevVal + currItem.length,
            0
        );
    }

    useEffect(() => {
        if (chatNamespace) {
            // Registering majority of our chat event listeners here. There is one in MultiView component as well.
            chatNamespace.on('chatMessages', (data) => {
                if (data.length < ANALYST_CHAT_DEFAULT_PAGE_SIZE) {
                    setNoMoreMessages(true);
                }
                storeMessages(data);
            });
            chatNamespace.on('unreadChatMessages', (data) => {
                if (data.length < ANALYST_CHAT_DEFAULT_PAGE_SIZE) {
                    setNoMoreMessages(true);
                }
                storeMessages(data);
            });
            chatNamespace.on('chatMessageReplies', (data) => {
                setThreadMessages((threadMessages) => [
                    ...threadMessages,
                    ...data
                ]);
            });
            chatNamespace.on('newChatMessage', (newMessage) => {
                processMessages(newMessage);
                scrollNewMessageIntoView(newMessage);
            });
            chatNamespace.on('chatMessageDeleted', (data) => {
                processDeletedMessage(data);
            });
            chatNamespace.on('analystReadStatusUpdated', (data) => {
                handleMessageReadUpdates(data);
            });

            if (allOrUnread === UNREAD_CHAT) {
                chatNamespace.emit('getUnreadChatMessages');
            } else if (allOrUnread === ALL_CHAT) {
                chatNamespace.emit('getChatMessages');
            }
        }

        return () => {
            chatNamespace.off('analystReadStatusUpdated');
            chatNamespace.off('chatMessages');
            chatNamespace.off('chatMessageDeleted');
            chatNamespace.off('chatMessageReplies');
            chatNamespace.off('newChatMessage');
            chatNamespace.off('unreadChatMessages');
        };
    }, []);

    useEffect(() => {
        dispatch({
            type: CLEAR_MESSAGES_LIST,
            payload: null
        });
        setNoMoreMessages(false);
        if (allOrUnread === UNREAD_CHAT) {
            chatNamespace.emit('getUnreadChatMessages');
        } else if (allOrUnread === ALL_CHAT) {
            chatNamespace.emit('getChatMessages');
        }
    }, [allOrUnread]);

    function scrollNewMessageIntoView(newMessage) {
        // If the new message was created by the current user, scroll it
        //  into view
        if (newMessage.analystUserId !== authInfo.user.id) {
            return;
        }
        scrollToRef(threadParentIdRef.current ? threadEndRef : messagesEndRef);
    }

    function handleMessageReadUpdates(data) {
        const isThreadParent = data.id === threadParentIdRef.current;
        if (data.replyToId) {
            dispatch({
                type: SET_THREAD_READ_STATUS,
                payload: data
            });
        }
        if (data.replyToId || isThreadParent) {
            processThreadUpdates(data);
        }
        if (!data.replyToId || isThreadParent) {
            dispatch({
                type: SET_MESSAGE_READ_STATUS,
                payload: data
            });
        }
        chatNamespace.emit('getAnalystUnreadMessageCount');
    }

    function processThreadUpdates(data) {
        setThreadMessages((threadMessages) => {
            return threadMessages.map((chatMessage) => {
                if (chatMessage.id === data.id) {
                    return {
                        ...chatMessage,
                        readByAnalystName: data.readByAnalystName,
                        updatedAt: data.updatedAt
                    };
                } else {
                    return chatMessage;
                }
            });
        });
    }

    function processDeletedMessage(data) {
        const { deletedMessage, lastReply } = data;
        if (deletedMessage.replyToId) {
            deleteThreadReply(deletedMessage, lastReply);
        } else {
            deleteTopLevelMessage(deletedMessage);
        }
        chatNamespace.emit('getAnalystUnreadMessageCount');
    }

    function deleteThreadReply(deletedMessage, lastReply) {
        // If the user has the thread open, remove the reply from the thread
        if (threadParentIdRef.current === deletedMessage.replyToId) {
            setThreadMessages((messages) =>
                filter(messages, (m) => m.id !== deletedMessage.id)
            );
        }
        // Update the numberOfReplies and lastReply on the parent message
        dispatch({
            type: DECREMENT_REPLY_COUNT,
            payload: {
                replyToId: deletedMessage.replyToId,
                lastReply
            }
        });
    }

    function deleteTopLevelMessage(deletedMessage) {
        // Handle case where user is viewing a thread and top-level message is deleted
        if (
            threadParentIdRef.current &&
            threadParentIdRef.current === deletedMessage.id
        ) {
            closeThread();
        }
        dispatch({
            type: DELETE_CHAT_MESSAGE,
            payload: deletedMessage
        });
    }

    function processMessages(data) {
        if (data.replyToId) {
            dispatch({
                type: SET_THREAD_READ_STATUS,
                payload: data
            });
            if (threadParentIdRef.current) {
                if (data.replyToId === threadParentIdRef.current) {
                    setThreadMessages((threadMessages) => [
                        ...threadMessages,
                        data
                    ]);
                }
            }
            incrementChatReplies(data);
        } else {
            storeMessages([data]);
        }
    }

    function incrementChatReplies(data) {
        dispatch({ payload: data, type: INCREMENT_REPLY_COUNT });
    }

    useEffect(() => {
        setSortedMessages(sortMessages(chatMessages));
    }, [chatMessages]);

    useEffect(() => {
        setSortedThreadMessages(sortMessages(threadMessages));
    }, [threadMessages]);

    useEffect(() => {
        if (threadParentIdRef.current) {
            getMessageReplies();
        } else {
            // When the thread is closed, scroll to the bottom
            scrollToRef(messagesEndRef);
        }
    }, [threadParentIdRef.current]);

    function getMessageReplies() {
        chatNamespace.emit('getChatMessageReplies', {
            replyToId: threadParentIdRef.current
        });
    }

    function loadOlderMessages(e) {
        e.preventDefault();
        // TODO: should probably add some more sophisticated checks / error handling
        const oldestMessage = sortBy(chatMessages, ['createdAt'])[0] || {};
        const oldestMessageTimestamp = oldestMessage.createdAt
            ? moment(oldestMessage.createdAt).unix()
            : null;

        if (allOrUnread === UNREAD_CHAT) {
            chatNamespace.emit('getUnreadChatMessages', {
                before: oldestMessageTimestamp
            });
        } else {
            chatNamespace.emit('getChatMessages', {
                before: oldestMessageTimestamp
            });
        }
    }

    function updateAllOrUnread([{ value }]) {
        console.log('updating all or unread', { value, allOrUnread });
        setAllOrUnread(value);
    }

    const storeMessages = (messages) => {
        dispatch({
            type: SET_MESSAGES_LIST,
            payload: messages
        });
    };

    function handleDelete(message) {
        confirmAlert({
            title: 'Confirm Delete',
            message: `Are you sure you want to delete this message?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () =>
                        chatNamespace.emit('deleteChatMessage', { message })
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function sortMessages(toSort) {
        // First, sort by createdAt asc
        toSort = sortBy(toSort, ['createdAt']);
        // Then group by date
        return groupBy(toSort, (m) =>
            moment(m.createdAt).format('MMMM DD, YYYY')
        );
    }

    function openThread(message) {
        setThreadMessages([message]);
        threadParentIdRef.current = message.id;
    }

    function closeThread() {
        threadParentIdRef.current = null;
        setThreadMessages([]);
    }

    return (
        <div>
            <div className="AnalystChat-page">
                {
                    // TODO also check if we're fetching/loading data
                    !sortedMessages ? (
                        <div>
                            <span className="fa fa-pulse fa-spinner loading">
                                &nbsp;
                            </span>
                            &nbsp;Loading...
                        </div>
                    ) : (
                        <>
                            {threadParentIdRef.current ? (
                                <div>
                                    <div className="AnalystChat-panel">
                                        <div className="Thread-view-masthead">
                                            <div className="Thread-title-message">
                                                <div
                                                    className="Thread-back-button"
                                                    data-testid={`${CHAT_VIEW.CHAT_REPLIES.THREAD_BACK_BUTTON}`}
                                                    onClick={closeThread}
                                                >
                                                    <FaChevronLeft
                                                        size={'20'}
                                                    />
                                                </div>
                                                <div className="Thread-view-title">
                                                    Thread View
                                                </div>
                                            </div>
                                            <div className="Thread-section-line">
                                                <div className="ChatSection-line"></div>
                                            </div>
                                        </div>

                                        {map(
                                            sortedThreadMessages,
                                            (val, key) => (
                                                <Section
                                                    key={key}
                                                    date={key}
                                                    data-testid={`${CHAT_VIEW.CHAT_SECTION}-${key}`}
                                                    handleDelete={handleDelete}
                                                    messages={val}
                                                    isThread={true}
                                                    canDelete={includes(
                                                        [
                                                            ADMINISTRATOR,
                                                            LEAD_ANALYST
                                                        ],
                                                        authInfo.user.role
                                                    )}
                                                />
                                            )
                                        )}
                                        <div ref={threadEndRef} />
                                    </div>
                                    <NewMessage
                                        placeholder="Reply in Thread"
                                        replyToId={threadParentIdRef.current}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <div className="AnalystChat-all-or-unread">
                                        <Multiselect
                                            id={`AnalystChat-all-or-unread`}
                                            selectionLimit={1}
                                            options={allOrUnreadOptions}
                                            onSelect={updateAllOrUnread}
                                            displayValue="label"
                                            singleSelect={true}
                                            avoidHighlightFirstOption={true}
                                            style={MULTISELECT_COMPONENT_STYLES}
                                            selectedValues={[
                                                find(allOrUnreadOptions, {
                                                    value: allOrUnread
                                                })
                                            ]}
                                        />
                                    </div>
                                    <div className="AnalystChat-panel">
                                        <div className="AnalystChat-paging">
                                            {noMoreMessages ? (
                                                <span
                                                    data-testid={`${CHAT_VIEW.BUTTONS.CHAT_NO_MORE_MESSAGES}`}
                                                >
                                                    No more messages to load.
                                                </span>
                                            ) : (
                                                <a
                                                    data-testid={`${CHAT_VIEW.BUTTONS.CHAT_LOAD_MORE_MESSAGES}`}
                                                    onClick={loadOlderMessages}
                                                >
                                                    Load older messages
                                                </a>
                                            )}
                                        </div>
                                        {map(sortedMessages, (val, key) => (
                                            <Section
                                                key={key}
                                                date={key}
                                                data-testid={`${CHAT_VIEW.CHAT_SECTION}-${key}`}
                                                handleDelete={handleDelete}
                                                messages={val}
                                                openThread={openThread}
                                                isThread={false}
                                                canDelete={includes(
                                                    [
                                                        ADMINISTRATOR,
                                                        LEAD_ANALYST
                                                    ],
                                                    authInfo.user.role
                                                )}
                                            />
                                        ))}
                                        <div ref={messagesEndRef} />
                                    </div>
                                    <NewMessage placeholder="Type here to chat with a customer about a current threat" />
                                </div>
                            )}
                        </>
                    )
                }
            </div>
        </div>
    );
}
