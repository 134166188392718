import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import { NAMESPACES } from '../../../../config/constants';
import { getNamespace } from '../../../../redux/selectors';
import ReadOnlyAlertDetails from './ReadOnlyAlertDetails';
import ReadOnlyAlertDetailsHeader from './ReadOnlyDetailsHeader';

function ReadOnlyAlertList({ alerts = [], isLoading }) {
    const timeoutRef = useRef();
    const [mostRecentAlert, setMostRecentAlert] = useState(_.head(alerts));
    const [mostRecentAlertDetails, setMostRecentAlertDetails] = useState({});
    const threatsNamespace = useSelector(
        getNamespace(NAMESPACES.THREATS_NAMESPACE)
    );
    const [alertIndex, setAlertIndex] = useState(0);
    const [resetAlertIndex, setResetAlertIndex] = useState(false);

    // cycle through the alert subset on a timer
    useEffect(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setAlertIndex((oldCount) => {
                // cycle through the newest 10 only
                // or if we reach the length of the set
                if (oldCount + 1 <= 9 && oldCount + 1 < alerts.length) {
                    return oldCount + 1;
                } else if (resetAlertIndex) {
                    setResetAlertIndex(false);
                    return 0;
                }
                return 0;
            });
        }, 13000);

        // component cleanup, clear timeouts
        // this prevents setting state on unmounted components
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [alertIndex, alerts, resetAlertIndex]);

    // set most recent alert based on new hook index
    useEffect(() => {
        if (!isEmpty(alerts)) {
            setMostRecentAlert(alerts[alertIndex]);
        }
    }, [alertIndex, alerts]);

    // get alert details
    useEffect(() => {
        if (!_.isEmpty(mostRecentAlert)) {
            threatsNamespace.on(
                `alertDetails_${mostRecentAlert.id}`,
                (data) => {
                    setMostRecentAlertDetails(data);
                }
            );
            threatsNamespace.emit('getAlertDetailsById', mostRecentAlert.id);
        }

        return () => {
            // we might have to store previous most recent alerts and remove socket listeners as we request new details
            if (!_.isEmpty(mostRecentAlert)) {
                threatsNamespace.off(`alertDetails_${mostRecentAlert.id}`);
            }
        };
    }, [mostRecentAlert]);

    return (
        <>
            <div className={'AlertList'}>
                {isLoading && (
                    <div className="AlertList-loading">
                        <i className="fa fa-spinner fa-spin" />
                    </div>
                )}
                <div className={'AlertList-details-header'}>
                    {!_.isEmpty(mostRecentAlertDetails) && (
                        <ReadOnlyAlertDetailsHeader
                            mostRecentAlertDetails={mostRecentAlertDetails}
                        />
                    )}
                </div>
                <div>
                    {!_.isEmpty(mostRecentAlertDetails) && (
                        <ReadOnlyAlertDetails
                            mostRecentAlertDetails={mostRecentAlertDetails}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default ReadOnlyAlertList;
