import './ThreatDeckCard.css';

import React from 'react';

import CardConfidence from './CardConfidence';
import CardSource from './CardSource';

export default function CardHeader({ item, cardView = false }) {
    return (
        <div className="ThreatDeckCard-header">
            <div className="header-left">
                <CardSource
                    type={item?.type}
                    sourceName={item?.source_name}
                    sourceLink={item?.link || item?.source_link}
                    cardView={cardView}
                />
            </div>
            <div className="header-right">
                <CardConfidence confidence={item?.annotation?.ml_confidence} />
            </div>
        </div>
    );
}
