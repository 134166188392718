import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RedCircle from '../../../assets/red-circle.svg';
import { TEST_ATTRIBUTES } from '../../../config/testConstants';
import { SET_CURRENT_VIEW } from '../../../redux/actionTypes';
import { getCurrentView } from '../../../redux/selectors';

function ViewSelectorButton({ view, viewLabel, count, isLoading }) {
    const dispatch = useDispatch();

    const currentView = useSelector(getCurrentView);

    function handleClick() {
        dispatch({ type: SET_CURRENT_VIEW, payload: view });
    }

    return (
        <button
            className={`ViewSelectorButton ${
                currentView === view ? 'selected' : ''
            } ${count ? 'with-label' : ''}`}
            data-testid={`${
                TEST_ATTRIBUTES[`${_.snakeCase(view).toUpperCase()}`].BUTTONS
                    .VIEW_BUTTON
            }`}
            onClick={() => {
                handleClick(view);
            }}
        >
            <div
                className={`${
                    count !== undefined
                        ? 'ViewSelectorButton-label-with-count'
                        : ''
                }`}
            >
                {viewLabel}
            </div>

            {isLoading ? (
                <div className="AlertList-loading">
                    <i className="fa fa-spinner fa-spin" />
                </div>
            ) : (
                _.isNumber(count) && (
                    <div className="CountContainer">
                        <img className="CountCircle" src={RedCircle} />
                        <span
                            data-testid={`${
                                TEST_ATTRIBUTES[
                                    `${_.snakeCase(view).toUpperCase()}`
                                ].BUTTONS.VIEW_BUTTON_COUNT
                            }`}
                            className="CountText"
                        >
                            {count}
                        </span>
                    </div>
                )
            )}
        </button>
    );
}

export default ViewSelectorButton;
